import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService, LicenceType } from '@interacta-shared/data-access-auth';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import {
    BypassSecurityTrustPipe,
    IconButtonComponent,
    ImageComponent,
} from '@interacta-shared/ui';
import { isDefined } from '@interacta-shared/util';
import { BottomMenuService } from '@modules/bottom-menu/services/bottom-menu.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { ChatStateService } from '@modules/state/services/chat-state.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { ChatPanelHeaderComponent } from './components/chat-panel-header/chat-panel-header.component';
import { ChatPanelComponent } from './components/chat-panel/chat-panel.component';

@NgModule({
    declarations: [ChatPanelComponent, ChatPanelHeaderComponent],
    imports: [
        CommonModule,
        ImageComponent,
        IconButtonComponent,
        TranslateModule,
        MatTooltipModule,
        BypassSecurityTrustPipe,
    ],
    exports: [ChatPanelComponent],
})
export class ChatModule {
    constructor(
        private bottomMenuService: BottomMenuService,
        private authService: AuthService,
        private chatStateService: ChatStateService,
        private configurationService: ConfigurationService,
        private store: Store<AppState>,
    ) {
        const selectedRouteState$ = this.store.select(
            AppSelectors.selectRouteState,
        );

        this.configurationService
            .getEnvironmentInfoStream()
            .pipe(
                filter(isDefined),
                switchMap((envInfo) =>
                    combineLatest([
                        this.authService.currentUserData$,
                        selectedRouteState$,
                    ]).pipe(
                        map(([userData, selectedRouteState]) => ({
                            envInfo,
                            userData,
                            selectedRouteState,
                        })),
                    ),
                ),
            )
            .subscribe(({ envInfo, userData, selectedRouteState }) => {
                const chatEnabled =
                    envInfo?.installedFeatures.chat &&
                    envInfo?.installedFeatures.chatEmbedded &&
                    userData?.licences?.some(
                        (value) => value.type === LicenceType.CHAT,
                    ) &&
                    selectedRouteState?.appBaseRoute !== 'error';

                this.chatStateService.toggleEnable(chatEnabled ?? false);

                if (chatEnabled) {
                    this.bottomMenuService.addComponent(
                        {
                            type: ChatPanelComponent,
                        },
                        true,
                    );
                } else {
                    this.bottomMenuService.removeComponent(ChatPanelComponent);
                }
            });
    }
}
