<ng-container *ngIf="routeState()?.appBaseRoute as appBaseRoute">
    @if (!goToMainContentExcluded.includes(appBaseRoute)) {
        <button
            class="bg-surface-A typo-sm-bold absolute -top-40 left-0 z-50 h-36 px-12 py-8 focus:translate-x-4 focus:translate-y-44"
            id="skip-link-button-main-content"
            type="button"
            (click)="setFocus('main-content')"
        >
            {{ 'SKIP_BUTTONS_ACCESSIBILITY.GO_TO_MAIN_CONTENT' | translate }}
        </button>
        @if (!goToSidebarExcluded.includes(appBaseRoute)) {
            <button
                class="typo-sm-bold bg-surface-A absolute -top-40 left-0 z-50 h-36 px-12 py-8 focus:translate-x-12 focus:translate-y-44"
                type="button"
                (click)="setFocus('sidebar')"
            >
                {{ 'SKIP_BUTTONS_ACCESSIBILITY.GO_TO_SIDEBAR' | translate }}
            </button>
        }
    }
</ng-container>
