import { createAction, props } from '@ngrx/store';

export const clear = createAction('[Table Info] Clear');

export const openDetail = createAction(
    '[Table Info] Open Detail',
    props<{ tableId: number }>(),
);

export const closeDetail = createAction('[Table Info] Close Detail');

export const fetchTableInfo = createAction(
    '[Table Info] Fetch Table Info',
    props<{ tableId: number }>(),
);
