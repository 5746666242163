import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { dashboardCommunity } from '@modules/app-routing/routes';
import { ICommunity } from '@modules/communities/models/communities.model';
import { changeFilters } from '@modules/communities/store/post/post.actions';
import { HashtagInfo } from '@modules/hashtag-info/models/hashtag-info.model';
import {
    closeDetail,
    followHashtag,
} from '@modules/hashtag-info/store/hashtag-info.actions';
import {
    selectDetailHashtagInfo,
    selectDetailVisible,
} from '@modules/hashtag-info/store/hashtag-info.selectors';
import { emptyPostFilters } from '@modules/post/models/filter-post/filter-post.utils';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export type SearchType = 'post' | 'attachment' | 'both';
@Component({
    selector: 'interacta-hashtag-info-dialog',
    templateUrl: './hashtag-info-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HashtagInfoDialogComponent {
    visible$: Observable<boolean>;
    hashtag$: Observable<HashtagInfo | undefined>;
    community$: Observable<ICommunity | undefined>;
    searchType = new FormControl<SearchType[]>([]);

    constructor(
        private store: Store,
        private router: Router,
        communitiesStateService: CommunitiesStateService,
    ) {
        this.visible$ = store.select(selectDetailVisible);
        this.hashtag$ = store.select(selectDetailHashtagInfo);

        this.community$ = this.hashtag$.pipe(
            mergeMap((hashtag) =>
                hashtag
                    ? communitiesStateService.getCommunity(hashtag.communityId)
                    : of(undefined),
            ),
        );
    }

    close(): void {
        this.store.dispatch(closeDetail());
        this.searchType.reset();
    }

    toggleFollow(hashtag: HashtagInfo): void {
        this.store.dispatch(
            followHashtag({
                hashtagId: hashtag.id,
                follow: !hashtag.followedByMe,
            }),
        );
    }

    apply(hashtag: HashtagInfo, searchTypes: SearchType[] | null): void {
        if (searchTypes?.length) {
            this.store.dispatch(
                changeFilters({
                    fetchType: 'dashboard',
                    updatedFilters: {
                        ...emptyPostFilters(),
                        communityId: hashtag.communityId,
                        hashtag:
                            searchTypes.includes('post') ||
                            searchTypes.includes('both')
                                ? [hashtag]
                                : null,
                        attachmentHashtag:
                            searchTypes.includes('attachment') ||
                            searchTypes.includes('both')
                                ? [hashtag]
                                : null,
                    },
                }),
            );
            this.router.navigate([dashboardCommunity, hashtag.communityId]);
        }
        this.close();
    }
}
