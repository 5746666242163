import { NgModule } from '@angular/core';
import {
    AvatarComponent,
    ButtonComponent,
    DialogComponent,
    KeyValueFieldComponent,
    LoaderComponent,
    SeparatorComponent,
} from '@interacta-shared/ui';
import { IconComponent } from '@interacta-shared/ui-icons';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { PeopleDetailsComponent } from './components/people-details/people-details.component';
import { PeopleDialogComponent } from './components/people-dialog/people-dialog.component';

const standaloneComponents = [
    IconComponent,
    AvatarComponent,
    ButtonComponent,
    DialogComponent,
    KeyValueFieldComponent,
    LoaderComponent,
    SeparatorComponent,
];

@NgModule({
    declarations: [PeopleDialogComponent, PeopleDetailsComponent],
    imports: [SharedV2Module, ...standaloneComponents],
    exports: [PeopleDialogComponent],
})
export class PeopleDialogModule {}
