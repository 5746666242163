import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService, LicenceType } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    IExternalLink,
    toExternalLinkArray,
} from '../models/external-link/external-link.model';

@Injectable({
    providedIn: 'root',
})
export class ExternalLinksService {
    private baseUrlCommunication = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/settings`;

    constructor(
        protected http: HttpClient,
        private authService: AuthService,
        private configurationService: ConfigurationService,
    ) {}

    public getExternalLinks(): Observable<IExternalLink[]> {
        return this.http
            .get(`${this.baseUrlCommunication}/external-links`)
            .pipe(
                map((res) => {
                    const chatEnabled =
                        (this.configurationService.getEnvironmentInfo()
                            ?.installedFeatures.chat &&
                            this.authService
                                .currentUserData()
                                ?.licences?.some(
                                    (value) => value.type === LicenceType.CHAT,
                                )) ??
                        false;
                    const xLearningEnabled =
                        (this.configurationService.getEnvironmentInfo()
                            ?.installedFeatures.xlearning &&
                            this.authService
                                .currentUserData()
                                ?.licences?.some(
                                    (value) =>
                                        value.type === LicenceType.XLEARNING,
                                )) ??
                        false;
                    return toExternalLinkArray(
                        res,
                        chatEnabled,
                        xLearningEnabled,
                        this.configurationService.getEnvironmentInfo()
                            ?.plantBaseUrl,
                    );
                }),
            );
    }
}
