import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeInOutAnimation } from '@interacta-shared/ui';
import { ICarousel } from '@modules/carousel/models/carousel/carousel.model';
import { CarouselService } from '@modules/carousel/services/carousel.service';

@Component({
    selector: 'interacta-carousel-container',
    templateUrl: './carousel-container.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInOutAnimation('carousel', 500, 'ease-out'),
        trigger('slideIn', [
            state(
                'outright',
                style({
                    transform: 'translateX(130%)',
                }),
            ),
            state(
                'in',
                style({
                    transform: 'translateX(0)',
                }),
            ),
            state(
                'outleft',
                style({
                    transform: 'translateX(-130%)',
                }),
            ),
            transition('outright => in', [animate('500ms ease')]),
            transition('outleft => in', [animate('500ms ease')]),
            transition('in => *', [animate('0.1ms 1s ease')]),
        ]),
    ],
})
export class CarouselContainerComponent {
    @Input() canShowCarousel = false;

    carousel!: ICarousel;
    currentIndex = 0;

    constructor(public carouselService: CarouselService) {}

    nextStep(index: number): void {
        this.currentIndex = index;
    }

    closeCarousel(): void {
        this.carouselService.markAsSeen();
        this.currentIndex = 0;
    }
}
