import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    OnDestroy,
    OnInit,
    signal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
    DialogButtonComponent,
    DialogButtonsComponent,
    DialogComponent,
    GroupAvatarComponent,
    InfiniteScrollDirective,
    InfiniteScrollItemDirective,
    InputSearchComponent,
    InputSelectSearchEvent,
    LoaderComponent,
    LoadMoreComponent,
    ScrollTrackerDirective,
    ScrollTrackerEvent,
    SeparatorComponent,
} from '@interacta-shared/ui';
import {
    emptyPaginatedList,
    fetchPaginatedList,
    fetchPaginatedListSuccess,
    getNextPageToken,
    getRandomColor,
    PageTokenInfo,
    PaginatedList,
} from '@interacta-shared/util';
import { AdminV2GroupService } from '@modules/admin-v2-group/services/admin-v2-group.service';
import { adminV2GroupOverview } from '@modules/admin-v2-routing/admin-v2-routes';
import { adminV2, adminV2Group } from '@modules/app-routing/routes';
import { IUsersGroup } from '@modules/core';
import { Member } from '@modules/core/models/member/member.model';
import { wrapMemberPaginatedList } from '@modules/core/models/member/member.utils';
import { UserCapabilitiesService } from '@modules/core/services/user-capabilites.service';
import { UsersService } from '@modules/core/services/users.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { Store } from '@ngrx/store';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    filter,
    Subject,
    takeUntil,
} from 'rxjs';
import { GroupInfoV2Service } from '../group-info-v2.service';

@Component({
    standalone: true,
    imports: [
        SharedV2Module,
        DialogComponent,
        DialogButtonComponent,
        DialogButtonsComponent,
        SeparatorComponent,
        LoaderComponent,
        InputSearchComponent,
        LoadMoreComponent,
        ScrollTrackerDirective,
        InfiniteScrollDirective,
        InfiniteScrollItemDirective,
        GroupAvatarComponent,
    ],
    selector: 'interacta-group-info-dialog-v2',
    templateUrl: './group-info-dialog-v2.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupInfoDialogV2Component implements OnInit, OnDestroy {
    private readonly userCapabilitiesService = inject(UserCapabilitiesService);
    private readonly usersService = inject(UsersService);
    private readonly groupService = inject(AdminV2GroupService);
    private readonly router = inject(Router);
    private readonly appStore = inject(Store<AppState>);
    private readonly groupInfoService = inject(GroupInfoV2Service);

    readonly MEMBERS_TO_LOAD_AT_START = 8;
    readonly N_MEMBERS_TO_SHOW = 20;
    readonly getRandomColor = getRandomColor;

    data$ = this.groupInfoService.state.data;
    members$ = new BehaviorSubject<PaginatedList<Member>>(emptyPaginatedList());

    searchMemberControl: FormControl<string> = new FormControl('', {
        nonNullable: true,
    });

    remainingMembers = 0;
    remainingTags = 0;
    showMoreMembers = false;

    // permission
    isAdminSection = signal(false);
    showEditButton = computed(
        () =>
            this.isAdminSection() &&
            !!this.userCapabilitiesService.userCapabilities()?.manageUsers,
    );

    private destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.appStore
            .select(AppSelectors.selectRouteState)
            .pipe(takeUntil(this.destroy$))
            .subscribe((route) => {
                this.isAdminSection.set(route?.appBaseRoute === adminV2);
            });

        this.data$
            .asObservable()
            .pipe(
                filter(({ isOpen }) => isOpen),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.showMoreMembers = false;
                this.fetchMembers(
                    { text: '', nextPageToken: null },
                    this.MEMBERS_TO_LOAD_AT_START,
                );
            });

        this.searchMemberControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe((text) =>
                this.fetchMembers(
                    { text: text, nextPageToken: null },
                    this.N_MEMBERS_TO_SHOW,
                ),
            );
    }

    fetchMembers(filter: InputSelectSearchEvent, pageSize: number): void {
        if (this.data$.value.group) {
            this.members$.next(
                fetchPaginatedList(
                    this.members$.value,
                    filter.nextPageToken ?? undefined,
                ),
            );

            const id = this.data$.value.group.id;
            (this.isAdminSection()
                ? this.groupService.getMembers(id, filter, pageSize)
                : this.usersService.getGroupMembers(
                      id,
                      filter,
                      !filter.nextPageToken,
                      pageSize,
                  )
            )
                .pipe(takeUntil(this.destroy$))
                .subscribe((members) => {
                    this.members$.next(
                        fetchPaginatedListSuccess(
                            this.members$.value,
                            wrapMemberPaginatedList(members),
                        ),
                    );
                    if (!filter.nextPageToken) {
                        this.remainingMembers = members.totalCount - pageSize;
                    }
                });
        }
    }

    onScroll(event: ScrollTrackerEvent, pageToken: PageTokenInfo): void {
        if (
            event?.thresholdReached &&
            this.showMoreMembers &&
            pageToken.tag === 'regularLoading'
        ) {
            this.fetchNextMembers(pageToken);
        }
    }

    fetchNextMembers(pageToken: PageTokenInfo): void {
        this.fetchMembers(
            {
                text: this.searchMemberControl.value,
                nextPageToken: getNextPageToken(pageToken),
            },
            this.N_MEMBERS_TO_SHOW,
        );
    }

    loadMoreMembers(): void {
        this.showMoreMembers = true;
        this.fetchMembers(
            { text: '', nextPageToken: null },
            this.N_MEMBERS_TO_SHOW,
        );
    }

    edit(groupId: IUsersGroup['id']): void {
        this.router.navigate([adminV2Group, groupId, adminV2GroupOverview]);
    }

    close(): void {
        this.searchMemberControl.reset();
        this.groupInfoService.closeDialog();
    }

    ngOnDestroy(): void {
        this.showMoreMembers = false;
        this.destroy$.next();
    }
}
