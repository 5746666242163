import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, iif, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

export class MultiTranslateHttpLoader implements TranslateLoader {
    static translateLoaded = false;
    constructor(
        private http: HttpClient,
        public resources: {
            prefix: string;
            suffix: string;
            dynamic?: boolean;
        }[],
    ) {}

    /**
     * Gets the translations from the server
     * @param lang
     * @returns {any}
     */
    public getTranslation(lang: string): any {
        const reducer = (translations: any, val: any) => {
            if (val) {
                const result = { ...translations, ...val };
                const keys = Object.keys(result);
                for (const key of keys) {
                    if (
                        typeof translations[key] == 'object' &&
                        typeof val[key] == 'object'
                    ) {
                        result[key] = reducer(translations[key], val[key]);
                    }
                }
                return result;
            } else {
                return translations;
            }
        };

        return forkJoin(
            this.resources.map((config) => {
                return this.http
                    .get(`${config.prefix}${lang}${config.suffix}`)
                    .pipe(
                        catchError((error) =>
                            iif(
                                () => !!config.dynamic,
                                of(null),
                                throwError(error),
                            ),
                        ),
                    );
            }),
        ).pipe(
            finalize(() => (MultiTranslateHttpLoader.translateLoaded = true)),
            map((response) => response.reduce(reducer, {})),
        );
    }
}
