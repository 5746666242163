<ng-container
    *ngIf="{
        currentHomeId,
        selectableHomes,
    } as homes"
>
    @if (homes.selectableHomes.length > 1) {
        <button
            #openMenu="cdkOverlayOrigin"
            cdkOverlayOrigin
            class="typo-t5 bg-surface-A hover:bg-surface-B inline-flex items-center gap-8 rounded px-12 py-8"
            matRipple
            type="button"
            [attr.aria-label]="'DIGITAL_WORKPLACE.SELECT_HOME' | translate"
            [matTooltip]="'DIGITAL_WORKPLACE.SELECT_HOME' | translate"
            (click)="toggleOpen()"
        >
            <h1>
                {{
                    (homes | apply: getHomeName) ?? '' | currentLanguageV2: true
                }}
            </h1>
            <interacta-icon class="size-24" [icon]="'chevron-small-down'" />
        </button>

        <interacta-menu
            *ngIf="isOpen"
            positionX="after"
            [open]="isOpen"
            [origin]="openMenu"
            (closing)="isOpen = false"
        >
            <interacta-menu-decorator class="block">
                <div
                    class="min-w-300 flex flex-col overflow-y-auto py-16"
                    [style.max-height]="'min(80vh, 17.625rem)'"
                >
                    @for (item of selectableHomes; track item.id) {
                        <interacta-button-menu (click)="itemSelected(item)">
                            <div
                                class="typo-medium flex w-full items-center justify-between gap-8"
                            >
                                <span>{{
                                    item.name | currentLanguageV2: true
                                }}</span>
                                <div
                                    *ngIf="item.isNew"
                                    class="typo-xs flex h-24 items-center rounded-full border px-8"
                                >
                                    {{
                                        'DIGITAL_WORKPLACE.NEW_HOME_BADGE'
                                            | translate
                                    }}
                                </div>
                            </div>
                        </interacta-button-menu>
                    }
                </div>
            </interacta-menu-decorator>
        </interacta-menu>
    } @else {
        <h5 class="typo-t5">
            {{ (homes | apply: getHomeName) ?? '' | currentLanguageV2: true }}
        </h5>
    }
</ng-container>
