import { Action, createReducer, on } from '@ngrx/store';

import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import * as TableInfoApiActions from './table-info-api.actions';
import * as TableInfoActions from './table-info.actions';

export const TABLE_INFO_FEATURE_KEY = 'tableInfo';

export interface TableInfoState {
    detail: {
        visible: boolean;
        tableId: number | undefined;
    };

    table: QuillTable | undefined;
}

export const initialTableInfoState: TableInfoState = {
    detail: {
        visible: false,
        tableId: undefined,
    },
    table: undefined,
};

const reducer = createReducer(
    initialTableInfoState,
    on(TableInfoActions.clear, () => ({ ...initialTableInfoState })),

    on(TableInfoActions.openDetail, (state, { tableId }) => ({
        ...state,
        detail: {
            tableId,
            visible: true,
        },
        table: undefined,
    })),

    on(TableInfoActions.closeDetail, (state) => ({
        ...state,
        detail: {
            tableId: undefined,
            visible: false,
        },
        table: undefined,
    })),

    on(TableInfoApiActions.fetchTableInfoSuccess, (state, { table }) => ({
        ...state,
        table,
    })),
);

export function tableInfoReducer(
    state: TableInfoState | undefined,
    action: Action,
) {
    return reducer(state, action);
}
