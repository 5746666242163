import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toQuillTable } from '@modules/shared-v2/models/quill-table/quill-table.deserialize';
import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TableInfoService {
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts/data/tables`;

    constructor(private http: HttpClient) {}

    getTableInfo(tableId: number): Observable<QuillTable> {
        return this.http
            .get<any>(`${this.baseUrl}/${tableId}`)
            .pipe(map(toQuillTable));
    }
}
