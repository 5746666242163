import {
    CommandMessage,
    CommandType,
    EventMessage,
    EventType,
} from './chat.models';

export function toIMessage(
    message: MessageEvent,
):
    | CommandMessage<CommandType, unknown>
    | EventMessage<EventType, unknown>
    | null {
    const data: any = message.data;

    if (!data) return null;

    const result = {
        id: data.id,
        target: data.target,
        type: data.type,
        event: data.event,
        command: data.command,
        body: data.body,
    };

    return result;
}
