<div
    #app
    class="relative isolate flex h-screen min-h-0 w-screen flex-col overflow-hidden print:overflow-visible"
    (scroll)="app.scrollTop = 0"
>
    <interacta-skip-links />
    @let user = currentUser();
    @if (user && showHeader()) {
        <interacta-header
            class="z-40 shrink-0"
            [currentTheme]="theme()"
            [customerLogoSrc]="logo"
            [enableChristmasEasterEgg]="enableChristmasEasterEgg"
            [iconMode]="iconMode()"
            [user]="user"
            (changeLanguage)="changeLanguage($event)"
            (changeTheme)="changeTheme($event)"
            (logout)="logout()"
            (openNotificationsSettings)="openNotificationsSettings()"
            (openProfile)="openProfile()"
        />
    }
    <interacta-bottom-menu class="fixed bottom-0 right-0 z-10 print:hidden" />

    <div class="z-0 flex min-h-0 grow">
        @if (user && showSidebar()) {
            @let isLeftMenuOpen = isMd() && (leftMenuUI()?.isOpen ?? false);
            <nav
                class="transition-width shrink-0 overflow-x-hidden duration-300"
                [ngClass]="isLeftMenuOpen ? 'w-252' : 'w-64'"
            >
                <interacta-sidebar
                    class="block h-full"
                    [currentTheme]="theme()"
                    [enableChristmasEasterEgg]="enableChristmasEasterEgg"
                    [expanded]="isLeftMenuOpen"
                />
            </nav>
        }
        <main class="min-w-0 grow">
            <router-outlet />
        </main>
    </div>
</div>

<interacta-layer />
<interacta-confirm-dialog />
<interacta-upload-dialog />
<interacta-people-dialog />
<interacta-group-info-dialog-v2 />
<interacta-hashtag-info-dialog />
<interacta-table-info-dialog />
<interacta-attachments-versions-dialog />
<interacta-tip-overlay />
<interacta-notifications-panel />
<interacta-comment-fullscreen-editor />
<interacta-loading-layer [src]="theme().mode | appLogo" />
<interacta-carousel-container [canShowCarousel]="canShowCarousel()" />
<interacta-links-dialog />
