import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { CurrentUser } from '@interacta-shared/data-access-auth';
import { Language } from '@interacta-shared/data-access-configuration';
import { translateFadeAnimation } from '@interacta-shared/ui';

import { Theme, ThemeOption } from '@modules/core/models/theme.model';
import { UserMenuPage } from '@modules/shared-v2/models/user-menu';

@Component({
    selector: 'interacta-user-menu',
    templateUrl: './user-menu.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        translateFadeAnimation('slideLeft', '-2.5rem', null, 'in'),
        translateFadeAnimation('slideRight', '2.5rem', null, 'in'),
    ],
})
export class UserMenuComponent {
    @Input() origin!: CdkOverlayOrigin;
    @Input() isOpen = false;
    @Input() availableLanguages!: Language[];
    @Input() currentLanguage!: Language;
    @Input() currentUser!: CurrentUser;
    @Input() currentTheme!: Theme;

    @Output() closing = new EventEmitter();
    @Output() changeTheme = new EventEmitter<ThemeOption>();
    @Output() changeLanguage = new EventEmitter<Language>();
    @Output() logout = new EventEmitter<void>();
    @Output() openProfile = new EventEmitter<void>();
    @Output() openNotificationsSettings = new EventEmitter<void>();

    currentPage: UserMenuPage = 'home';
}
