// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.

import { Environment } from '@interacta-shared/data-access-configuration';

export const appEnvironment: Environment = {
    production: false,
    apiBasePath: {
        auth: './api/core/auth',
        common: './api',
        learning: '../learning/api',
    },
};
