@if (tip) {
    <article class="flex items-center gap-16">
        @switch (tip.severity) {
            @case (TipSeverity.SUCCESS) {
                <interacta-icon
                    class="text-system-success size-24 shrink-0"
                    [icon]="'check'"
                />
            }
            @case (TipSeverity.INFO) {
                <interacta-icon
                    class="text-text-link size-24 shrink-0"
                    [icon]="'info'"
                />
            }
            @case (TipSeverity.WARN) {
                <interacta-icon
                    class="text-system-warning size-24 shrink-0"
                    [icon]="'warning'"
                />
            }
            @case (TipSeverity.ERROR) {
                <interacta-icon
                    class="text-text-error size-24 shrink-0"
                    [icon]="'warning'"
                />
            }
        }

        @if (tip.message | apply: asString; as message) {
            <div
                class="typo-sm text-text-reverse overflow-auto whitespace-pre-line break-words"
            >
                {{ message | translate: tip.translateParams }}
            </div>
        } @else {
            @if (tip.message | apply: asDelta; as deltaMessage) {
                <interacta-delta-view
                    class="typo-sm text-text-reverse min-w-0"
                    [delta]="deltaMessage"
                />
            }
        }

        @if (tip.closeBehavior !== 'forceReload') {
            <button
                class="text-text-reverse ml-auto shrink-0"
                interacta-icon-button
                type="button"
                [bgColor]="'reverse-transparent'"
                [icon]="'close'"
                (click)="closeTip.emit()"
            ></button>
        } @else {
            <button
                class="ml-auto shrink-0"
                interacta-dialog-button
                type="button"
                [active]="true"
                [appearance]="'ghost'"
                [label]="'SHARED.TIPS.FORCE_RELOAD' | translate"
                (click)="forceReload.emit()"
            ></button>
        }
    </article>
}
