import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    ButtonComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DialogButtonComponent,
    DialogButtonsComponent,
    DialogComponent,
    LoaderComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    SeparatorComponent,
} from '@interacta-shared/ui';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HashtagInfoDialogComponent } from './components/hashtag-info-dialog/hashtag-info-dialog.component';
import { HashtagInfoEffects } from './store/hashtag-info.effects';
import { hashtagInfoReducer } from './store/hashtag-info.reducer';

const standaloneComponents = [
    ButtonComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DialogButtonComponent,
    DialogButtonsComponent,
    DialogComponent,
    LoaderComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    SeparatorComponent,
];

@NgModule({
    declarations: [HashtagInfoDialogComponent],
    imports: [
        CommonModule,
        SharedV2Module,
        ...standaloneComponents,
        StoreModule.forFeature('hashtag-info', hashtagInfoReducer),
        EffectsModule.forFeature([HashtagInfoEffects]),
    ],
    exports: [HashtagInfoDialogComponent],
})
export class HashtagInfoModule {}
