import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import {
    emptyPaginatedList,
    flatten,
    getNextPageToken,
    mapArrayById,
} from '@interacta-shared/util';
import { PushChannelsService } from '@modules/core';
import { NotificationBellState } from '@modules/state/models/notification-bell-state.model';
import { Store } from '@ngrx/store';
import {
    BehaviorSubject,
    EMPTY,
    Observable,
    Subject,
    Subscription,
    forkJoin,
    of,
} from 'rxjs';
import { finalize, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { StateService } from '../../state/services/state.service';
import { toActivityNotificationList } from '../models/notification-user/notification-user.deserialize';
import {
    IEventActivity,
    NotificationList,
} from '../models/notification-user/notification-user.model';
import { emptyNotificationList } from '../models/notification-user/notification-user.utils';
import { AppState } from '../store';
import { notificationPanel } from '../store/UI/ui.selector';

@Injectable({
    providedIn: 'root',
})
export class NotificationBellStreamService {
    private notificationBellState: NotificationBellState;
    private unreadNotificationEventsCount = new BehaviorSubject<number>(0);
    private newFutureNotificationsToRead = new Subject<IEventActivity[]>();
    private futureEventsSyncToken: string | undefined = undefined;
    private userChannelStreamSubscription: Subscription | null = null;
    private loadingFutureEvent = false;
    private baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/update-notifications`;
    private userNotificationsLastUpdateTimestamp: number | null = null;

    constructor(
        private appState: StateService,
        private store: Store<AppState>,
        private http: HttpClient,
        private authService: AuthService,
        private configurationService: ConfigurationService,
        private pushChannelsService: PushChannelsService,
    ) {
        this.notificationBellState = this.appState.notificationBellState;
        this.onInit();
    }

    getNewFutureNotificationsToRead(): Observable<IEventActivity[]> {
        return this.newFutureNotificationsToRead.asObservable();
    }

    setNewFutureNotificationsToRead(notifications: IEventActivity[]): void {
        this.newFutureNotificationsToRead.next(notifications);
    }

    getUnreadNotificationCountStream(): Observable<number> {
        return this.unreadNotificationEventsCount.asObservable();
    }

    getBufferNotificationsStream(): Observable<NotificationList | undefined> {
        return this.notificationBellState.currentActivityNotificationList.onDataChange();
    }

    markNotificationEventsAsRead(
        activities: IEventActivity[],
    ): Observable<any> {
        const notificationIds: number[] = mapArrayById(activities) || [];
        return this.http
            .post(`${this.baseUrl}/mark-notifications-as-read`, {
                notificationIds,
            })
            .pipe(
                tap(() => {
                    this.markNotificationAdReadUpdateBuffer(notificationIds);
                }),
            );
    }

    markAllNotificationEventsAsRead(): Observable<any> {
        const currentNotificationEventList =
            this.notificationBellState.currentActivityNotificationList.staticData();
        if (currentNotificationEventList?.list[0]) {
            return this.http
                .post(`${this.baseUrl}/mark-all-notifications-as-read`, {
                    lastReadNotificationId:
                        currentNotificationEventList.list[0].id,
                })
                .pipe(tap(() => this.markNotificationAdReadUpdateBuffer()));
        } else {
            console.debug(
                'No notification present. Skip mark all as read operation',
            );
            return EMPTY;
        }
    }

    loadPastNotifications(): Observable<NotificationList> {
        return this._doLoadMorePastEventsFromStream();
    }

    toggleOnlyUnreadNotificationAndFetchStream(onlyUnread: boolean): void {
        const actualList =
            this.notificationBellState.currentActivityNotificationList.staticData();
        if (actualList) {
            this.setBufferNotifications({
                ...actualList,
                ...emptyPaginatedList(),
                isFetching: true,
            });
        }

        forkJoin([
            this._activityStreamEvents(null, null, onlyUnread),
            this._doRetrieveUnreadNotificationsEventsCount(),
        ]).subscribe(([result, counter]) => {
            const newList = actualList
                ? {
                      ...actualList,
                      list: [...result.list],
                      nextPageToken: result.nextPageToken,
                      nextSyncToken: result.nextSyncToken,
                      isFetching: false,
                      onlyUnread,
                  }
                : { ...result, onlyUnread };
            this.setBufferNotifications(newList);
            this.setUnreadNotificationEventsCount(counter);
        });
    }

    removeAllRead(): void {
        const actualList =
            this.notificationBellState.currentActivityNotificationList.staticData();
        if (actualList) {
            const list = actualList?.list.filter((e) => !e.read);
            this.setBufferNotifications({
                ...actualList,
                list,
                totalCount:
                    actualList.totalCount +
                    list.length -
                    actualList.list.length,
            });
        }
    }

    private markNotificationAdReadUpdateBuffer(eventIds?: number[]) {
        const unReadCount = this.unreadNotificationEventsCount.getValue();
        const updatedCount =
            eventIds && unReadCount >= eventIds.length
                ? unReadCount - eventIds.length
                : 0;
        this.setUnreadNotificationEventsCount(updatedCount);
        const currentNotificationEventList =
            this.notificationBellState.currentActivityNotificationList.staticData();
        if (currentNotificationEventList) {
            const newList = {
                ...currentNotificationEventList,
                list: currentNotificationEventList.list.map((item) =>
                    !eventIds || eventIds.indexOf(item.id) >= 0
                        ? {
                              ...item,
                              read: true,
                          }
                        : item,
                ),
            };
            this.setBufferNotifications(newList);
        }
    }

    private _doUpdateFutureEventsFromStream(
        userNotificationsLastUpdateTimestamp: number | null,
        onlyUnread: boolean,
    ): Observable<any> {
        if (this.loadingFutureEvent) {
            return of(false);
        }

        this.loadingFutureEvent = true;
        const actualList =
            this.notificationBellState.currentActivityNotificationList.staticData();
        if (actualList) {
            this.setBufferNotifications({
                ...actualList,
                onlyUnread,
                isFetching: true,
            });
        }

        const notificationStream$: Observable<NotificationList> =
            userNotificationsLastUpdateTimestamp != null
                ? this._doRetrieveNotificationsEventsFromStream(
                      this.futureEventsSyncToken ?? null,
                      null,
                      onlyUnread,
                  )
                : of(emptyNotificationList());

        const unreadNotificationCount$: Observable<number> =
            userNotificationsLastUpdateTimestamp != null
                ? this._doRetrieveUnreadNotificationsEventsCount()
                : of(this.unreadNotificationEventsCount.getValue());

        return forkJoin([notificationStream$, unreadNotificationCount$]).pipe(
            withLatestFrom(
                this.notificationBellState.currentActivityNotificationList.onDataChange(),
            ),
            tap(
                ([
                    [activityNotificationList, unreadNotificationCount],
                    currentNotificationEventList,
                ]) => {
                    if (this.userNotificationsLastUpdateTimestamp) {
                        this.setNewFutureNotificationsToRead(
                            activityNotificationList.list.filter(
                                (n) => !n.read,
                            ),
                        );
                    }

                    const aggregatedNotificationIds = flatten(
                        activityNotificationList.list
                            .filter(
                                (event) =>
                                    event.aggregatedNotificationIds?.length > 0,
                            )
                            .map((event) => event.aggregatedNotificationIds),
                    );

                    const newList: NotificationList =
                        currentNotificationEventList
                            ? {
                                  ...currentNotificationEventList,
                                  list: [
                                      ...activityNotificationList.list,
                                      ...currentNotificationEventList.list.filter(
                                          (event) =>
                                              !aggregatedNotificationIds.includes(
                                                  event.id,
                                              ),
                                      ),
                                  ],
                                  nextPageToken:
                                      activityNotificationList.nextPageToken,
                                  nextSyncToken:
                                      activityNotificationList.nextSyncToken,
                                  onlyUnread:
                                      currentNotificationEventList.onlyUnread,
                                  isFetching: false,
                              }
                            : {
                                  ...activityNotificationList,
                                  isFetching: false,
                              };

                    this.futureEventsSyncToken = newList.nextSyncToken;
                    this.setBufferNotifications(newList);

                    this.setUnreadNotificationEventsCount(
                        unreadNotificationCount,
                    );
                    this.userNotificationsLastUpdateTimestamp =
                        userNotificationsLastUpdateTimestamp;
                },
            ),
            finalize(() => (this.loadingFutureEvent = false)),
        );
    }

    private _doLoadMorePastEventsFromStream(): Observable<any> {
        const actualList =
            this.notificationBellState.currentActivityNotificationList.staticData();
        const pageToken = actualList
            ? getNextPageToken(actualList.nextPageToken)
            : null;
        const onlyUnread = actualList?.onlyUnread ?? false;
        if (actualList) {
            this.setBufferNotifications({
                ...actualList,
                isFetching: true,
            });
        }
        return this._activityStreamEvents(null, pageToken, onlyUnread).pipe(
            withLatestFrom(
                this.notificationBellState.currentActivityNotificationList.onDataChange(),
            ),
            tap(([result, currentActivityNotificationList]) => {
                const newList = currentActivityNotificationList
                    ? {
                          ...currentActivityNotificationList,
                          list: [
                              ...currentActivityNotificationList.list,
                              ...result.list,
                          ],
                          nextPageToken: result.nextPageToken,
                          nextSyncToken: result.nextSyncToken,
                          isFetching: false,
                          onlyUnread,
                      }
                    : {
                          ...result,
                          onlyUnread,
                      };
                this.setBufferNotifications(newList);
            }),
        );
    }

    private _doRetrieveUnreadNotificationsEventsCount(): Observable<number> {
        return this.http
            .get<{
                count: number;
                overscale: boolean;
            }>(`${this.baseUrl}/unread-notifications`)
            .pipe(map((res) => res.count));
    }

    private _doRetrieveNotificationsEventsFromStream(
        syncToken: string | null,
        pageToken: string | null,
        onlyUnread: boolean,
    ): Observable<NotificationList> {
        const collectAllPages = syncToken != null;

        return this._activityStreamEvents(
            syncToken,
            pageToken,
            onlyUnread,
        ).pipe(
            mergeMap((notificationList: NotificationList) => {
                if (
                    collectAllPages &&
                    notificationList.nextPageToken.tag === 'regularLoading'
                ) {
                    return this._activityStreamEvents(
                        null,
                        getNextPageToken(notificationList.nextPageToken),
                        onlyUnread,
                    );
                }
                return of(notificationList);
            }),
        );
    }

    private _activityStreamEvents(
        syncToken: string | null,
        pageToken: string | null,
        onlyUnread: boolean,
    ): Observable<NotificationList> {
        const httpGetActivityStreamEventsOptions = { params: {} as any };

        if (pageToken != null) {
            httpGetActivityStreamEventsOptions.params['pageToken'] = pageToken;
        }

        if (syncToken != null) {
            httpGetActivityStreamEventsOptions.params['syncToken'] = syncToken;
        }

        if (onlyUnread) {
            httpGetActivityStreamEventsOptions.params['onlyUnread'] =
                onlyUnread;
        }

        return this.http
            .get<any>(
                `${this.baseUrl}/notifications-stream`,
                httpGetActivityStreamEventsOptions,
            )
            .pipe(
                map((responseData: any) =>
                    toActivityNotificationList(
                        { ...responseData, onlyUnread },
                        this.configurationService,
                    ),
                ),
            );
    }

    private setBufferNotifications(
        notificationEventList: NotificationList,
    ): void {
        this.notificationBellState.currentActivityNotificationList.updateData(
            notificationEventList,
        );
    }

    private setUnreadNotificationEventsCount(unreadNotification: number) {
        this.unreadNotificationEventsCount.next(unreadNotification);
    }

    private resetNotificationsReceiverStatus(): void {
        this.futureEventsSyncToken = undefined;
        const initState: NotificationList = {
            ...emptyPaginatedList<IEventActivity>(),
            nextSyncToken: undefined,
            onlyUnread: false,
        };
        this.setBufferNotifications(initState);
        this.setUnreadNotificationEventsCount(0);
    }

    private onInit() {
        const notificationStream$ = this.pushChannelsService
            .getUserNotificationsLastUpdateTimestamp$()
            .pipe(
                withLatestFrom(this.store.select(notificationPanel)),
                mergeMap(([timestamp, notificationPanel]) =>
                    this._doUpdateFutureEventsFromStream(
                        timestamp,
                        notificationPanel?.onlyUnread ?? false,
                    ),
                ),
            );

        this.authService.sessionState$.subscribe((sessionState) => {
            if (sessionState.signedIn && !this.userChannelStreamSubscription) {
                this.resetNotificationsReceiverStatus();
                this.userChannelStreamSubscription =
                    notificationStream$.subscribe({
                        error: (error) =>
                            console.error('WARNING NOTIFICATION STREAM', error),
                    });
            } else {
                this.userNotificationsLastUpdateTimestamp = null;
                if (this.userChannelStreamSubscription != null) {
                    this.userChannelStreamSubscription.unsubscribe();
                    this.userChannelStreamSubscription = null;
                }
            }
        });
    }
}
