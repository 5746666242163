import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonComponent } from '@interacta-shared/ui';
import { filterMap } from '@interacta-shared/util';
import { AppSelectors, AppState } from '@modules/core/store';
import { AppRouterState } from '@modules/core/store/app-router.serializer';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'interacta-skip-links',
    standalone: true,
    imports: [CommonModule, TranslateModule, ButtonComponent],
    templateUrl: './skip-links.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/*TODO:
 **The commented code is about improvements for link skip blocks,
 **so that on each page you decide which to show
 */
export class SkipLinksComponent implements OnInit {
    routeState$!: Observable<AppRouterState>;
    //showMainContentButton$ = new BehaviorSubject(true);
    //showSidebarButton$ = new BehaviorSubject(true);

    constructor(
        private router: Router,
        private store: Store<AppState>,
    ) {}

    ngOnInit(): void {
        this.routeState$ = this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                filterMap((route) => route),
                // tap((route) => {
                //     if (route.appBaseRoute === 'login') {
                //         this.showMainContentButton$.next(false);
                //         this.showSidebarButton$.next(false);
                //     }
                // })
            );
    }

    setFocus(goTo: 'main-content' | 'sidebar'): void {
        const goToContent = document.getElementById(goTo) as HTMLElement;
        if (goToContent) {
            this.router.navigate([], {
                fragment: goTo,
            });
            goToContent.focus();
        }
    }
}
