import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TABLE_INFO_FEATURE_KEY, TableInfoState } from './table-info.reducer';

// Lookup the 'TableInfo' feature state managed by NgRx
export const selectTableInfoState = createFeatureSelector<TableInfoState>(
    TABLE_INFO_FEATURE_KEY,
);

export const selectTable = createSelector(
    selectTableInfoState,
    (state) => state.table,
);

export const selectVisible = createSelector(
    selectTableInfoState,
    (state) => state.detail.visible,
);
