import { inject, Injectable } from '@angular/core';
import { ErrorService } from '@interacta-shared/data-access-error';
import { filterMap } from '@interacta-shared/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { BulkWorkflowTransitionService } from '../services/bulk-workflow-transition.service';
import {
    abortCurrentOperation,
    fetchLastUnreadOperation,
    markCurrentOperationAsRead,
} from './bulk-workflow-transition.actions';
import {
    abortCurrentOperationError,
    abortCurrentOperationSuccess,
    fetchLastUnreadOperationError,
    fetchLastUnreadOperationSuccess,
    markCurrentOperationAsReadError,
    markCurrentOperationAsReadSuccess,
} from './bulk-workflow-transition.actions-api';
import { selectCurrentOperation } from './bulk-workflow-transition.selectors';

@Injectable()
export class BulkWorkflowTransitionEffects {
    private actions$ = inject(Actions);
    private bulkWorkflowTransitionService = inject(
        BulkWorkflowTransitionService,
    );
    private errorService = inject(ErrorService);
    private store = inject(Store);

    fetchLastUnreadOperation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchLastUnreadOperation),
            switchMap(() =>
                this.bulkWorkflowTransitionService
                    .fetchBulkPostWorkflowOperations()
                    .pipe(
                        map((operations) => operations[0] ?? null),
                        map((operation) =>
                            fetchLastUnreadOperationSuccess({ operation }),
                        ),
                        catchError((error) =>
                            of(fetchLastUnreadOperationError({ error })),
                        ),
                    ),
            ),
        ),
    );

    abortCurrentOperation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(abortCurrentOperation),
            withLatestFrom(this.store.select(selectCurrentOperation)),
            filterMap(([, operation]) =>
                operation ? operation.operationId : undefined,
            ),
            switchMap((operationId) =>
                this.bulkWorkflowTransitionService
                    .abortBulkPostWorkflowOperation(operationId)
                    .pipe(
                        map(() => abortCurrentOperationSuccess()),
                        catchError((error) =>
                            of(abortCurrentOperationError({ error })),
                        ),
                    ),
            ),
        ),
    );

    markCurrentOperationAsRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(markCurrentOperationAsRead),
            withLatestFrom(this.store.select(selectCurrentOperation)),
            filterMap(([, operation]) =>
                operation ? operation.operationId : undefined,
            ),
            switchMap((operationId) =>
                this.bulkWorkflowTransitionService
                    .markBulkPostWorkflowOperationAsRead(operationId)
                    .pipe(
                        map(() => markCurrentOperationAsReadSuccess()),
                        catchError((error) =>
                            of(markCurrentOperationAsReadError({ error })),
                        ),
                    ),
            ),
        ),
    );

    notifyError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    markCurrentOperationAsReadError,
                    abortCurrentOperationError,
                    fetchLastUnreadOperationError,
                ),
                tap(({ error }) => {
                    this.errorService.handle(error);
                }),
            ),
        { dispatch: false },
    );
}
