import { Injectable, inject } from '@angular/core';
import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { ConsoleService } from '@interacta-shared/data-access-configuration';
import * as fromRoutes from '@modules/app-routing/routes';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface AppRouterState {
    url: string;
    params: Params;
    queryParams: Params;
    appRoute: fromRoutes.AppRoute | null;
    appBaseRoute: fromRoutes.AppBaseRoute | null;
    data: Data;
}

@Injectable({ providedIn: 'root' })
export class AppRouterSerializer
    implements RouterStateSerializer<AppRouterState>
{
    private readonly consoleService = inject(ConsoleService);

    serialize(routerState: RouterStateSnapshot): AppRouterState {
        let root = routerState.root;
        let params = {};

        while (root.firstChild) {
            params = { ...params, ...root.firstChild.params };
            root = root.firstChild;
        }

        const {
            url,
            root: { queryParams },
        } = routerState;

        const appRoute = fromRoutes.urlToAppRoute(url);
        const appBaseRoute = fromRoutes.toBaseRoute(appRoute);

        if (appRoute === null) {
            this.consoleService.warningDev(
                'AppRouterSerializer.serialize(): appRoute is null with url' +
                    url,
            );
        }
        if (appBaseRoute === null) {
            this.consoleService.warningDev(
                'AppRouterSerializer.serialize(): appBaseRoute is null with url' +
                    url,
            );
        }

        return {
            url,
            params,
            queryParams,
            appRoute,
            appBaseRoute,
            data: root.data,
        };
    }
}
