import { isDefined, paginatedListFromArray } from '@interacta-shared/util';
import { isActivityPost } from '@modules/post/models/activity-post/activity-post.model';
import { PostDetailSection } from '../utility.model';
import {
    EventType,
    IEventActivity,
    INotificationNavigation,
    NotificationList,
} from './notification-user.model';

export const navigationNotificationActivity = (
    notification: IEventActivity,
): INotificationNavigation | null => {
    switch (notification.typeId) {
        case EventType.ADD_ATTACHMENT:
        case EventType.REMOVE_ATTACHMENT:
        case EventType.ADD_ATTACHMENT_VERSION:
            return openPostDetails(notification, PostDetailSection.ATTACHMENTS);
        case EventType.COMMENT:
        case EventType.REMOVE_COMMENT:
        case EventType.MENTION_COMMENT:
        case EventType.LIKE_COMMENT:
        case EventType.ADD_COMMENT_ON_YOUR_POST:
        case EventType.REPLY_YOUR_COMMENT:
            return openPostDetails(notification, PostDetailSection.COMMENTS);
        case EventType.CHANGE_STATUS:
        case EventType.EDIT_SCREEN_DATA:
            return openPostDetails(notification, PostDetailSection.WORKFLOW);
        case EventType.CREATE_TASK:
        case EventType.EDIT_TASK:
        case EventType.COPY_TASK:
        case EventType.DELETE_TASK:
        case EventType.TASK_REMINDER:
        case EventType.ADD_COMMENT_ON_TASK:
        case EventType.MENTION_TASK_COMMENT:
        case EventType.DELETE_TASK_COMMENT:
        case EventType.MENTION_TASK:
        case EventType.ASSIGNED_TASK:
        case EventType.ADD_TASK_ATTACHMENT:
        case EventType.REMOVE_TASK_ATTACHMENT:
        case EventType.REOPENED_YOUR_TASK:
        case EventType.CLOSED_YOUR_TASK:
        case EventType.DELETED_YOUR_TASK:
            return openPostDetails(notification, PostDetailSection.TASKS);
        case EventType.EVENT_INVITATION_STAFF:
            return openPostDetails(notification, PostDetailSection.INVITATIONS);
        case EventType.ACTIVITY_ASSIGNED:
        case EventType.ACTIVITY_EDIT:
            return openPostDetails(
                notification,
                PostDetailSection.SUBACTIVITIES,
            );
        case EventType.FEEDBACK_TASK_CHANGE_STATUS: {
            const postId = notification.post?.id;
            return postId
                ? {
                      id: postId,
                      url: [
                          '/post',
                          'feedback',
                          postId.toString(),
                          'recipient',
                      ],
                      event: notification,
                      queryParams: {
                          taskId: !notification.task?.deleted
                              ? notification.task?.id
                              : undefined,
                      },
                  }
                : null;
        }
        case EventType.REMOVE_POST:
            return null;
        default:
            return openPostDetails(notification);
    }
};

const openPostDetails = (
    notification: IEventActivity,
    flowType?: PostDetailSection,
): INotificationNavigation => {
    const postId = notification.post?.id;
    if (!isDefined(postId)) {
        throw new Error('missing required post id');
    }

    if (!flowType && notification.post && isActivityPost(notification.post)) {
        flowType = PostDetailSection.SUBACTIVITIES;
    }

    const queryParams = flowType
        ? {
              entity: flowType,
              commentId:
                  flowType === PostDetailSection.COMMENTS &&
                  !notification.comment?.deleted &&
                  notification.comment?.id
                      ? notification.comment.id
                      : undefined,
              taskId:
                  flowType === PostDetailSection.TASKS &&
                  !notification.task?.deleted &&
                  notification.task?.id
                      ? notification.task.id
                      : undefined,
          }
        : undefined;

    return {
        id: postId,
        url: ['/post', postId.toString()],
        event: notification,
        queryParams,
    };
};

export const emptyNotificationList = (): NotificationList => {
    return {
        ...paginatedListFromArray<IEventActivity>([]),
        onlyUnread: false,
    };
};

export const TaskEventTypes = [
    EventType.CREATE_TASK,
    EventType.EDIT_TASK,
    EventType.COPY_TASK,
    EventType.DELETE_TASK,
    EventType.TASK_REMINDER,
    EventType.ASSIGNED_TASK,
    EventType.OPENED_TASK,
    EventType.CLOSED_TASK,
    EventType.REOPENED_YOUR_TASK,
    EventType.CLOSED_YOUR_TASK,
    EventType.DELETED_YOUR_TASK,
];

export const ActivityPostEventTypes = [
    EventType.CREATION, //this is the same as other post types, we have to handle properly
    EventType.ACTIVITY_ASSIGNED,
    EventType.ACTIVITY_EDIT,
];
