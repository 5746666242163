import { Injectable } from '@angular/core';
import { AuthActions, AuthService } from '@interacta-shared/data-access-auth';
import { login } from '@modules/app-routing/routes';
import { PostActionsApi } from '@modules/communities/store';
import { Theme, systemTheme } from '@modules/core/models/theme.model';
import { LocalStorageService } from '@modules/core/services/local-storage.service';
import { NotificationBellStreamService } from '@modules/core/services/notification-bell-stream.service';
import { DiscoveryService } from '@modules/discovery/services/discovery.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromRouter from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, defer, fromEventPattern, iif, of } from 'rxjs';
import {
    concatMap,
    filter,
    first,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { UIActions } from '.';
import { AppSelectors, AppState } from '..';
import { notificationPanel } from './ui.selector';

const THEME_STORAGE_KEY = 'theme';
const ONLY_UNREAD_STORAGE_KEY = 'onlyUnreadNotification';

@Injectable()
export class UiEffects {
    closeFullscreen$ = createEffect(() =>
        this.actions.pipe(
            ofType(
                PostActionsApi.addCommentSuccess,
                PostActionsApi.addStreamingCommentSuccess,
                PostActionsApi.saveEditCommentSuccess,
                fromRouter.routerNavigatedAction,
            ),
            map(() => UIActions.closeCommentFullScreen()),
        ),
    );

    systemTheme$: Observable<Theme> = iif(
        () =>
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme)').media !== 'not all',
        defer(() => {
            const mediaQuery = window.matchMedia(
                '(prefers-color-scheme: dark)',
            );

            return fromEventPattern<MediaQueryListEvent>(
                (handler) =>
                    mediaQuery.addEventListener
                        ? mediaQuery.addEventListener('change', handler)
                        : mediaQuery.addListener(handler),
                (handler) =>
                    mediaQuery.removeEventListener
                        ? mediaQuery.removeEventListener('change', handler)
                        : mediaQuery.removeListener(handler),
            ).pipe(
                map((event) => ({
                    isSystem: true,
                    mode: event.matches
                        ? ('dark' as const)
                        : ('light' as const),
                })),
            );
        }),
        EMPTY,
    );

    forceLightThemeForLoginPage$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromRouter.routerNavigatedAction),
            concatMap(() =>
                this.store.select(AppSelectors.selectRouteState).pipe(first()),
            ),
            filter((route) => route?.appRoute === login),
            map(() =>
                UIActions.changeTheme({
                    theme: { isSystem: false, mode: 'light' },
                    dontSaveInStorage: true,
                }),
            ),
        ),
    );

    changeAppThemeWhenSystemThemeChanges$ = createEffect(() =>
        this.systemTheme$.pipe(
            withLatestFrom(
                this.authService.currentUserData$,
                this.store.pipe(map((state) => state.uiState.theme)),
            ),
            // We change the theme only if the user is using the system one
            filter(
                ([_, currentUser, userTheme]) =>
                    currentUser != null && userTheme?.isSystem === true,
            ),
            map(([theme]) => UIActions.changeTheme({ theme })),
        ),
    );

    initializeUserTheme$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.login),
            map(() =>
                this.localStorageService.getEntry<Theme>(THEME_STORAGE_KEY),
            ),
            map((cachedTheme) => UIActions.initTheme({ cachedTheme })),
        ),
    );

    openProTip$ = createEffect(() =>
        this.actions.pipe(
            ofType(UIActions.initTheme),
            filter(
                ({ cachedTheme }) =>
                    !cachedTheme && systemTheme().mode === 'dark',
            ),
            mergeMap(() =>
                this.discoveryService.openTip(
                    { id: 'systemTheme' },
                    {
                        title: 'THEME.TIP_TITLE',
                        message: 'THEME.TIP_DESCRIPTION',
                        tipActions: [
                            {
                                id: 'switchToLightMode',
                                label: 'THEME.GO_TO_LIGHT',
                                data: {},
                            },
                        ],
                    },
                ),
            ),
            filter((actionTip) => actionTip !== 'close'),
            map(() =>
                UIActions.changeTheme({
                    theme: {
                        mode: 'light',
                        isSystem: false,
                    },
                }),
            ),
        ),
    );

    changeTheme$ = createEffect(() =>
        this.actions.pipe(
            ofType(UIActions.initTheme),
            switchMap((action) =>
                of(action).pipe(
                    map(({ cachedTheme }) =>
                        UIActions.changeTheme({
                            theme: cachedTheme ?? systemTheme(),
                        }),
                    ),
                ),
            ),
        ),
    );

    applyTheme$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(UIActions.changeTheme),
                tap(({ theme, dontSaveInStorage }) => {
                    if (theme.mode === 'dark') {
                        document.body.classList.add('dark');
                    } else {
                        document.body.classList.remove('dark');
                    }
                    if (!dontSaveInStorage) {
                        this.localStorageService.setEntry(
                            THEME_STORAGE_KEY,
                            theme.mode,
                            false,
                        );
                        this.localStorageService.setEntry(
                            THEME_STORAGE_KEY,
                            theme,
                            true,
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    initializeOnlyUnreadNotification$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.login),
            map(() => {
                const onlyUnread =
                    this.localStorageService.getEntry<boolean>(
                        ONLY_UNREAD_STORAGE_KEY,
                    ) ?? false;
                return UIActions.toggleOnlyUnreadNotification({
                    onlyUnread,
                });
            }),
        ),
    );

    removeAllRead$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(UIActions.toggleNotificationPanel),
                concatMap((action) =>
                    of(action).pipe(
                        withLatestFrom(this.store.select(notificationPanel)),
                    ),
                ),
                tap(([{ isOpen }, notificationPanel]) => {
                    if (!isOpen && notificationPanel?.onlyUnread) {
                        this.notificationStreamService.removeAllRead();
                    }
                }),
            ),
        { dispatch: false },
    );

    toggleOnlyUnreadNotification$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(UIActions.toggleOnlyUnreadNotification),
                concatMap((action) =>
                    of(action).pipe(
                        withLatestFrom(this.store.select(notificationPanel)),
                        withLatestFrom(
                            this.notificationStreamService.getBufferNotificationsStream(),
                        ),
                    ),
                ),
                tap(
                    ([
                        [{ onlyUnread }, notificationPanel],
                        notificationList,
                    ]) => {
                        if (notificationPanel?.isOpen) {
                            this.localStorageService.setEntry(
                                ONLY_UNREAD_STORAGE_KEY,
                                onlyUnread,
                            );
                        }

                        if (
                            notificationList &&
                            notificationList.nextPageToken.tag !==
                                'firstLoading' &&
                            notificationList.onlyUnread !== onlyUnread
                        ) {
                            this.notificationStreamService.toggleOnlyUnreadNotificationAndFetchStream(
                                onlyUnread,
                            );
                        }
                    },
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private actions: Actions,
        private store: Store<AppState>,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private notificationStreamService: NotificationBellStreamService,
        private discoveryService: DiscoveryService,
    ) {}
}
