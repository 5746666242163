import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { translateFadeAnimation } from '@interacta-shared/ui';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ProTipData,
    TipActionCallback,
    TipData,
    TipSeverity,
} from '../../models/tip.model';
import { isProTip } from '../../models/tip.utils';
import { closeCurrentTip } from '../../store/tip.actions';
import { selectCurrentTip } from '../../store/tip.selectors';
import { ProTipComponent } from '../pro-tip/pro-tip.component';
import { SimpleTipComponent } from '../simple-tip/simple-tip.component';

@Component({
    standalone: true,
    imports: [CommonModule, SimpleTipComponent, ProTipComponent],
    selector: 'interacta-tip-overlay',
    templateUrl: './tip-overlay.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [translateFadeAnimation('tip', null, '3rem', 'in-out')],
})
export class OverlayTipsComponent<A, P> {
    simpleTip$: Observable<TipData | null>;
    proTip$: Observable<ProTipData<A, P> | null>;

    TipSeverity = TipSeverity;

    constructor(private store: Store) {
        const currentTip$ = store.select(selectCurrentTip);
        this.simpleTip$ = currentTip$.pipe(
            map((tip) => (tip != null && !isProTip(tip) ? tip : null)),
        );
        this.proTip$ = currentTip$.pipe(
            map((tip) => (tip != null && isProTip(tip) ? tip : null)),
        ) as Observable<ProTipData<A, P>>;
    }

    close(callback?: () => void): void {
        this.store.dispatch(closeCurrentTip());
        if (callback) {
            callback();
        }
    }

    forceReload(): void {
        window.location.reload();
    }

    actionClicked(actionCallback: TipActionCallback<A>): void {
        this.close();
        actionCallback.callback(actionCallback.action);
    }
}
