<div
    class="typo-sm flex w-full items-center rounded-2xl px-4 py-8 text-left"
    matRipple
    [matRippleDisabled]="disabled"
    [ngClass]="{
        'hover:bg-surface-100': !disabled,
        'justify-between': width === 'content',
        'justify-center': width === 'full',
    }"
>
    <div class="flex items-center">
        <interacta-icon
            *ngIf="icon"
            class="text-text-low-contrast mr-12 inline-block h-24 w-24"
            [icon]="icon"
        ></interacta-icon>

        <ng-content></ng-content>
    </div>

    <interacta-icon
        *ngIf="withArrow"
        class="h-36 w-36"
        icon="chevron-small-right"
    ></interacta-icon>
</div>
