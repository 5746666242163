<form
    *ngIf="{
        query: formGroup.controls.search | formControlValueV2$ | async,
    } as $"
    [formGroup]="formGroup"
    (ngSubmit)="search($.query)"
>
    <interacta-advanced-search-bar
        #advancedSearchBar
        [bgColor]="
            advancedSearchBar?.isInputFocused$?.value
                ? 'surface-A'
                : 'surface-B'
        "
        [closedInputSize]="$.query ? '420' : '172'"
        [openLeft]="true"
        [search]="formGroup.controls.search"
        (inputFocused)="isAdvancedSearchOpen = false"
    >
        <div advanced-search class="mt-12">
            <!-- l'evento per chiudere la searchbar si basa su componenti montati, quindi devo nasconderli perché funzioni (non posso usare gli if) -->
            <div
                class="flex flex-col gap-y-12"
                [class.hidden]="isAdvancedSearchOpen"
            >
                <div class="flex gap-8">
                    <interacta-icon class="size-24" [icon]="'lightbulb'" />
                    <p>{{ 'SEARCH.HINT' | translate }}</p>
                </div>
                <interacta-separator [thickness]="'soft'" />
                <button
                    interacta-button
                    type="button"
                    [active]="true"
                    [appearance]="'ghost'"
                    [label]="'SEARCH.ADVANCED' | translate"
                    (click)="setAdvancedSearch(true)"
                ></button>
            </div>

            <interacta-advanced-search-panel
                [class.hidden]="!isAdvancedSearchOpen"
                [formGroup]="formGroup.controls.advanced"
                (closePanel)="setAdvancedSearch(false)"
                (search)="advancedSearch($event)"
            />
        </div>
    </interacta-advanced-search-bar>
</form>
