import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApplyPipe } from '@interacta-shared/ui';
import { AIFabComponent } from '@modules/ai/components/ai-fab/ai-fab.component';
import { BottomMenuItem } from '@modules/bottom-menu/models/bottom-menu-state.model';
import { ChatPanelComponent } from '@modules/chat/components/chat-panel/chat-panel.component';
import { Observable } from 'rxjs';
import { BottomMenuService } from '../../services/bottom-menu.service';

@Component({
    selector: 'interacta-bottom-menu',
    standalone: true,
    imports: [CommonModule, ApplyPipe],
    templateUrl: './bottom-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomMenuComponent {
    components$: Observable<BottomMenuItem[]>;

    constructor(private bottomMenuService: BottomMenuService) {
        this.components$ = this.bottomMenuService.getComponentsStream();
    }

    getComponentClasses(data: {
        component: BottomMenuItem;
        $index: number;
    }): string {
        const { component, $index } = { ...data };

        if (component.type === ChatPanelComponent) {
            return `z-auto transition-all duration-250 ${$index === 0 ? 'right-32' : 'right-96'}`;
        }

        if (component.type === AIFabComponent) {
            return 'right-0 z-10';
        }

        return 'right-0';
    }
}
