import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    bulkWorkflowTransitionFeatureKey,
    BulkWorkflowTransitionState,
} from './bulk-workflow-transition.reducer';

export const selectBulkWorkflowTransitionFeature =
    createFeatureSelector<BulkWorkflowTransitionState>(
        bulkWorkflowTransitionFeatureKey,
    );

export const selectCurrentOperation = createSelector(
    selectBulkWorkflowTransitionFeature,
    (state) => state.currentOperation,
);

export const selectCurrentOperationIsFetching = createSelector(
    selectBulkWorkflowTransitionFeature,
    (state) => state.isFetching,
);

export const selectCurrentOperationIsBusy = createSelector(
    selectBulkWorkflowTransitionFeature,
    (state) => state.isBusy,
);
