import {
    animate,
    group,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    NgZone,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import {
    getNextPageToken,
    isDefined,
    lastLoading,
} from '@interacta-shared/util';
import * as PostActions from '@modules/communities/store/post/post.actions';
import { PostDetailSection } from '@modules/core';
import {
    EventType,
    IEventActivity,
    INotificationNavigation,
    NotificationList,
} from '@modules/core/models/notification-user/notification-user.model';
import { navigationNotificationActivity } from '@modules/core/models/notification-user/notification-user.utils';
import { HtmlNotificationService } from '@modules/core/services/html-notification.service';
import { NotificationBellStreamService } from '@modules/core/services/notification-bell-stream.service';
import { UIActions } from '@modules/core/store/UI';
import { NotificationPanel, UIState } from '@modules/core/store/UI/ui.reducer';
import * as UISelectors from '@modules/core/store/UI/ui.selector';
import { IListAttachments } from '@modules/post/models/attachment/attachment.model';
import { GalleryStateService } from '@modules/state/services/gallery-state.service';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'interacta-notifications-panel',
    templateUrl: './notifications-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('panel', [
            transition(':enter', [
                group([
                    query(':self', [
                        style({ opacity: 0 }),
                        animate('300ms ease', style({ opacity: 1 })),
                    ]),
                    query('#notifications-panel', [
                        style({ opacity: 0, transform: 'translateX(100%)' }),
                        animate(
                            '200ms ease-out',
                            style({ opacity: 1, transform: 'translateX(0)' }),
                        ),
                    ]),
                ]),
            ]),
            transition(':leave', [
                group([
                    query(':self', [
                        animate('300ms ease', style({ opacity: 0 })),
                    ]),
                    query('#notifications-panel', [
                        animate(
                            '300ms ease',
                            style({
                                opacity: 0,
                                transform: 'translateX(100%)',
                            }),
                        ),
                    ]),
                ]),
            ]),
        ]),
    ],
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
    notificationPanelUi$!: Observable<NotificationPanel>;
    notifications$!: Observable<NotificationList | undefined>;
    unreadNotification$!: Observable<number>;
    displayedNotifications: IEventActivity[] = [];

    private destroy$ = new Subject<void>();

    constructor(
        private notificationStreamService: NotificationBellStreamService,
        private galleryStateService: GalleryStateService,
        private store: Store<UIState>,
        private router: Router,
        private htmlNotificationService: HtmlNotificationService,
        private zone: NgZone,
    ) {}

    @HostListener('document:keydown.escape', ['$event'])
    onEscapeDown(): void {
        this.close();
    }

    ngOnInit(): void {
        this.notificationPanelUi$ = this.store
            .select(UISelectors.notificationPanel)
            .pipe(filter(isDefined));
        this.notifications$ =
            this.notificationStreamService.getBufferNotificationsStream();
        this.unreadNotification$ =
            this.notificationStreamService.getUnreadNotificationCountStream();

        this.notificationStreamService
            .getNewFutureNotificationsToRead()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (newNotifications: IEventActivity[]) => {
                    newNotifications
                        .filter((notification) => notification.pushEnabled)
                        .forEach((notification) => {
                            const navigateObject =
                                navigationNotificationActivity(notification);
                            if (navigateObject) {
                                const openNotificationCallback =
                                    this.openNotificationCallback.bind(this);

                                this.htmlNotificationService.open(
                                    notification.descriptionPlainText,
                                    notification.title,
                                    notification.id.toString(),
                                    navigateObject,
                                    openNotificationCallback,
                                );
                            }
                        });
                },
                error: (error) => {
                    console.error('Buffer Notificaions Stream FAILED: ', error);
                },
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    close(): void {
        this.store.dispatch(
            UIActions.toggleNotificationPanel({ isOpen: false }),
        );
    }

    markNotificationEventsAsRead(
        notification: IEventActivity,
        close: boolean,
    ): void {
        this.notificationStreamService
            .markNotificationEventsAsRead([notification])
            .pipe(first())
            .subscribe(() => {
                if (close) {
                    this.close();
                }
            });
    }

    markAllNotificationEventsAsRead(): void {
        this.notificationStreamService
            .markAllNotificationEventsAsRead()
            .pipe(first())
            .subscribe();
    }

    loadMorePastNotification(): void {
        this.notificationStreamService
            .loadPastNotifications()
            .pipe(first())
            .subscribe();
    }

    toggleShowNotRead(onlyUnread: boolean): void {
        this.store.dispatch(
            UIActions.toggleOnlyUnreadNotification({ onlyUnread }),
        );
    }

    nextPage(event: IPageInfo, notifications: NotificationList): void {
        if (
            event.endIndex === notifications.list.length - 1 &&
            !notifications.isFetching &&
            notifications.nextPageToken &&
            getNextPageToken(notifications.nextPageToken) != null
        ) {
            this.loadMorePastNotification();
        }
    }

    clickOnNotification(navigateObject: INotificationNavigation): void {
        if (!navigateObject.event.read) {
            this.markNotificationEventsAsRead(navigateObject.event, true);
        } else {
            this.close();
        }
        this.openNotificationPage(navigateObject);
    }

    viewAttachments(notification: IEventActivity): void {
        const attachments = [
            ...notification.addedAttachments,
            ...notification.updatedAttachments,
            ...notification.versionedAttachments,
        ];

        const attachmentList: IListAttachments = {
            attachments,
            pageTokenInfo: lastLoading(),
            totalCount: attachments.length,
            isLoading: false,
            isError: false,
        };

        this.galleryStateService.openGallery(
            {
                feature: 'embedded',
                attachmentList,
            },
            0,
        );
    }

    private openNotificationCallback(
        navigateObject: INotificationNavigation,
    ): void {
        this.zone.run(() => this.clickOnNotification(navigateObject));
    }

    private openNotificationPage(navigateObject: INotificationNavigation) {
        if (
            this.router.url.indexOf(navigateObject.id.toString()) > -1 ||
            navigateObject.event.typeId === EventType.LIKE_COMMENT
        ) {
            const postId = navigateObject.id;
            this.store.dispatch(
                PostActions.reload({
                    postId,
                    reloadComments:
                        navigateObject.queryParams?.entity ===
                        PostDetailSection.COMMENTS,
                    parentCommentId: navigateObject.queryParams?.commentId,
                }),
            );
        }
        this.router.navigate(navigateObject.url, {
            queryParams: navigateObject.queryParams ?? undefined,
        });
    }
}
