import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    OnInit,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Theme, selectCurrentTheme } from '@interacta-shared/data-access-theme';
import { filterMap } from '@interacta-shared/util';
import { AppState } from '@modules/core/store';
import { ChatStateService } from '@modules/state/services/chat-state.service';
import { StateService } from '@modules/state/services/state.service';
import { Store } from '@ngrx/store';
import {
    BehaviorSubject,
    Observable,
    combineLatest,
    distinctUntilChanged,
} from 'rxjs';

@Component({
    selector: 'interacta-chat-panel',
    templateUrl: './chat-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatPanelComponent implements OnInit {
    private chat?: ElementRef<HTMLIFrameElement>;

    @ViewChild('chat') set chatFrame(value: ElementRef<HTMLIFrameElement>) {
        requestAnimationFrame(() => {
            this.chat = value;
            this.renderedIframe.next(!!value?.nativeElement);
        });
    }

    readonly ANIMATION_DURATION = 150;
    readonly theme$: Observable<Theme>;

    renderedIframe = new BehaviorSubject(false);
    registered = false;

    private readonly chatStateService = inject(ChatStateService);
    private readonly destroyRef = inject(DestroyRef);
    private readonly store = inject(Store<AppState>);
    readonly appState = inject(StateService);

    constructor() {
        this.theme$ = this.store
            .select(selectCurrentTheme)
            .pipe(filterMap((theme) => theme ?? undefined));
    }

    ngOnInit(): void {
        this.theme$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                if (this.chatStateService.state.chatUrl$.getValue() === '') {
                    this.chatStateService.setChatUrl(value);
                } else {
                    this.chatStateService.changeTheme(value);
                }
            });

        this.appState.chatState.isCollapsed$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                if (!this.chat) return;

                if (value) {
                    setTimeout(() => {
                        this.chatStateService.updateChatVisibility(
                            'background',
                        );
                    }, this.ANIMATION_DURATION);
                } else {
                    this.chatStateService.updateChatVisibility('foreground');
                    if (this.chatStateService.isOpen())
                        this.chat?.nativeElement.focus();
                }
            });

        combineLatest([this.appState.chatState.canShow$, this.renderedIframe])
            .pipe(
                distinctUntilChanged(
                    ([prevEnabled, prevRender], [currEnabled, currRender]) =>
                        (prevEnabled && prevRender) ===
                        (currEnabled && currRender),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(([isEnabled, renderIframe]) => {
                !this.registered && isEnabled && renderIframe
                    ? this.register()
                    : this.unregister();
            });
    }

    toggleCollapsed(): void {
        this.chatStateService.toggleCollapse();
    }

    openChatInNewTab(): void {
        this.chatStateService.openInNewTab();
    }

    close(): void {
        this.chatStateService.close();
    }

    private register(): void {
        if (!this.chat?.nativeElement?.contentWindow) return;

        this.chatStateService.registerChat(
            this.chat.nativeElement.contentWindow,
        );

        this.registered = true;
    }

    private unregister(): void {
        //do nothing
    }
}
