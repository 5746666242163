import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    ICarouselConfig,
    ISlide,
} from '@modules/carousel/models/carousel/carousel.model';

@Component({
    selector: 'interacta-carousel-slide',
    templateUrl: './carousel-slide.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselSlideComponent {
    @Input({ required: true }) slide!: ISlide;
    @Input() index!: number;
    @Input() totalItems!: number;
    @Input() config!: ICarouselConfig;

    @Output() nextStep = new EventEmitter<number>();
    @Output() closeCarousel = new EventEmitter<void>();
}
