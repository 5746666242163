<interacta-dialog
    *ngIf="state$ | async as state"
    [isOpen]="state.isOpen"
    [size]="state.content.size"
    (closing)="close(false)"
>
    <h6 class="typo-t6" title>
        {{ state.content.title | translate: state.content.translationVars }}
    </h6>

    <interacta-dialog-description>
        <span
            [innerHTML]="
                state.content.description
                    | translate: state.content.translationVars
            "
        ></span>
    </interacta-dialog-description>

    <div *ngIf="state.content.template" content>
        <ng-container *ngTemplateOutlet="state.content.template" />
    </div>

    <interacta-dialog-buttons>
        <button
            *ngIf="state.content.cancel"
            interacta-dialog-button
            type="button"
            [appearance]="'ghost'"
            [bgColor]="'surface-A'"
            [label]="state.content.cancel | translate"
            (click)="close(false)"
        ></button>
        <button
            *ngIf="state.content.confirm"
            interacta-dialog-button
            type="button"
            [appearance]="'fill'"
            [bgColor]="'primary'"
            [label]="state.content.confirm | translate"
            (click)="close(true)"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
