<ng-container
    *ngIf="{
        table: table$ | async,
        visible: (visible$ | async) ?? false,
    } as $"
>
    <ng-container *ngIf="$.visible && $.table != null as isOpen">
        <interacta-dialog
            size="fit-content"
            [isOpen]="isOpen"
            (closing)="close()"
        >
            <div class="typo-t6 line-clamp-3 break-all" title>
                {{ $.table.title }}
            </div>

            <div class="typo-medium flex flex-col space-y-16" content>
                <interacta-editable-table
                    [ngModel]="$.table.data"
                    [readonly]="true"
                ></interacta-editable-table>
            </div>
        </interacta-dialog>
    </ng-container>
</ng-container>
