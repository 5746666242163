<div
    class="flex h-screen flex-col items-center bg-cover"
    style="
        background-image: url('./assets/shared/images/out-of-service-background.jpg');
    "
>
    <div
        class="flex w-full shrink-0 items-center justify-between p-64"
        [attr.aria-hidden]="true"
    >
        <svg
            xml:space="preserve"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            id="Livello_1"
            style="enable-background: new 0 0 1080 1080"
            version="1.1"
            viewBox="0 0 1080 1080"
            width="68"
            x="0px"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
        >
            <style type="text/css">
                .st0 {
                    fill: #f7335c;
                }
            </style>
            <g>
                <g>
                    <g>
                        <path
                            class="st0"
                            d="M780,81H629.8c-49.6,0-89.8,40.2-89.8,89.8V323h150.2h0c49.6,0,89.8-40.2,89.8-89.8v-88.8V81z"
                        />
                        <path
                            class="st0"
                            d="M540,323H389.8c-49.6,0-89.8,40.2-89.8,89.8V998h150.2h0c49.6,0,89.8-40.2,89.8-89.8V386.2V323z"
                        />
                    </g>
                </g>
            </g>
        </svg>
        <span class="text-text-primary typo-t4">{{
            'SHARED.SPLASH_SCREEN' | translate
        }}</span>
    </div>
    <article
        aria-labelledby="error-title"
        class="flex w-full grow flex-col items-center justify-center transition-all duration-700 lg:flex-row lg:space-x-32 2xl:space-x-64"
    >
        <div
            class="bg-surface-primary-low-contrast size-416 lg:size-608 flex flex-col justify-center gap-y-24 rounded-full p-56 transition-all duration-700 lg:self-start"
        >
            <h1
                class="typo-t4 lg:typo-t2 transition-all duration-700"
                id="error-title"
            >
                {{ 'SHARED.ERROR_PAGE.503.TITLE' | translate | uppercase }}
            </h1>
            <p
                class="typo-sm lg:typo-medium transition-all duration-700"
                [innerHTML]="'SHARED.ERROR_PAGE.503.DESCRIPTION' | translate"
            ></p>
        </div>

        <img
            alt=""
            class="bg-surface-primary p-100 -mt-100 size-300 lg:size-450 flex items-center justify-center rounded-full lg:mt-0"
            role="presentation"
            src="./assets/shared/images/doodles/out-of-service.png"
        />
    </article>
</div>
