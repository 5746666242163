import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'interacta-bulk-workflow-transition-banner',
    templateUrl: './bulk-workflow-transition-banner.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule],
})
export class BulkWorkflowTransitionBannerComponent {}
