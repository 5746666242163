import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    ApplyPipe,
    AvatarComponent,
    ButtonComponent,
    ButtonMenuComponent,
    ButtonMenuVerticalComponent,
    FormControlValueV2Pipe,
    FormFieldV2Component,
    IconButtonComponent,
    ImageComponent,
    InputTextV2Component,
    MenuComponent,
    MenuDecoratorComponent,
    SeparatorComponent,
} from '@interacta-shared/ui';
import { BackComponent } from '@interacta-shared/ui-common';
import { IconComponent } from '@interacta-shared/ui-icons';
import { ChatModule } from '@modules/chat/chat.module';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { StoreModule } from '@ngrx/store';
import { AdvancedSearchPanelComponent } from './components/advanced-search-panel/advanced-search-panel.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeSelectorComponent } from './components/home-selector/home-selector.component';
import { SearchComponent } from './components/search/search.component';
import { UserMenuButtonComponent } from './components/user-menu-button/user-menu-button.component';
import { UserMenuHomeComponent } from './components/user-menu-home/user-menu-home.component';
import { UserMenuLanguageComponent } from './components/user-menu-language/user-menu-language.component';
import { UserMenuSeparatorComponent } from './components/user-menu-separator/user-menu-separator.component';
import { UserMenuThemeComponent } from './components/user-menu-theme/user-menu-theme.component';
import { UserMenuTitleComponent } from './components/user-menu-title/user-menu-title.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { headerFeatureKey, headerReducer } from './store/header.reducer';

const userMenuComponents = [
    UserMenuComponent,
    UserMenuButtonComponent,
    UserMenuThemeComponent,
    UserMenuHomeComponent,
    UserMenuTitleComponent,
    UserMenuSeparatorComponent,
    UserMenuLanguageComponent,
];

const standaloneComponents = [
    IconComponent,
    AvatarComponent,
    BackComponent,
    ButtonComponent,
    ButtonMenuComponent,
    ButtonMenuVerticalComponent,
    IconButtonComponent,
    ImageComponent,
    InputTextV2Component,
    MenuComponent,
    MenuDecoratorComponent,
    SeparatorComponent,
    FormFieldV2Component,
];

const standalonePipes = [ApplyPipe, FormControlValueV2Pipe];

@NgModule({
    declarations: [
        HeaderComponent,
        ...userMenuComponents,
        SearchComponent,
        HomeSelectorComponent,
        AdvancedSearchPanelComponent,
    ],
    imports: [
        CommonModule,
        SharedV2Module,
        ...standaloneComponents,
        ...standalonePipes,
        ChatModule,
        StoreModule.forFeature(headerFeatureKey, headerReducer),
    ],
    exports: [HeaderComponent],
})
export class HeaderModule {}
