import { Injectable } from '@angular/core';
import { AuthActions, AuthService } from '@interacta-shared/data-access-auth';
import { LocalStorageService } from '@interacta-shared/data-access-common';
import {
    changeTheme,
    initTheme,
    systemTheme,
} from '@interacta-shared/data-access-theme';
import { PostActionsApi } from '@modules/communities/store';
import { NotificationBellStreamService } from '@modules/core/services/notification-bell-stream.service';
import { DiscoveryService } from '@modules/discovery/services/discovery.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromRouter from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    concatMap,
    filter,
    map,
    mergeMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { UIActions } from '.';
import { AppState } from '..';
import { notificationPanel } from './ui.selector';

const ONLY_UNREAD_STORAGE_KEY = 'onlyUnreadNotification';

@Injectable()
export class UiEffects {
    closeFullscreen$ = createEffect(() =>
        this.actions.pipe(
            ofType(
                PostActionsApi.addCommentSuccess,
                PostActionsApi.saveEditCommentSuccess,
                fromRouter.routerNavigatedAction,
            ),
            map(() => UIActions.closeCommentFullScreen()),
        ),
    );

    openProTip$ = createEffect(() =>
        this.actions.pipe(
            ofType(initTheme),
            filter(
                ({ cachedTheme }) =>
                    !cachedTheme && systemTheme().mode === 'dark',
            ),
            mergeMap(() =>
                this.discoveryService.openTip(
                    { id: 'systemTheme' },
                    {
                        title: 'THEME.TIP_TITLE',
                        message: 'THEME.TIP_DESCRIPTION',
                        tipActions: [
                            {
                                id: 'switchToLightMode',
                                label: 'THEME.GO_TO_LIGHT',
                                data: {},
                            },
                        ],
                    },
                ),
            ),
            filter((actionTip) => actionTip !== 'close'),
            map(() =>
                changeTheme({
                    theme: {
                        mode: 'light',
                        isSystem: false,
                    },
                }),
            ),
        ),
    );

    initializeOnlyUnreadNotification$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.login),
            map(() => {
                const onlyUnread =
                    this.localStorageService.getEntry<boolean>(
                        ONLY_UNREAD_STORAGE_KEY,
                    ) ?? false;
                return UIActions.toggleOnlyUnreadNotification({
                    onlyUnread,
                });
            }),
        ),
    );

    removeAllRead$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(UIActions.toggleNotificationPanel),
                concatMap((action) =>
                    of(action).pipe(
                        withLatestFrom(this.store.select(notificationPanel)),
                    ),
                ),
                tap(([{ isOpen }, notificationPanel]) => {
                    if (!isOpen && notificationPanel?.onlyUnread) {
                        this.notificationStreamService.removeAllRead();
                    }
                }),
            ),
        { dispatch: false },
    );

    toggleOnlyUnreadNotification$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(UIActions.toggleOnlyUnreadNotification),
                concatMap((action) =>
                    of(action).pipe(
                        withLatestFrom(this.store.select(notificationPanel)),
                        withLatestFrom(
                            this.notificationStreamService.getBufferNotificationsStream(),
                        ),
                    ),
                ),
                tap(
                    ([
                        [{ onlyUnread }, notificationPanel],
                        notificationList,
                    ]) => {
                        if (notificationPanel?.isOpen) {
                            this.localStorageService.setEntry(
                                ONLY_UNREAD_STORAGE_KEY,
                                onlyUnread,
                            );
                        }

                        if (
                            notificationList &&
                            notificationList.nextPageToken.tag !==
                                'firstLoading' &&
                            notificationList.onlyUnread !== onlyUnread
                        ) {
                            this.notificationStreamService.toggleOnlyUnreadNotificationAndFetchStream(
                                onlyUnread,
                            );
                        }
                    },
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private actions: Actions,
        private store: Store<AppState>,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private notificationStreamService: NotificationBellStreamService,
        private discoveryService: DiscoveryService,
    ) {}
}
