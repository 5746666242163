import { Injectable } from '@angular/core';
import { ExternalLinksService } from '@modules/core/services/external-links.service';
import { ExternalLinksState } from '../models/external-links-state.model';
import { IStateService } from './istate-service';
import { StateService } from './state.service';

@Injectable({
    providedIn: 'root',
})
export class ExternalLinksStateService
    implements IStateService<ExternalLinksState>
{
    public readonly state: ExternalLinksState;

    constructor(
        stateService: StateService,
        private externalLinksService: ExternalLinksService,
    ) {
        this.state = stateService.externalLinksState;
    }

    initialize(): void {
        this.externalLinksService
            .getExternalLinks()
            .subscribe((val) => this.state.externalLinks$.next(val));
    }

    flush(): void {
        this.state.externalLinks$.next(null);
    }
}
