import {
    ErrorHandler,
    Inject,
    Injectable,
    Injector,
    Type,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { groupBy, map, mergeMap, pairwise, throttleTime } from 'rxjs/operators';
import { ErrorService } from './error.service';

@Injectable()
export class InteractaErrorHandler implements ErrorHandler {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private errorStream = new BehaviorSubject<any>({});
    private _errorsService: ErrorService | undefined;

    constructor(
        // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
        @Inject(Injector) private injector: Injector,
    ) {
        this.errorStream
            .pipe(
                map((error) => ({ error, id: <string>error.toString() })),
                groupBy((item) => item.id),
                mergeMap((groupById) =>
                    groupById.pipe(
                        throttleTime(500), // Skip duplicated consecutive errors within 500ms
                    ),
                ),
                pairwise(),
                map(([prev, curr]) => ({
                    error: curr.error,
                    multiple: prev.id === curr.id, // Avoid server-side logging for duplicated consecutive errors
                })),
            )
            .subscribe((item) =>
                this.errorService.handle(item.error, true, item.multiple),
            );
    }

    handleError(error: unknown) {
        // The default implementation of ErrorHandler prints error messages to the console. This must be done anyway.
        console.error(error);
        this.errorStream.next(error);
    }

    private get errorService(): ErrorService {
        if (!this._errorsService) {
            this._errorsService = this.injector.get<ErrorService>(
                ErrorService as Type<ErrorService>,
            );
        }
        return this._errorsService;
    }
}
