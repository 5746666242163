import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppBaseRoute } from '@modules/app-routing/routes';
import { AppSelectors, AppState } from '@modules/core/store';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'interacta-skip-links',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './skip-links.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkipLinksComponent {
    goToMainContentExcluded: AppBaseRoute[] = ['login', 'error'];
    goToSidebarExcluded: AppBaseRoute[] = [
        'post-edit',
        'post-detail',
        'error',
        'login',
        'profile',
    ];
    routeState = this.store.selectSignal(AppSelectors.selectRouteState);

    constructor(
        private router: Router,
        private store: Store<AppState>,
    ) {}

    setFocus(goTo: 'main-content' | 'sidebar'): void {
        const goToContent = document.getElementById(goTo) as HTMLElement;
        if (goToContent) {
            this.router.navigate([], {
                fragment: goTo,
            });
            goToContent.focus();
        }
    }
}
