import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { IWorkflowStatus } from '@modules/communities/models/workflow/workflow.model';
import { Member } from '@modules/core/models/member/member.model';
import {
    getEmptyUserPlaceholderAsMember,
    isMemberUser,
} from '@modules/core/models/member/member.utils';
import {
    EventType,
    IEventActivity,
    INotificationNavigation,
} from '@modules/core/models/notification-user/notification-user.model';
import {
    ActivityPostEventTypes,
    navigationNotificationActivity,
    TaskEventTypes,
} from '@modules/core/models/notification-user/notification-user.utils';
import { FeedbackTaskStateStyle } from '@modules/feedback/models/feedback.model';
import { getFeedbackTaskStateStyle } from '@modules/feedback/models/feedback.utils';
import { NotificationsService } from '@modules/notifications/services/notifications.service';
import { onOpenNotificationDetails } from '@modules/notifications/store/notifications.actions';
import {
    ActivityPost,
    isActivityPost,
} from '@modules/post/models/activity-post/activity-post.model';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { isEventPost } from '@modules/post/models/event-post.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'interacta-notification-row',
    templateUrl: './notification-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationRowComponent implements OnInit {
    @Input({ required: true }) notification!: IEventActivity;

    @Output() clickOnNotification = new EventEmitter<INotificationNavigation>();
    @Output() markAsRead = new EventEmitter<void>();
    @Output() viewAttachments = new EventEmitter<void>();

    private readonly translateService = inject(TranslateService);
    private readonly notificationsService = inject(NotificationsService);
    private readonly store = inject(Store);

    readonly EventType = EventType;
    readonly TaskEventTypes = TaskEventTypes;
    readonly ActivityPostEventTypes = ActivityPostEventTypes;
    attachment?: IAttachment;
    otherAttachments = 0;

    eventStartAt: Date | undefined = undefined;
    eventAllDay = false;

    fromState?: IWorkflowStatus;
    toState?: IWorkflowStatus;
    feedbackTaskFromStateStyle?: FeedbackTaskStateStyle;
    feedbackTaskToStateStyle?: FeedbackTaskStateStyle;

    activityNotificationPostData: Pick<
        ActivityPost,
        'expectedStart' | 'expectedEnd' | 'assignee' | 'priority'
    > | null = null;

    notificationText = '';

    emptyMemberPlaceholder = getEmptyUserPlaceholderAsMember(
        this.translateService.instant('DASHBOARD.FILTERS.NOT_ASSIGNED'),
    );

    ngOnInit(): void {
        this.computeEventPostData();
        this.computeActivityPostData();
        this.computeChangeStatusData();
        this.computeEditedPostAttachmentsData();
        this.buildNotificationText();
    }

    clickOnAttachment(event: MouseEvent): void {
        event.stopPropagation();
        this.viewAttachments.emit();
        if (!this.notification.read) {
            this.markAsRead.emit();
        }
    }

    openNotificationDetails(): void {
        this.store.dispatch(
            onOpenNotificationDetails({ notification: this.notification }),
        );
        const navigateObject = navigationNotificationActivity(
            this.notification,
        );
        if (navigateObject) {
            this.clickOnNotification.emit(navigateObject);
        } else if (!this.notification.read) {
            this.markAsRead.emit();
        }
    }

    /**
     * set deleted property to true to see name in strike-through style
     */
    markAsDeleted(oldValueMember: Member): Member {
        if (isMemberUser(oldValueMember)) {
            return {
                ...oldValueMember,
                user: { ...oldValueMember.user, deleted: true },
            };
        } else {
            return {
                ...oldValueMember,
                group: {
                    ...oldValueMember.group,
                    deleted: true,
                },
            };
        }
    }

    private buildNotificationText(): void {
        const postTitle = this.notificationsService.buildPostTitle(
            this.notification,
        );

        const translateKey = this.notificationsService.buildTranslateKey(
            this.notification,
            postTitle,
        );

        if (translateKey) {
            const params =
                this.notificationsService.buildTranslateInterpolateParams(
                    this.notification,
                    postTitle,
                );
            this.notificationText = this.translateService.instant(
                translateKey,
                params,
            );
        } else {
            this.notificationText = '';
        }
    }

    private computeEventPostData(): void {
        if (this.notification.post && isEventPost(this.notification.post)) {
            this.eventStartAt =
                this.notification.post.startAt.zonedDatetime ??
                this.notification.post.startAt.localDatetime;
            this.eventAllDay = this.notification.post.allDay;
        }
    }

    private computeActivityPostData(): void {
        if (this.notification.post && isActivityPost(this.notification.post)) {
            this.activityNotificationPostData = {
                priority: this.notification.post.priority,
                expectedEnd: this.notification.post.expectedEnd,
                expectedStart: this.notification.post.expectedStart,
                assignee: this.notification.post.assignee,
            };
        }
    }

    private computeChangeStatusData(): void {
        if (this.notification.typeId === EventType.CHANGE_STATUS) {
            this.fromState = this.notification.aggregated
                ? this.notification.firstPostWorkflowOperationDetail?.fromState
                : this.notification.lastPostWorkflowOperationDetail?.fromState;

            this.toState =
                this.notification.lastPostWorkflowOperationDetail?.toState;
        }

        if (
            this.notification.typeId === EventType.FEEDBACK_TASK_CHANGE_STATUS
        ) {
            this.feedbackTaskFromStateStyle = this.notification
                .lastTaskTransitionOperationDetail?.fromState
                ? getFeedbackTaskStateStyle(
                      this.notification.lastTaskTransitionOperationDetail
                          .fromState,
                  )
                : undefined;
            this.feedbackTaskToStateStyle = this.notification
                .lastTaskTransitionOperationDetail?.toState
                ? getFeedbackTaskStateStyle(
                      this.notification.lastTaskTransitionOperationDetail
                          .toState,
                  )
                : undefined;
        }
    }

    private computeEditedPostAttachmentsData(): void {
        if (this.notification.typeId === EventType.EDITED_POST_ATTACHMENTS) {
            this.otherAttachments =
                this.notification.addedAttachments.length +
                this.notification.updatedAttachments.length +
                this.notification.versionedAttachments.length -
                1;
            if (this.notification.addedAttachments.length > 0) {
                this.attachment = this.notification.addedAttachments[0];
            } else if (this.notification.updatedAttachments.length > 0) {
                this.attachment = this.notification.updatedAttachments[0];
            } else if (this.notification.versionedAttachments.length > 0) {
                this.attachment = this.notification.versionedAttachments[0];
            }
        }
    }
}
