import { inject, Injectable } from '@angular/core';
import { AuthActions } from '@interacta-shared/data-access-auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { PrivateEmailService } from '../services/private-email.service';

@Injectable()
export class PrivateEmailEffects {
    private actions = inject(Actions);
    private privateEmailService = inject(PrivateEmailService);

    verifyCurrentUserPrivateEmailAfterLogin$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.login),
                tap(() =>
                    this.privateEmailService.askVerifyCurrentUserPrivateEmail(),
                ),
            ),
        { dispatch: false },
    );
}
