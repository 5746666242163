import { Injectable } from '@angular/core';
import { emptyPaginatedList } from '@interacta-shared/util';
import { NotificationBellState } from '../models/notification-bell-state.model';
import { IStateService } from './istate-service';
import { StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class NotificationBellStateService
    implements IStateService<NotificationBellState>
{
    readonly state: NotificationBellState;

    constructor(stateService: StateService) {
        this.state = stateService.notificationBellState;
    }

    initializeCurrentActivityNotificationList(): void {
        this.state.currentActivityNotificationList.updateData({
            ...emptyPaginatedList(),
            nextSyncToken: undefined,
            onlyUnread: false,
        });
    }

    initialize(): void {
        this.initializeCurrentActivityNotificationList();
    }

    flush(): void {
        this.initializeCurrentActivityNotificationList();
    }
}
