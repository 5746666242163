import { Injectable, inject } from '@angular/core';
import { AuthActions, AuthService } from '@interacta-shared/data-access-auth';
import { LocalStorageService } from '@interacta-shared/data-access-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, defer, fromEventPattern, iif } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {
    DARK_THEME_CLASSES,
    THEME_STORAGE_KEY,
    Theme,
    getUserThemeModePreference,
    systemTheme,
} from '../models/theme.model';
import * as ThemeActions from './theme.actions';
import { selectCurrentTheme } from './theme.selector';

@Injectable()
export class ThemeEffects {
    private readonly actions = inject(Actions);
    private readonly authService = inject(AuthService);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly store = inject(Store);

    systemTheme$: Observable<Theme> = iif(
        () =>
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme)').media !== 'not all',
        defer(() => {
            const mediaQuery = window.matchMedia(
                '(prefers-color-scheme: dark)',
            );

            return fromEventPattern<MediaQueryListEvent>(
                (handler) =>
                    mediaQuery.addEventListener
                        ? mediaQuery.addEventListener('change', handler)
                        : mediaQuery.addListener(handler),
                (handler) =>
                    mediaQuery.removeEventListener
                        ? mediaQuery.removeEventListener('change', handler)
                        : mediaQuery.removeListener(handler),
            ).pipe(map(() => systemTheme()));
        }),
        EMPTY,
    );

    changeAppThemeWhenSystemThemeChanges$ = createEffect(() =>
        this.systemTheme$.pipe(
            withLatestFrom(
                this.authService.currentUserData$,
                this.store.select(selectCurrentTheme),
            ),
            // We change the theme only if the user is using the system one
            filter(
                ([_, currentUser, userTheme]) =>
                    currentUser != null && userTheme.isSystem,
            ),
            map(([theme]) => ThemeActions.changeTheme({ theme })),
        ),
    );

    restoreCachedThemeAfterLogin$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.login),
            map(() => {
                const cachedTheme =
                    this.localStorageService.getEntry<Theme>(THEME_STORAGE_KEY);
                return ThemeActions.initTheme({ cachedTheme });
            }),
        ),
    );

    initTheme$ = createEffect(() =>
        this.actions.pipe(
            ofType(ThemeActions.initTheme),
            map(({ cachedTheme }) =>
                ThemeActions.changeTheme({
                    theme: this.actualizeTheme(cachedTheme),
                }),
            ),
        ),
    );

    applyTheme$ = createEffect(() =>
        this.actions.pipe(
            ofType(ThemeActions.changeTheme),
            tap(({ theme }) => {
                if (theme.mode === 'dark') {
                    document.body.classList.add(...DARK_THEME_CLASSES);
                } else {
                    document.body.classList.remove(...DARK_THEME_CLASSES);
                }

                this.localStorageService.setEntry(
                    THEME_STORAGE_KEY,
                    theme.mode,
                    false,
                );
                this.localStorageService.setEntry(
                    THEME_STORAGE_KEY,
                    theme,
                    true,
                );
            }),
            map(({ theme }) => ThemeActions.changeThemeSuccess({ theme })),
        ),
    );

    private actualizeTheme(cachedTheme: Theme | undefined): Theme {
        if (
            cachedTheme &&
            cachedTheme.isSystem &&
            cachedTheme.mode !== getUserThemeModePreference()
        ) {
            return systemTheme();
        } else {
            return cachedTheme ?? systemTheme();
        }
    }
}
