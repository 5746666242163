import { HttpClient } from '@angular/common/http';
import { Environment } from '@interacta-shared/data-access-configuration';
import { MultiTranslateHttpLoader } from '@interacta-shared/util';

export function translateHttpLoaderFactory(
    http: HttpClient,
    env: Environment,
): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/ui/i18n/', suffix: '.json' },
        { prefix: './assets/shared/i18n/', suffix: '.json' },
        { prefix: './assets/admin-v2/i18n/', suffix: '.json' },
        { prefix: './assets/i18n/', suffix: '.json' },
        {
            prefix: `${env.apiBasePath.common}/core/settings/labels/interacta_webapp/`,
            suffix: '',
            dynamic: true,
        },
    ]);
}
