import { Component, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    CustomerLogo,
    Language,
} from '@interacta-shared/data-access-configuration';
import {
    changeTheme,
    selectCurrentTheme,
    systemTheme,
    ThemeOption,
} from '@interacta-shared/data-access-theme';
import { openProTip, TipService } from '@interacta-shared/feature-tip';
import { ConfirmDialogService } from '@interacta-shared/ui-common';
import { isDefined } from '@interacta-shared/util';
import { HistoryRoutingService } from '@interacta-shared/util-common';
import {
    AppBaseRoute,
    getIconMode,
    IconMode,
    login,
    notificationSettings,
    unsubscribe,
} from '@modules/app-routing/routes';
import { isChristmasTime } from '@modules/core/helpers/generic.utils';
import { GoogleAnalyticsService } from '@modules/core/services/google-analytics.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { EProfileOrigin } from '@modules/profile/models/profile-origin.enum';
import { isSidebarShown } from '@modules/sidebar/models/sidebar.utils';
import { CatalogsStateService } from '@modules/state/services/catalogs-state.service';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { StateService } from '@modules/state/services/state.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

const noHeaderBaseRoutes: AppBaseRoute[] = [login, 'error', unsubscribe];

const noCarousel: AppBaseRoute[] = [login, 'error'];

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    private readonly authService = inject(AuthService);
    private readonly appState = inject(StateService);
    private readonly historyRoutingService = inject(HistoryRoutingService);
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly translate = inject(TranslateService);
    private readonly googleAnalyticsService = inject(GoogleAnalyticsService);
    private readonly configurationService = inject(ConfigurationService);
    private readonly store = inject(Store<AppState>);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly communitiesStateService = inject(CommunitiesStateService);
    private readonly catalogsStateService = inject(CatalogsStateService);
    private readonly tipService = inject(TipService);

    currentUser = this.authService.currentUserData;
    theme = this.store.selectSignal(selectCurrentTheme);
    logo!: CustomerLogo;
    iconMode: IconMode = 'menu';
    showHeader$!: Observable<boolean>;
    showSidebar$!: Observable<boolean>;
    canShowCarousel$!: Observable<boolean>;

    leftMenuUI = toSignal(this.appState.uiState.leftMenuUI.onDataChange());
    enableChristmasEasterEgg = isChristmasTime();

    ngOnInit(): void {
        this.logo = this.configurationService.getCustomerLogoUrl();
        this.historyRoutingService.loadRouting();

        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationEnd),
                mergeMap((_) => this.getRouteData(this.activatedRoute)),
                filter((routeData) => routeData.id != null),
            )
            .subscribe(({ id }) => {
                this.googleAnalyticsService.sendScreenView(id);
            });

        this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                filter(isDefined),
                map((route) => getIconMode(route.appRoute)),
            )
            .subscribe((icon) => (this.iconMode = icon));

        this.showHeader$ = this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                map(
                    (route) =>
                        isDefined(route) &&
                        (!route.appBaseRoute ||
                            !noHeaderBaseRoutes.includes(route.appBaseRoute)),
                ),
            );

        this.showSidebar$ = this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                map(
                    (route) =>
                        isDefined(route) &&
                        !!route.appRoute &&
                        isSidebarShown(route.appRoute),
                ),
            );

        this.canShowCarousel$ = this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                map(
                    (route) =>
                        isDefined(route) &&
                        (!route.appBaseRoute ||
                            !noCarousel.includes(route.appBaseRoute)),
                ),
            );

        this.configurationService
            .checkUpdateVersionRequired()
            .pipe(filter((isNewVersion) => isNewVersion))
            .subscribe(() =>
                this.tipService.info('TIPS.NEW_APP_VERSION', 'forceReload'),
            );
    }

    changeTheme(mode: ThemeOption): void {
        this.store.dispatch(
            changeTheme({
                theme:
                    mode === 'system'
                        ? systemTheme()
                        : { isSystem: false, mode },
            }),
        );
    }

    changeLanguage(lang: Language): void {
        const currentLang = this.configurationService.getCurrentLanguage();
        if (lang?.code !== currentLang.code) {
            this.translate
                .use(lang.code)
                .pipe(
                    switchMap((_) =>
                        this.confirmDialogService.open({
                            title: 'DIALOG_BASE.LABEL_TITLE_LANGUAGE_RELOAD',
                            description:
                                'DIALOG_BASE.LABEL_MESSAGE_LANGUAGE_RELOAD',
                        }),
                    ),
                    switchMap((confirm) =>
                        confirm
                            ? of(confirm)
                            : this.translate
                                  .use(currentLang.code)
                                  .pipe(switchMap(() => EMPTY)),
                    ),
                    switchMap((_) => [
                        this.communitiesStateService.clearCommunitiesCache(),
                        this.catalogsStateService.clearCatalogsCache(),
                    ]),
                )
                .subscribe(() => window.location.reload());
        }
    }

    logout(): void {
        this.authService.signOut().subscribe(() => {
            this.store.dispatch(
                openProTip({
                    title: 'SHARED.LOGOUT_TIP.TITLE',
                    message: 'SHARED.LOGOUT_TIP.MESSAGE',
                }),
            );
        });
    }

    openProfile(): void {
        const userId = this.currentUser()?.id;
        if (userId) {
            this.router.navigate([`/profile/${userId}`], {
                queryParams: { origin: EProfileOrigin.PEOPLE },
            });
        }
    }

    openNotificationsSettings(): void {
        this.router.navigate([notificationSettings]);
    }

    private getRouteData(route: ActivatedRoute): Observable<Data> {
        let child = route;
        while (child.firstChild) {
            child = child.firstChild;
        }
        return child.data;
    }
}
