import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { toHashtagInfo } from '../models/hashtag-info.deserialize';
import { HashtagInfo } from '../models/hashtag-info.model';

@Injectable({
    providedIn: 'root',
})
export class HashtagInfoService {
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts/data/hashtags`;

    constructor(private http: HttpClient) {}

    getHashtagInfo(id: number): Observable<HashtagInfo> {
        return this.http
            .get<any>(`${this.baseUrl}/${id}/instances-count`)
            .pipe(map(toHashtagInfo));
    }
}
