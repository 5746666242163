import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    ConfigurationService,
    EnvironmentInfo,
} from '@interacta-shared/data-access-configuration';
import { Theme } from '@interacta-shared/data-access-theme';
import { isDefined, uuid } from '@interacta-shared/util';
import {
    isCurrentChatStatusUpdatedEvent,
    isOpenChatListCommand,
    isOpenLinkCommand,
    isRequestChatVisibilityCommand,
    isUpdateUnreadChatStatusEvent,
    isVersionUpdateFoundEvent,
} from '@modules/chat/helpers/chat.utils';
import { toIMessage as toMessage } from '@modules/chat/models/chat.deserialize';
import {
    ChatVisibilityUpdatedEvent,
    CommandMessage,
    CommandType,
    CurrentChatStatusUpdatedEvent,
    EventMessage,
    EventType,
    OpenLinkCommand,
    OpenListChatCommand,
    RequestToOpenCurrentRoute,
    ThemeModeUpdatedEvent,
    UnreadChatStatusUpdatedEvent,
} from '@modules/chat/models/chat.models';
import { Subject, filter } from 'rxjs';
import { ChatState } from '../models/chat-state.model';
import { IStateService } from './istate-service';
import { StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class ChatStateService implements IStateService<ChatState> {
    readonly state: ChatState;

    baseUrl = '';

    get basePortalUrl(): string {
        return this.baseUrl !== '' ? `${this.baseUrl}/portal/` : '';
    }
    get baseChatUrl(): string {
        return this.baseUrl !== '' ? `${this.baseUrl}/chat/` : '';
    }

    flush$ = new Subject<void>();
    private chatWindow: Window | null = null;
    private env?: EnvironmentInfo;
    private markedForUpdate = false;

    constructor(
        private stateService: StateService,
        private configurationService: ConfigurationService,
        private router: Router,
    ) {
        this.state = this.stateService.chatState;

        this.configurationService
            .getEnvironmentInfoStream()
            .pipe(filter(isDefined))
            .subscribe((env) => {
                this.baseUrl =
                    window.location.hostname === 'localhost'
                        ? `${env?.plantBaseUrl}`
                        : window.location.origin;
                this.env = env;
            });
    }

    isOpen(): boolean {
        return this.state.isOpen$.value;
    }

    isCollapsed(): boolean {
        return this.state.isCollapsed$.value;
    }

    setChatUrl(theme: Theme): void {
        const developmentParam =
            window.location.hostname === 'localhost' ? '&development=true' : '';

        this.state.chatUrl$.next(
            `${this.baseChatUrl}?embedder=interacta&theme-mode=${theme.isSystem ? 'system' : theme.mode}${developmentParam}`,
        );
    }

    initialize(): void {
        this.state.isOpen$.next(false);
        this.state.isCollapsed$.next(true);
    }

    flush(): void {
        this.initialize();
        this.flush$.next();
    }

    open(): void {
        this.state.isOpen$.next(true);
        this.state.isCollapsed$.next(false);
    }

    close(): void {
        this.state.isOpen$.next(false);
        this.state.isCollapsed$.next(true);

        if (this.markedForUpdate) {
            this.reloadChat();
            this.markedForUpdate = false;
        }
    }

    toggleCollapse(): void {
        this.state.isCollapsed$.next(!this.state.isCollapsed$.value);
    }

    toggleEnable(enable: boolean): void {
        if (!enable) this.close();
        this.state.canShow$.next(enable);
    }

    openInNewTab(): void {
        const message: RequestToOpenCurrentRoute = {
            id: uuid(),
            body: undefined,
            command: 'request-to-open-current-route',
            target: 'chat',
            type: 'command',
        };

        this.chatWindow?.postMessage(message, this.baseUrl);
    }

    updateChatVisibility(visibility: 'foreground' | 'background'): void {
        const message: ChatVisibilityUpdatedEvent = {
            id: uuid(),
            event: 'chat-visibility-updated',
            target: 'interacta',
            type: 'event',
            body: {
                isVisible: visibility === 'foreground',
            },
        };

        this.chatWindow?.postMessage(message, this.baseUrl);
    }

    openChatList(): void {
        const message: OpenListChatCommand = {
            id: uuid(),
            body: undefined,
            command: 'open-chat-list',
            target: 'chat',
            type: 'command',
        };

        this.chatWindow?.postMessage(message, this.baseUrl);
    }

    changeTheme(theme: Theme): void {
        const message: ThemeModeUpdatedEvent = {
            id: uuid(),
            body: { themeMode: theme.isSystem ? 'system' : theme.mode },
            event: 'theme-mode-updated',
            target: 'interacta',
            type: 'event',
        };

        this.chatWindow?.postMessage(message, this.baseUrl);
    }

    registerChat(contentWindow: Window): void {
        this.chatWindow = contentWindow;

        window.addEventListener('message', (message: MessageEvent) => {
            if (!message?.data?.type || !message?.data?.target) return;
            if (this.env?.env !== 'dev' && message.origin !== this.baseUrl)
                return;

            const data = toMessage(message);

            if (data) {
                if (data.type === 'event' && data.target === 'chat')
                    this.callbackEventMessage(data);
                if (data.type === 'command' && data.target === 'interacta')
                    this.callbackCommandMessage(data);
            }
        });
    }

    reloadChat(): void {
        if (!this.chatWindow) return;

        this.chatWindow.location.reload();
    }

    private callbackEventMessage(
        message: EventMessage<EventType, unknown>,
    ): void {
        if (!message.event) return;

        if (isUpdateUnreadChatStatusEvent(message)) {
            this.updateUnreadChatStatus(message);
        }
        if (isCurrentChatStatusUpdatedEvent(message)) {
            this.updateCurrentChatStatus(message);
        }
        if (isVersionUpdateFoundEvent(message)) {
            this.markedForUpdate = true;
        }
    }

    private callbackCommandMessage(
        message: CommandMessage<CommandType, unknown>,
    ): void {
        if (!message.command) return;

        if (isOpenChatListCommand(message)) {
            this.openChatList();
        }
        if (isOpenLinkCommand(message)) {
            this.openLink(message);
        }
        if (isRequestChatVisibilityCommand(message)) {
            const visibility =
                this.isOpen() && !this.isCollapsed()
                    ? 'foreground'
                    : 'background';

            this.updateChatVisibility(visibility);
        }
    }

    private updateUnreadChatStatus(event: UnreadChatStatusUpdatedEvent): void {
        this.state.unreadChatStatus$.next(
            event.body.hasUnreadChats
                ? event.body.unreadChatsEstimatedCount
                : 0,
        );
    }

    /**
     * Updates the current chat status in the state.
     * Events are asynchronous, so in case of closing event referred to a different chat ID then the current one,
     * the function exits early. Otherwise, it updates the state with the event's body.
     * @param event - The event with updated chat status.
     */
    private updateCurrentChatStatus(
        event: CurrentChatStatusUpdatedEvent,
    ): void {
        const currentChatId =
            this.state.currentChatStatus$.getValue()?.chatId ?? null;
        if (
            currentChatId &&
            event.body.isClosed &&
            event.body.chatId !== currentChatId
        ) {
            return;
        }

        this.state.currentChatStatus$.next(event.body);
    }

    private openLink(event: OpenLinkCommand) {
        const url = event.body.link;
        if (!url) return;

        if (!url.startsWith(this.basePortalUrl)) window.open(url, '_blank');
        else {
            const replacedUrl = url.replace(this.basePortalUrl, '/');

            this.router.navigate([replacedUrl]);
        }
    }
}
