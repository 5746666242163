import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLicense } from '@syncfusion/ej2-base';
import { AppModule } from './app/app.module';

registerLicense(
    'Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ccnVRRGRYUkxxWEc=',
);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
