import { Injectable } from '@angular/core';
import { adminFlush } from '@modules/core/store/app.actions';
import { userMentionClick } from '@modules/shared-v2/store/mentions.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { CommunitiesStateService } from '../services/communities-state.service';
import { ProfilePreviewDialogStateService } from '../services/profile-preview-dialog-state.service';

@Injectable()
export class StateEffects {
    constructor(
        private actions$: Actions,
        private profilePreviewDialogService: ProfilePreviewDialogStateService,
        private communitiesStateService: CommunitiesStateService,
    ) {}

    openProfilePreviewDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(userMentionClick),
                tap(({ id }) =>
                    this.profilePreviewDialogService.open(id).subscribe(),
                ),
            ),
        { dispatch: false },
    );

    adminFlush$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(adminFlush),
                tap(() => {
                    this.communitiesStateService.initializeCommunityTrees();
                    this.communitiesStateService.clearAdminCommunityTree();
                }),
            ),
        { dispatch: false },
    );
}
