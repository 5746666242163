<ng-container
    *ngIf="{
        isOpen: appState.chatState.isOpen$ | async,
        isCollapsed: appState.chatState.isCollapsed$ | async,
        unreadChatStatus: appState.chatState.unreadChatStatus$ | async,
        chatUrl: appState.chatState.chatUrl$ | async,
        currentChatStatus: appState.chatState.currentChatStatus$ | async,
        theme: theme$ | async,
    } as $"
>
    <section
        *ngIf="$.theme"
        class="w-400 bg-surface-A text-text border-border-300 relative bottom-[66vh] right-0 flex h-[66vh] flex-col rounded-t-2xl border-x border-t shadow transition-all"
        [attr.aria-hidden]="!$.isOpen"
        [attr.inert]="!$.isOpen ? '' : null"
        [attr.role]="$.isOpen ? 'dialog' : ''"
        [style.transform]="
            'translateY(' +
            (!$.isOpen ? '100%' : $.isCollapsed ? 'calc(100% - 48px)' : '0') +
            ')'
        "
        [style.transition-duration.ms]="ANIMATION_DURATION"
    >
        <header class="flex h-48 items-center justify-between gap-x-4 p-8">
            <interacta-chat-panel-header
                [currentChatStatus]="$.currentChatStatus"
                [isCollapsed]="$.isCollapsed ?? false"
                [theme]="$.theme.mode"
                [unreadChatStatus]="$.unreadChatStatus ?? 0"
            />

            <div class="flex items-center gap-x-8">
                <button
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        ($.isCollapsed
                            ? 'CHAT_EMBEDDED.CHAT_PANEL.MAXIMIZE_CHAT'
                            : 'CHAT_EMBEDDED.CHAT_PANEL.MINIMIZE_CHAT'
                        ) | translate
                    "
                    [icon]="$.isCollapsed ? 'chevron-big-up' : 'minus'"
                    [matTooltip]="
                        ($.isCollapsed
                            ? 'CHAT_EMBEDDED.CHAT_PANEL.MAXIMIZE_CHAT'
                            : 'CHAT_EMBEDDED.CHAT_PANEL.MINIMIZE_CHAT'
                        ) | translate
                    "
                    [matTooltipPosition]="'above'"
                    [shape]="'round'"
                    [size]="'extra-small'"
                    (click)="toggleCollapsed()"
                ></button>
                <button
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'CHAT_EMBEDDED.CHAT_PANEL.OPEN_CHAT_NEW_TAB' | translate
                    "
                    [icon]="'fullscreen'"
                    [matTooltip]="
                        'CHAT_EMBEDDED.CHAT_PANEL.OPEN_CHAT_NEW_TAB' | translate
                    "
                    [matTooltipPosition]="'above'"
                    [shape]="'round'"
                    [size]="'extra-small'"
                    (click)="openChatInNewTab()"
                ></button>
                <button
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'CHAT_EMBEDDED.CHAT_PANEL.CLOSE_CHAT' | translate
                    "
                    [icon]="'close'"
                    [matTooltip]="
                        'CHAT_EMBEDDED.CHAT_PANEL.CLOSE_CHAT' | translate
                    "
                    [matTooltipPosition]="'above'"
                    [shape]="'round'"
                    [size]="'extra-small'"
                    (click)="close()"
                ></button>
            </div>
        </header>
        <iframe
            *ngIf="$.chatUrl"
            #chat
            class="grow"
            [src]="$.chatUrl | bypassSecurityTrust: 'resource-url'"
        ></iframe>
    </section>
</ng-container>
