<div class="gap-form-field flex flex-col">
    <interacta-form-field-v2
        [control]="formGroup.controls.some"
        [name]="'SEARCH.SOME_WORD' | translate"
    >
        <interacta-input-text-v2
            [control]="formGroup.controls.some"
            [focusOnInit]="true"
            (keyup.enter)="doSearch()"
        />
    </interacta-form-field-v2>
    <interacta-form-field-v2
        [control]="formGroup.controls.exactly"
        [name]="'SEARCH.STRING_EXACTLY' | translate"
    >
        <interacta-input-text-v2
            [control]="formGroup.controls.exactly"
            (keyup.enter)="doSearch()"
        />
    </interacta-form-field-v2>
    <interacta-form-field-v2
        [control]="formGroup.controls.every"
        [name]="'SEARCH.EVERY_WORD' | translate"
    >
        <interacta-input-text-v2
            [control]="formGroup.controls.every"
            (keyup.enter)="doSearch()"
        />
    </interacta-form-field-v2>
    <interacta-form-field-v2
        [control]="formGroup.controls.none"
        [name]="'SEARCH.NONE_WORD' | translate"
    >
        <interacta-input-text-v2
            [control]="formGroup.controls.none"
            (keyup.enter)="doSearch()"
        />
    </interacta-form-field-v2>
    <div class="flex justify-between">
        <button
            interacta-button
            type="button"
            [active]="true"
            [appearance]="'ghost'"
            [label]="'SEARCH.STANDARD' | translate"
            (click)="closePanel.emit()"
        ></button>
        <button
            interacta-button
            type="button"
            [label]="'SHARED.BUTTON.SEARCH' | translate"
            [size]="'regular'"
            (click)="doSearch()"
        ></button>
    </div>
</div>
