import { inject, Injectable } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';

@Injectable({
    providedIn: 'root',
})
export class RedirectMobileAppService {
    private readonly configService = inject(ConfigurationService);

    /**
     * Checks if the current device is a mobile browser
     */
    private isMobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent,
        );
    }

    private isIosDevice(): boolean {
        return /webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    private isAndroidDevice(): boolean {
        return /Android|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent,
        );
    }

    /**
     * Build URL based on the environment and redirect to the mobile app
     */
    redirectIfMobile(): void {
        if (this.isMobile()) {
            const mobileDownloadUrl =
                this.configService.getEnvironmentInfo()?.mobileAppDownloadUrl;
            let url: string | null;
            if (mobileDownloadUrl) {
                if (this.isIosDevice() && mobileDownloadUrl.ios) {
                    url = mobileDownloadUrl.ios;
                } else if (
                    this.isAndroidDevice() &&
                    mobileDownloadUrl.android
                ) {
                    url = mobileDownloadUrl.android;
                } else if (mobileDownloadUrl.generic) {
                    url = mobileDownloadUrl.generic;
                } else {
                    url = null;
                }
                if (url) window.location.href = url;
            }
        }
    }
}
