<article
    class="flex h-screen flex-col items-center bg-cover"
    style="
        background-image: url('./assets/shared/images/out-of-service-background.jpg');
    "
>
    <section
        class="from-transparent-black-not-black-95 flex w-full shrink-0 items-center justify-between bg-gradient-to-b px-24 pb-24 pt-16"
        [attr.aria-hidden]="true"
    >
        <svg
            xml:space="preserve"
            id="Livello_1"
            style="enable-background: new 0 0 1080 1080"
            version="1.1"
            viewBox="0 0 1080 1080"
            width="40"
            x="0px"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
        >
            <style type="text/css">
                .st0 {
                    fill: #ffffffff;
                }
            </style>
            <g>
                <g>
                    <g>
                        <path
                            class="st0"
                            d="M780,81H629.8c-49.6,0-89.8,40.2-89.8,89.8V323h150.2h0c49.6,0,89.8-40.2,89.8-89.8v-88.8V81z"
                        />
                        <path
                            class="st0"
                            d="M540,323H389.8c-49.6,0-89.8,40.2-89.8,89.8V998h150.2h0c49.6,0,89.8-40.2,89.8-89.8V386.2V323z"
                        />
                    </g>
                </g>
            </g>
        </svg>
        <p class="text-text-white typo-t5">
            {{ 'SHARED.SPLASH_SCREEN' | translate }}
        </p>
    </section>
    <section
        aria-labelledby="error-title"
        class="flex w-full grow flex-wrap items-center justify-around gap-x-8 transition-all duration-700 lg:items-start"
    >
        <div
            class="bg-surface-primary-low-contrast size-272 sm:size-400 md:size-500 lg:size-600 flex flex-col justify-center rounded-full p-40 transition-all duration-700 xl:mt-40"
        >
            <h1
                class="typo-t5 sm:typo-t4 md:typo-t2 mb-8 transition-all duration-700 sm:mb-16 md:mb-24"
                id="error-title"
            >
                {{ 'SHARED.ERROR_PAGE.503.TITLE' | translate | uppercase }}
            </h1>
            <p
                class="typo-sm md:typo-medium transition-all duration-700"
                [innerHTML]="'SHARED.ERROR_PAGE.503.DESCRIPTION' | translate"
            ></p>
        </div>
        <div
            class="xl:mt-318 bg-surface-primary size-450 hidden items-center justify-center rounded-full lg:flex"
        >
            <img
                alt=""
                class="size-120 sm:size-200 md:size-300"
                role="presentation"
                src="./assets/shared/images/doodles/out-of-service.png"
            />
        </div>
    </section>
</article>
