import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PeopleField } from '@modules/people-dialog/models/people-dialog.model';

@Component({
    selector: 'interacta-people-details[items]',
    templateUrl: './people-details.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleDetailsComponent {
    @Input() items!: PeopleField[];
}
