import { ErrorPage, ErrorPageInfo } from '@interacta-shared/data-access-error';

export type ErrorPageEntity =
    | 'POST'
    | 'USER'
    | 'GROUP'
    | 'AREA'
    | 'BUSINESS_UNIT'
    | 'WORKSPACE'
    | 'COMMUNITY'
    | 'CATALOG'
    | 'WORKFLOW';

export const errorPageInfoRegistryConfig: Partial<
    Record<ErrorPage, Partial<Record<ErrorPageEntity, ErrorPageInfo>>>
> = {
    pageNotFound: {
        POST: {
            title: 'ERROR_PAGE.404.TITLE_POST',
            description: 'ERROR_PAGE.404.DESCRIPTION_POST',
            logout: false,
        },
    },
    forbiddenAccess: {
        USER: {
            title: 'SHARED.ERROR_PAGE.403.TITLE',
            description: 'ERROR_PAGE.403.DESCRIPTION_USER_REGISTRY',
            logout: false,
        },
        GROUP: {
            title: 'SHARED.ERROR_PAGE.403.TITLE',
            description: 'ERROR_PAGE.403.DESCRIPTION_GROUP_REGISTRY',
            logout: false,
        },
        AREA: {
            title: 'SHARED.ERROR_PAGE.403.TITLE',
            description: 'ERROR_PAGE.403.DESCRIPTION_AREA',
            logout: false,
        },
        BUSINESS_UNIT: {
            title: 'SHARED.ERROR_PAGE.403.TITLE',
            description: 'ERROR_PAGE.403.DESCRIPTION_BUSINESS_UNIT',
            logout: false,
        },
        WORKSPACE: {
            title: 'SHARED.ERROR_PAGE.403.TITLE',
            description: 'ERROR_PAGE.403.DESCRIPTION_WORKSPACE',
            logout: false,
        },
        COMMUNITY: {
            title: 'SHARED.ERROR_PAGE.403.TITLE',
            description: 'ERROR_PAGE.403.DESCRIPTION_COMMUNITY',
            logout: false,
        },
        CATALOG: {
            title: 'SHARED.ERROR_PAGE.403.TITLE',
            description: 'ERROR_PAGE.403.DESCRIPTION_CATALOG',
            logout: false,
        },
        WORKFLOW: {
            title: 'SHARED.ERROR_PAGE.403.TITLE',
            description: 'ERROR_PAGE.403.DESCRIPTION_WORKFLOW',
            logout: false,
        },
    },
};
