<button
    class="flex w-full items-start"
    type="button"
    (click)="openNotificationDetails()"
>
    <ng-container *ngIf="notification.authorUsers">
        <interacta-avatar
            *ngIf="notification.authorUsers.length === 1"
            [user]="notification.authorUsers[0]"
        />

        <div
            *ngIf="notification.authorUsers.length > 1"
            class="relative h-full w-40 shrink-0"
        >
            <interacta-avatar
                [size]="'extra-small'"
                [user]="notification.authorUsers[0]"
            />
            <interacta-avatar
                class="absolute right-0 top-12"
                [size]="'extra-small'"
                [user]="notification.authorUsers[1]"
            />
        </div>
    </ng-container>

    <div class="ml-16 mr-4 flex h-full min-w-0 grow flex-col">
        <div
            class="typo-sm mb-4 line-clamp-3 text-ellipsis text-left"
            [innerHTML]="buildNotificationText()"
            [ngClass]="{
                'text-text': !notification.read,
                'text-text-mid-contrast': notification.read,
            }"
        ></div>
        <div class="flex items-center space-x-16">
            <div
                *ngIf="notification.timestamp"
                class="typo-xs-bold shrink-0"
                [matTooltip]="notification.timestamp | dateTooltip"
                [ngClass]="{
                    'text-text-primary': !notification.read,
                    'text-text-mid-contrast': notification.read,
                }"
            >
                {{ notification.timestamp | notificationDate }}
            </div>
            <span
                *ngIf="notification.community"
                class="typo-xs-bold-uppercase line-clamp-1 break-words"
                [ngClass]="{
                    'text-text-low-contrast': !notification.read,
                    'text-text-mid-contrast': notification.read,
                }"
            >
                {{ notification.community.name | currentLanguage }}</span
            >
        </div>
        <div
            *ngIf="
                TaskEventTypes.includes(notification.typeId) &&
                notification.task &&
                notification.task.expiration &&
                notification.task.expiration.zonedDatetime
            "
            class="typo-xs bg-surface-B mt-8 h-24 w-max rounded-3xl px-8 py-4"
            matTooltipPosition="above"
            [matTooltip]="
                notification.task.expiration.zonedDatetime | dateTooltip
            "
        >
            <span>
                {{
                    notification.task.expiration.zonedDatetime
                        | taskExpirationDate
                }}
            </span>
        </div>
        <div
            *ngIf="eventStartAt?.zonedDatetime || eventStartAt?.localDatetime"
            class="mt-8 flex space-x-4"
        >
            <interacta-icon class="size-20" [icon]="'clock'" />
            <div class="typo-sm flex items-center">
                <interacta-zoned-date
                    *ngIf="
                        eventStartAt?.zonedDatetime ??
                        eventStartAt?.localDatetime as date
                    "
                    [allDay]="eventAllDay"
                    [date]="date"
                />
            </div>
        </div>

        <div
            *ngIf="notification.typeId === EventType.CHANGE_STATUS"
            class="mt-8 flex items-center space-x-4"
        >
            <interacta-workflow-state-badge
                *ngIf="fromState"
                [color]="fromState.metadata.styleColor"
                [colorCode]="fromState.metadata.styleColorCode"
                [size]="'small'"
                [workflowStateName]="fromState.name"
            />
            <interacta-icon class="size-20" [icon]="'arrow-right'" />
            <interacta-icon
                *ngIf="notification.aggregated"
                class="size-20"
                [icon]="'ellipsis-horizontal'"
            />
            <interacta-icon
                *ngIf="notification.aggregated"
                class="size-20"
                [icon]="'arrow-right'"
            />
            <interacta-workflow-state-badge
                *ngIf="toState"
                [color]="toState.metadata.styleColor"
                [colorCode]="toState.metadata.styleColorCode"
                [size]="'small'"
                [workflowStateName]="toState.name"
            />
        </div>

        <div
            *ngIf="
                notification.typeId === EventType.FEEDBACK_TASK_CHANGE_STATUS
            "
            class="mt-8 flex items-center space-x-4"
        >
            <interacta-workflow-state-badge
                *ngIf="feedbackTaskFromStateStyle"
                [colorClass]="feedbackTaskFromStateStyle.background"
                [rounded]="'leaf'"
                [size]="'small'"
                [workflowStateName]="
                    feedbackTaskFromStateStyle.label | translate
                "
            />
            <interacta-icon class="size-20" [icon]="'arrow-right'" />
            <interacta-workflow-state-badge
                *ngIf="feedbackTaskToStateStyle"
                [colorClass]="feedbackTaskToStateStyle.background"
                [rounded]="'leaf'"
                [size]="'small'"
                [workflowStateName]="feedbackTaskToStateStyle.label | translate"
            />
        </div>

        <div
            *ngIf="notification.typeId === EventType.EDITED_POST_ATTACHMENTS"
            class="mt-8 flex space-x-4"
        >
            <interacta-attachment-chip
                *ngIf="attachment"
                class="min-w-0"
                [attachment]="attachment"
                [bgColor]="'surface-B'"
                [slim]="true"
                (click)="clickOnAttachment($event)"
            />
            <button
                *ngIf="otherAttachments > 0"
                class="typo-xs-bold bg-surface-B shrink-0 rounded-full px-8 py-4"
                type="button"
                (click)="clickOnAttachment($event)"
            >
                + {{ otherAttachments }}
            </button>
        </div>
    </div>

    <button
        *ngIf="!notification.read"
        class="bg-surface-primary size-12 shrink-0 rounded-full"
        type="button"
        [matTooltip]="'NOTIFICATIONS.MARK_AS_READ' | translate"
        (click)="$event.stopPropagation(); markAsRead.emit()"
    ></button>
</button>
