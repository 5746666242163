import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    output,
} from '@angular/core';
import {
    ApplyPipe,
    DeltaViewComponent,
    IconButtonComponent,
    SafeHtmlPipe,
} from '@interacta-shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { asDelta, asString } from '../../models/tip.utils';
import { SystemTipData, TipActionCallback } from './../../models/tip.model';

@Component({
    selector: 'interacta-system-tip',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        IconButtonComponent,
        DeltaViewComponent,
        ApplyPipe,
        SafeHtmlPipe,
    ],
    templateUrl: './system-tip.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemTipComponent<A, P> {
    tip = input.required<SystemTipData<A, P> | null>();
    closeTip = output<void>();
    actionClicked = output<TipActionCallback<A>>();

    imageSrc = computed(() => {
        const tip = this.tip();
        return tip && tip.image !== 'empty'
            ? `assets/shared/images/pro-tip/${tip.image}.png`
            : null;
    });
    readonly asString = asString;
    readonly asDelta = asDelta;
}
