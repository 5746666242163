import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { CurrentUser } from '@interacta-shared/data-access-auth';
import { Language } from '@interacta-shared/data-access-configuration';
import { Theme } from '@modules/core/models/theme.model';
import { DiscoveryService } from '@modules/discovery/services/discovery.service';
import { UserMenuPage } from '@modules/shared-v2/models/user-menu';

@Component({
    selector: 'interacta-user-menu-home',
    templateUrl: './user-menu-home.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuHomeComponent implements OnInit {
    @Input() availableLanguages: Language[] = [];
    @Input() currentLanguage!: Language;
    @Input() currentUser!: CurrentUser;
    @Input() currentTheme!: Theme;

    @Output() changePage = new EventEmitter<UserMenuPage>();
    @Output() logout = new EventEmitter<void>();
    @Output() openProfile = new EventEmitter<void>();
    @Output() openNotificationsSettings = new EventEmitter<void>();

    constructor(private discoveryService: DiscoveryService) {}

    ngOnInit(): void {
        this.discoveryService
            .openTip(
                { id: 'noLongerDarkBetaTheme' },
                {
                    title: 'THEME.DARK_TIP_TITLE',
                    message: 'THEME.DARK_TIP_DESCRIPTION',
                    image: 'reading',
                },
            )
            .subscribe();
    }
}
