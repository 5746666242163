import { inject, Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { filterMap } from '@interacta-shared/util';
import { SKIP_TITLE } from '@interacta-shared/util-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    BaseRouterStoreState,
    ROUTER_NAVIGATED,
    RouterNavigatedAction,
    SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { map } from 'rxjs';
import { PageTitleService } from '../services/page-title.service';

@Injectable()
export class PageTitleEffects<
    T extends BaseRouterStoreState & {
        data?: Data;
    } = SerializedRouterStateSnapshot,
> {
    private readonly actions$ = inject(Actions);
    private readonly pageTitleService = inject(PageTitleService);

    setPageTitle$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map(
                    (action: RouterNavigatedAction<T>) =>
                        action.payload.routerState.data?.['title'],
                ),
                filterMap((title: string | undefined) =>
                    title !== SKIP_TITLE ? (title ?? null) : undefined,
                ),
                this.pageTitleService.setPageTitle$(),
            ),
        { dispatch: false },
    );
}
