import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';

import { map, Observable } from 'rxjs';
import { toBulkWorkflowTransitionOperations } from '../models/bulk-workflow-transition.deserialize';
import { BulkWorkflowTransitionOperation } from '../models/bulk-workflow-transition.model';

@Injectable({
    providedIn: 'root',
})
export class BulkWorkflowTransitionService {
    private baseUrlData = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts`;
    private http = inject(HttpClient);

    public fetchBulkPostWorkflowOperations(
        operationId?: number,
        onlyUnread?: boolean,
    ): Observable<BulkWorkflowTransitionOperation[]> {
        return this.http
            .get(`${this.baseUrlData}/data/massive-post-workflow-operations`, {
                params: {
                    ...(operationId !== undefined && {
                        operationId: String(operationId),
                    }),
                    ...(onlyUnread !== undefined && {
                        onlyUnread: String(onlyUnread),
                    }),
                },
            })
            .pipe(map(toBulkWorkflowTransitionOperations));
    }

    public markBulkPostWorkflowOperationAsRead(
        operationId: number,
    ): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlData}/manage/massive-post-workflow-operations/${operationId}/read`,
            undefined,
        );
    }

    public abortBulkPostWorkflowOperation(
        operationId: number,
    ): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlData}/manage/massive-post-workflow-operations/${operationId}/abort`,
            undefined,
        );
    }
}
