import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentInfoService } from '@interacta-shared/data-access-configuration';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, of, timer } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    standalone: true,
    imports: [CommonModule, TranslateModule],
    selector: 'interacta-out-of-service-page',
    templateUrl: './out-of-service-page.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutOfServicePageComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    private readonly environmentInfoService = inject(EnvironmentInfoService);

    constructor(private route: ActivatedRoute) {}

    pollingServerBack(): void {
        //polling check environment
        timer(20000, 20000)
            .pipe(
                switchMap(() =>
                    this.environmentInfoService.getCurrentEnvironment().pipe(
                        map(() => true),
                        catchError(() => of(false)),
                        filter((health) => !!health),
                        takeUntil(this.destroy$),
                    ),
                ),
            )
            .subscribe((_) => {
                window.location.reload();
            });
    }

    ngOnInit(): void {
        this.route.queryParams
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.pollingServerBack());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
