<div class="typo-sm-bold">{{ 'PEOPLE.CONTACT' | translate }}</div>
<interacta-key-value-field
    *ngFor="let item of items; let last = last"
    [hideSeparator]="last"
    [label]="item.label | translate"
>
    <ng-container [ngSwitch]="item.type">
        <a
            *ngSwitchCase="'email'"
            class="text-text-primary"
            [href]="'mailto:' + item.value"
            >{{ item.value }}</a
        >

        <span *ngSwitchCase="'string'">{{ item.value }}</span>
    </ng-container>
</interacta-key-value-field>
