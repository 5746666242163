import { NgModule } from '@angular/core';
import {
    AvatarComponent,
    LoadMoreComponent,
    SeparatorComponent,
    ToggleComponent,
} from '@interacta-shared/ui';
import { NoResultComponent } from '@interacta-shared/ui-common';
import { IconComponent } from '@interacta-shared/ui-icons';
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { NotificationRowComponent } from './components/notification-row/notification-row.component';
import { NotificationsPanelComponent } from './components/notifications-panel/notifications-panel.component';
import { NotificationDatePipe } from './pipes/notification-date.pipe';

const standaloneComponents = [
    IconComponent,
    AvatarComponent,
    LoadMoreComponent,
    NoResultComponent,
    SeparatorComponent,
    ToggleComponent,
];

@NgModule({
    imports: [SharedV2Module, ...standaloneComponents, AttachmentsModule],
    exports: [NotificationsPanelComponent],
    declarations: [
        NotificationsPanelComponent,
        NotificationRowComponent,
        NotificationDatePipe,
    ],
})
export class NotificationsModule {}
