import { Injectable } from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import {
    EventType,
    IEventActivity,
} from '@modules/core/models/notification-user/notification-user.model';
import { TaskEventTypes } from '@modules/core/models/notification-user/notification-user.utils';
import { isActivityPost } from '@modules/post/models/activity-post/activity-post.model';
import { PostType } from '@modules/post/models/base-post.model';
import {
    isFeedBackPostType,
    isSurveyPost,
    isSurveyPostType,
} from '@modules/post/models/survey-post/survey-post.utils';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    buildPostTitle(notification: IEventActivity): string | null {
        const post = notification.post;
        if (!post || (!post.title && !post.code)) return null;

        return [post.code, post.title].filter(isDefined).join(' ');
    }

    buildTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';

        switch (notification.typeId) {
            case EventType.CREATION:
            case EventType.COPY:
                translateKey = this.buildCreationTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.EDIT:
                translateKey = this.buildEditTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.COMMENT:
                translateKey = this.buildCommentTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.MENTION_POST:
                translateKey = this.buildMentionTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.MENTION_COMMENT:
                translateKey = this.buildCommentMentionTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.CHANGE_STATUS:
                translateKey = this.buildStatusChangeTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.LIKE_POST:
                translateKey = this.buildPostLikeTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.NOT_LIKE_POST:
                translateKey = this.buildPostNotLikeTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.REMOVE_POST:
                translateKey =
                    notification.post?.tag === PostType.ACTIVITY
                        ? 'NOTIFICATION.BELL.LABEL_DELETE_POST_ACTIVITY'
                        : postTitle
                          ? 'NOTIFICATION.BELL.LABEL_DELETE_POST'
                          : 'NOTIFICATION.BELL.LABEL_DELETE_POST_NO_TITLE';
                break;
            case EventType.REMOVE_COMMENT:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_DELETE_POST_COMMENT'
                    : 'NOTIFICATION.BELL.LABEL_DELETE_POST_COMMENT_NO_TITLE';
                break;
            case EventType.EDIT_SCREEN_DATA:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_EDIT_SCREEN_DATA'
                    : 'NOTIFICATION.BELL.LABEL_EDIT_SCREEN_DATA_NO_TITLE';
                break;
            case EventType.CREATE_TASK:
            case EventType.COPY_TASK:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_CREATE_TASK'
                    : 'NOTIFICATION.BELL.LABEL_CREATE_TASK_NO_TITLE';
                break;
            case EventType.EDIT_TASK:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_EDIT_TASK'
                    : 'NOTIFICATION.BELL.LABEL_EDIT_TASK_NO_TITLE';
                break;
            case EventType.DELETE_TASK:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_DELETE_TASK'
                    : 'NOTIFICATION.BELL.LABEL_DELETE_TASK_NO_TITLE';
                break;
            case EventType.TASK_REMINDER:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_TASK_REMINDER'
                    : 'NOTIFICATION.BELL.LABEL_TASK_REMINDER_NO_TITLE';
                break;
            case EventType.ASSIGNED_TASK:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_ASSIGNED_TASK'
                    : 'NOTIFICATION.BELL.LABEL_ASSIGNED_TASK_NO_TITLE';
                break;
            case EventType.OPENED_TASK:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_OPEN_TASK'
                    : 'NOTIFICATION.BELL.LABEL_OPEN_TASK_NO_TITLE';
                break;
            case EventType.CLOSED_TASK:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_CLOSE_TASK'
                    : 'NOTIFICATION.BELL.LABEL_CLOSE_TASK_NO_TITLE';
                break;
            case EventType.UPDATE_VISIBILITY_POST:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_EXTEND_POST_VISIBILITY'
                    : 'NOTIFICATION.BELL.LABEL_EXTEND_POST_VISIBILITY_NO_TITLE';
                break;
            case EventType.FOLLOW_ADDED:
                translateKey = this.buildFollowPostTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.FOLLOW_REMOVED:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_NOT_FOLLOW_POST'
                    : 'NOTIFICATION.BELL.LABEL_NOT_FOLLOW_POST_NO_TITLE';
                break;
            case EventType.LIKE_COMMENT:
                translateKey = this.buildLikePostCommentTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.REMOVE_LIKE_COMMENT:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_REMOVE_LIKE_POST_COMMENT'
                    : 'NOTIFICATION.BELL.LABEL_REMOVE_LIKE_POST_COMMENT_NO_TITLE';
                break;
            case EventType.EDITED_POST_ATTACHMENTS:
                translateKey = this.buildEditAttachmentTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.ADD_HASHTAG:
                translateKey = this.buildAddHashtagTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.CREATE_ACKNOWLEDGE_TASK_REQUEST:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_ACKNOWLEDGE'
                    : 'NOTIFICATION.BELL.LABEL_ACKNOWLEDGE_NO_TITLE';
                break;
            case EventType.EVENT_INVITATION_GUEST:
                translateKey = 'NOTIFICATION.BELL.LABEL_EVENT_INVITATION_GUEST';
                break;
            case EventType.EVENT_INVITATION_STAFF:
                translateKey =
                    'NOTIFICATION.BELL.LABEL_EVENT_INVITATION_COLLABORATOR';
                break;
            case EventType.EDIT_EVENT:
                translateKey = 'NOTIFICATION.BELL.LABEL_EVENT_EDITED';
                break;
            case EventType.REOPENED_YOUR_TASK:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_REOPENED_YOUR_TASK'
                    : 'NOTIFICATION.BELL.LABEL_REOPENED_YOUR_TASK_NO_TITLE';
                break;
            case EventType.CLOSED_YOUR_TASK:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_CLOSED_YOUR_TASK'
                    : 'NOTIFICATION.BELL.LABEL_CLOSED_YOUR_TASK_NO_TITLE';
                break;
            case EventType.DELETED_YOUR_TASK:
                translateKey = postTitle
                    ? 'NOTIFICATION.BELL.LABEL_DELETED_YOUR_TASK'
                    : 'NOTIFICATION.BELL.LABEL_DELETED_YOUR_TASK_NO_TITLE';
                break;
            case EventType.ADD_COMMENT_ON_YOUR_POST:
                translateKey = this.buildAddCommentYourPostTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.REPLY_YOUR_COMMENT:
                translateKey = this.buildReplyYourCommentTranslateKey(
                    notification,
                    postTitle,
                );
                break;
            case EventType.CREATE_SURVEY:
                translateKey =
                    notification.post && isFeedBackPostType(notification.post)
                        ? 'NOTIFICATION.BELL.LABEL_CREATE_MISSION'
                        : 'NOTIFICATION.BELL.LABEL_CREATE_SURVEY';
                break;
            case EventType.CREATE_SURVEY_TASK_REQUEST:
                translateKey =
                    notification.post && isFeedBackPostType(notification.post)
                        ? 'NOTIFICATION.BELL.LABEL_CREATE_MISSION_TASK'
                        : 'NOTIFICATION.BELL.LABEL_CREATE_SURVEY_TASK';
                break;
            case EventType.EDIT_SURVEY:
                translateKey = this.buildEditSurveyTranslateKey(notification);
                break;
            case EventType.FEEDBACK_TASK_CHANGE_STATUS:
                translateKey =
                    'NOTIFICATION.BELL.LABEL_FEEDBACK_TASK_CHANGE_STATUS';
                break;
            case EventType.ACTIVITY_EDIT:
                //todo manca gestione aggregazione
                translateKey = 'NOTIFICATION.BELL.LABEL_EDITED_POST_ACTIVITY';
                break;
            case EventType.ACTIVITY_ASSIGNED:
                translateKey = 'NOTIFICATION.BELL.LABEL_ASSIGNED_POST_ACTIVITY';
                break;
            default:
                translateKey = notification.descriptionPlainText;
                break;
        }
        return translateKey;
    }

    buildTranslateInterpolateParams(
        notification: IEventActivity,
        postTitle: string | null,
    ): Record<string, unknown> {
        const interpolateParams: Record<string, unknown> = {};

        //POST TITLE
        if (postTitle) {
            interpolateParams.postTitle = postTitle;
        }

        //USERS
        if (notification.authorUsers.length > 0) {
            switch (notification.authorUsers.length) {
                case 1:
                    interpolateParams.fullName =
                        notification.authorUsers[0].fullName;
                    break;
                case 2:
                    interpolateParams.name1 =
                        notification.authorUsers[0].firstName;
                    interpolateParams.name2 =
                        notification.authorUsers[1].firstName;
                    break;
                case 3:
                    interpolateParams.name1 =
                        notification.authorUsers[0].firstName;
                    interpolateParams.name2 =
                        notification.authorUsers[1].firstName;
                    interpolateParams.name3 =
                        notification.authorUsers[2].firstName;
                    break;
                default:
                    interpolateParams.name1 =
                        notification.authorUsers[0].firstName;
                    interpolateParams.name2 =
                        notification.authorUsers[1].firstName;
                    interpolateParams.remainingPeopleCount =
                        notification.authorUsers.length - 2;
                    break;
            }
        }

        if (
            notification.typeId === EventType.EDIT_SCREEN_DATA &&
            notification.workflowState
        ) {
            interpolateParams.currentState = notification.workflowState.name;
        }

        if (TaskEventTypes.includes(notification.typeId) && notification.task) {
            interpolateParams.taskTitle = notification.task.title;
        }

        if (
            notification.typeId === EventType.ADD_HASHTAG &&
            notification.authorUsers.length === 1
        ) {
            interpolateParams.hashtag = notification.hashtags[0].name;
        }

        if (
            notification.typeId === EventType.EDITED_POST_ATTACHMENTS &&
            notification.authorUsers.length === 1 &&
            (notification.addedAttachments.length > 0 ||
                notification.versionedAttachments.length > 0)
        ) {
            interpolateParams.attachmentsCount =
                notification.addedAttachments.length > 0
                    ? notification.addedAttachments.length
                    : notification.versionedAttachments.length;
        }

        return interpolateParams;
    }

    private buildCreationTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        if (
            notification.post &&
            isActivityPost(notification.post) &&
            postTitle
        ) {
            return 'NOTIFICATION.BELL.LABEL_PUBLISHED_POST_ACTIVITY';
        } else {
            return postTitle
                ? 'NOTIFICATION.BELL.LABEL_PUBLISHED_POST'
                : 'NOTIFICATION.BELL.LABEL_PUBLISHED_POST_NO_TITLE';
        }
    }

    private buildEditTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey = 'NOTIFICATION.BELL.LABEL_EDITED_POST';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDITED_POST_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDITED_POST_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDITED_POST_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDITED_POST_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDITED_POST_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDITED_POST_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDITED_POST_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildEditSurveyTranslateKey(notification: IEventActivity): string {
        let translateKey = '';
        if (notification.post && isSurveyPost(notification.post)) {
            const baseLabel = isSurveyPostType(notification.post)
                ? 'EDITED_SURVEY'
                : 'EDITED_FEEDBACK';

            if (notification.authorUsers.length > 0) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey = `NOTIFICATION.BELL.${baseLabel}.ONE`;
                        break;
                    case 2:
                        translateKey = `NOTIFICATION.BELL.${baseLabel}.TWO`;
                        break;
                    case 3:
                        translateKey = `NOTIFICATION.BELL.${baseLabel}.THREE`;
                        break;
                    default:
                        translateKey = `NOTIFICATION.BELL.${baseLabel}.MULTIPLE`;
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildCommentTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_COMMENTED_ON_POST';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_COMMENTED_ON_POST_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_COMMENTED_ON_POST_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_COMMENTED_ON_POST_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_COMMENTED_ON_POST_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_COMMENTED_ON_POST_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_COMMENTED_ON_POST_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_COMMENTED_ON_POST_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildAddCommentYourPostTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_COMMENT_ON_YOUR_POST';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_COMMENT_ON_YOUR_POST_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_COMMENT_ON_YOUR_POST_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_COMMENT_ON_YOUR_POST_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_COMMENT_ON_YOUR_POST_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_COMMENT_ON_YOUR_POST_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_COMMENT_ON_YOUR_POST_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_COMMENT_ON_YOUR_POST_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }
    private buildReplyYourCommentTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_REPLY_YOUR_COMMENT';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_REPLY_YOUR_COMMENT_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_REPLY_YOUR_COMMENT_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_REPLY_YOUR_COMMENT_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_REPLY_YOUR_COMMENT_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_REPLY_YOUR_COMMENT_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_REPLY_YOUR_COMMENT_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_REPLY_YOUR_COMMENT_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildMentionTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_POST';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_POST_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_POST_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_POST_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_POST_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_POST_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_POST_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_POST_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildCommentMentionTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_COMMENT';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_COMMENT_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_COMMENT_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_COMMENT_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_COMMENT_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_COMMENT_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_COMMENT_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_MENTIONED_ON_COMMENT_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildStatusChangeTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                const isActivity = notification.post?.tag === PostType.ACTIVITY;
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey = isActivity
                            ? 'LABEL_CHANGED_STATUS_ACTIVITY'
                            : 'LABEL_CHANGED_STATUS';
                        break;
                    case 2:
                        translateKey = isActivity
                            ? 'LABEL_CHANGED_STATUS_ACTIVITY_TWO_PEOPLE'
                            : 'LABEL_CHANGED_STATUS_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey = isActivity
                            ? 'LABEL_CHANGED_STATUS_ACTIVITY_THREE_PEOPLE'
                            : 'LABEL_CHANGED_STATUS_THREE_PEOPLE';
                        break;
                    default:
                        translateKey = isActivity
                            ? 'LABEL_CHANGED_STATUS_ACTIVITY_MULTIPLE_PEOPLE'
                            : 'LABEL_CHANGED_STATUS_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey = 'LABEL_CHANGED_STATUS_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'LABEL_CHANGED_STATUS_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'LABEL_CHANGED_STATUS_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'LABEL_CHANGED_STATUS_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return `NOTIFICATION.BELL.${translateKey}`;
    }

    private buildPostLikeTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey = 'NOTIFICATION.BELL.LABEL_LIKE_POST';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildPostNotLikeTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey = 'NOTIFICATION.BELL.LABEL_NOT_LIKE_POST';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_NOT_LIKE_POST_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_NOT_LIKE_POST_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_NOT_LIKE_POST_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_NOT_LIKE_POST_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_NOT_LIKE_POST_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_NOT_LIKE_POST_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_NOT_LIKE_POST_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildFollowPostTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey = 'NOTIFICATION.BELL.LABEL_FOLLOW_POST';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_FOLLOW_POST_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_FOLLOW_POST_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_FOLLOW_POST_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_FOLLOW_POST_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_FOLLOW_POST_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_FOLLOW_POST_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_FOLLOW_POST_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildLikePostCommentTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_COMMENT';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_COMMENT_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_COMMENT_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_COMMENT_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_COMMENT_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_COMMENT_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_COMMENT_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_LIKE_POST_COMMENT_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildEditAttachmentTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (
                notification.authorUsers.length === 1 &&
                notification.versionedAttachments.length > 0 &&
                notification.addedAttachments.length === 0 &&
                notification.updatedAttachments.length === 0
            ) {
                translateKey =
                    notification.versionedAttachments.length === 1
                        ? postTitle
                            ? 'NOTIFICATION.BELL.LABEL_ADD_POST_ATTACHMENT_VERSION'
                            : 'NOTIFICATION.BELL.LABEL_ADD_POST_ATTACHMENT_VERSION_NO_TITLE'
                        : postTitle
                          ? 'NOTIFICATION.BELL.LABEL_ADD_POST_MULTIPLE_ATTACHMENT_VERSION'
                          : 'NOTIFICATION.BELL.LABEL_ADD_POST_MULTIPLE_ATTACHMENT_VERSION_NO_TITLE';
            } else if (
                notification.authorUsers.length === 1 &&
                notification.versionedAttachments.length === 0 &&
                notification.addedAttachments.length > 1 &&
                notification.updatedAttachments.length === 0
            ) {
                translateKey =
                    notification.addedAttachments.length === 1
                        ? postTitle
                            ? 'NOTIFICATION.BELL.LABEL_ADD_POST_ATTACHMENT'
                            : 'NOTIFICATION.BELL.LABEL_ADD_POST_ATTACHMENT_NO_TITLE'
                        : postTitle
                          ? 'NOTIFICATION.BELL.LABEL_ADD_POST_MULTIPLE_ATTACHMENT'
                          : 'NOTIFICATION.BELL.LABEL_ADD_POST_MULTIPLE_ATTACHMENT_NO_TITLE';
            } else if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDIT_POST_ATTACHMENT';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDIT_POST_ATTACHMENT_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDIT_POST_ATTACHMENT_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDIT_POST_ATTACHMENT_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDIT_POST_ATTACHMENT_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDIT_POST_ATTACHMENT_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDIT_POST_ATTACHMENT_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_EDIT_POST_ATTACHMENT_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }

    private buildAddHashtagTranslateKey(
        notification: IEventActivity,
        postTitle: string | null,
    ): string {
        let translateKey = '';
        if (notification.authorUsers.length > 0) {
            if (postTitle) {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey = 'NOTIFICATION.BELL.LABEL_ADD_HASHTAG';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_HASHTAG_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_HASHTAG_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_HASHTAG_MULTIPLE_PEOPLE';
                        break;
                }
            } else {
                switch (notification.authorUsers.length) {
                    case 1:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_HASHTAG_NO_TITLE';
                        break;
                    case 2:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_HASHTAG_NO_TITLE_TWO_PEOPLE';
                        break;
                    case 3:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_HASHTAG_NO_TITLE_THREE_PEOPLE';
                        break;
                    default:
                        translateKey =
                            'NOTIFICATION.BELL.LABEL_ADD_HASHTAG_NO_TITLE_MULTIPLE_PEOPLE';
                        break;
                }
            }
        }
        return translateKey;
    }
}
