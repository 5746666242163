import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { IWorkflowStatus } from '@modules/communities/models/workflow/workflow.model';
import {
    EventType,
    IEventActivity,
    INotificationNavigation,
    NotificationUserUtility,
} from '@modules/core/models/notification-user.model';
import { FeedbackTaskStateStyle } from '@modules/feedback/models/feedback.model';
import { getFeedbackTaskStateStyle } from '@modules/feedback/models/feedback.utils';
import { NotificationsService } from '@modules/notifications/services/notifications.service';
import { onOpenNotificationDetails } from '@modules/notifications/store/notifications.actions';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { PostType } from '@modules/post/models/base-post.model';
import { IEventPost } from '@modules/post/models/event-post.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'interacta-notification-row',
    templateUrl: './notification-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationRowComponent implements OnInit {
    @Input({ required: true }) notification!: IEventActivity;

    @Output() clickOnNotification = new EventEmitter<INotificationNavigation>();
    @Output() markAsRead = new EventEmitter<void>();
    @Output() viewAttachments = new EventEmitter<void>();

    EventType = EventType;
    PostType = PostType;
    TaskEventTypes: EventType[];
    attachment?: IAttachment;
    otherAttachments = 0;

    eventStartAt: { zonedDatetime?: Date; localDatetime: Date } | undefined =
        undefined;
    eventAllDay = false;

    fromState?: IWorkflowStatus;
    toState?: IWorkflowStatus;
    feedbackTaskFromStateStyle?: FeedbackTaskStateStyle;
    feedbackTaskToStateStyle?: FeedbackTaskStateStyle;

    constructor(
        private translateService: TranslateService,
        private notificationsService: NotificationsService,
        private store: Store,
    ) {
        this.TaskEventTypes = notificationsService.TaskEventTypes;
    }

    ngOnInit(): void {
        if (
            this.notification.post &&
            this.notification.post.type === PostType.EVENT
        ) {
            this.eventStartAt = (this.notification.post as IEventPost).startAt;
            this.eventAllDay = (this.notification.post as IEventPost).allDay;
        }

        if (this.notification.typeId === EventType.CHANGE_STATUS) {
            if (this.notification.aggregated) {
                this.fromState =
                    this.notification.firstPostWorkflowOperationDetail?.fromState;
            } else {
                this.fromState =
                    this.notification.lastPostWorkflowOperationDetail?.fromState;
            }
            this.toState =
                this.notification.lastPostWorkflowOperationDetail?.toState;
        }

        if (
            this.notification.typeId === EventType.FEEDBACK_TASK_CHANGE_STATUS
        ) {
            this.feedbackTaskFromStateStyle = this.notification
                .lastTaskTransitionOperationDetail?.fromState
                ? getFeedbackTaskStateStyle(
                      this.notification.lastTaskTransitionOperationDetail
                          .fromState,
                  )
                : undefined;
            this.feedbackTaskToStateStyle = this.notification
                .lastTaskTransitionOperationDetail?.toState
                ? getFeedbackTaskStateStyle(
                      this.notification.lastTaskTransitionOperationDetail
                          .toState,
                  )
                : undefined;
        }

        if (this.notification.typeId === EventType.EDITED_POST_ATTACHMENTS) {
            this.otherAttachments =
                this.notification.addedAttachments.length +
                this.notification.updatedAttachments.length +
                this.notification.versionedAttachments.length -
                1;
            if (this.notification.addedAttachments.length > 0) {
                this.attachment = this.notification.addedAttachments[0];
            } else if (this.notification.updatedAttachments.length > 0) {
                this.attachment = this.notification.updatedAttachments[0];
            } else if (this.notification.versionedAttachments.length > 0) {
                this.attachment = this.notification.versionedAttachments[0];
            }
        }
    }

    clickOnAttachment(event: MouseEvent): void {
        event.stopPropagation();
        this.viewAttachments.emit();
        if (!this.notification.read) {
            this.markAsRead.emit();
        }
    }

    public openNotificationDetails(): void {
        this.store.dispatch(
            onOpenNotificationDetails({ notification: this.notification }),
        );
        const navigateObject =
            NotificationUserUtility.navigationNotificationActivity(
                this.notification,
            );
        if (navigateObject) {
            this.clickOnNotification.emit(navigateObject);
        } else if (!this.notification.read) {
            this.markAsRead.emit();
        }
    }

    public buildNotificationText(): string {
        const postTitle = this.notificationsService.buildPostTitle(
            this.notification,
        );

        const translateKey = this.notificationsService.buildTranslateKey(
            this.notification,
            postTitle,
        );
        if (translateKey) {
            const params =
                this.notificationsService.buildTranslateInterpolateParams(
                    this.notification,
                    postTitle,
                );
            return this.translateService.instant(translateKey, params);
        } else {
            return '';
        }
    }
}
