import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogComponent } from '@interacta-shared/ui';
import { EditableTableComponent } from '@modules/editable-table';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TableInfoDialogComponent } from './components/table-info-dialog/table-info-dialog.component';
import { TableInfoEffects } from './store/table-info.effects';
import * as fromTableInfo from './store/table-info.reducer';

const standaloneComponents = [DialogComponent, EditableTableComponent];

@NgModule({
    declarations: [TableInfoDialogComponent],
    imports: [
        CommonModule,
        SharedV2Module,
        ...standaloneComponents,
        StoreModule.forFeature(
            fromTableInfo.TABLE_INFO_FEATURE_KEY,
            fromTableInfo.tableInfoReducer,
        ),
        EffectsModule.forFeature([TableInfoEffects]),
    ],
    exports: [TableInfoDialogComponent],
})
export class TableInfoModule {}
