import { Injectable } from '@angular/core';
import { AuthActions } from '@interacta-shared/data-access-auth';
import {
    closeCurrentProTip,
    closeCurrentTip,
} from '@interacta-shared/feature-tip';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { map } from 'rxjs';
import { BoundedTipConfig } from '../models/bounded-tip.model';

@Injectable()
export class BoundedTipEffects {
    closeTipsOnLogin$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.login),
            map(() => closeCurrentTip()),
        ),
    );

    closeTipsOnRouterNavigation$ = createEffect(() =>
        this.actions.pipe(
            ofType(routerNavigatedAction),
            // if a boundary is defined, close current proTip
            map(() =>
                closeCurrentProTip({
                    condition: async (payload) =>
                        payload != null &&
                        !!(payload as BoundedTipConfig<undefined>['payload'])
                            ?.boundary,
                }),
            ),
        ),
    );

    constructor(private actions: Actions) {}
}
