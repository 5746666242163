import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { createAction, props } from '@ngrx/store';

export type TableInfoError = any;

export const fetchTableInfoSuccess = createAction(
    '[Table Info API] Fetch Table Info Success',
    props<{ table: QuillTable }>(),
);

export const fetchTableInfoError = createAction(
    '[Table Info API] Fetch Table Info Error',
    props<{ tableId: number; error: TableInfoError }>(),
);
