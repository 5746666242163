<ng-container
    *ngIf="{
        loadingLayerState: loadingLayerState$ | async,
    } as $"
>
    <div
        *ngIf="$.loadingLayerState && $.loadingLayerState.loading"
        class="z-loading-layer fixed inset-0 flex items-center justify-center"
        [ngClass]="{
            'bg-transparent-black-not-black-80':
                $.loadingLayerState.content.blackBackground,
        }"
    >
        <div class="text-text-white flex flex-col items-center space-y-16">
            <interacta-image
                alt="image"
                class="w-100"
                [src]="src"
            ></interacta-image>

            <div class="typo-medium flex">
                <span>{{
                    $.loadingLayerState.content.firstLabel
                        | translate: $.loadingLayerState.content.translateParams
                }}</span>

                <div class="w-14">
                    <span
                        *ngFor="
                            let point of (points$ | async) || 0 | array;
                            let first = first;
                            let last = last
                        "
                        [ngClass]="{ 'mx-4': first, 'mr-4': !first && !last }"
                        >.</span
                    >
                </div>
            </div>
            <div *ngIf="showSecondLabel$ | async" class="typo-sm">
                {{ 'SHARED.LOADING_LAYER.SECOND_LABEL' | translate }}
            </div>
        </div>
    </div>
</ng-container>
