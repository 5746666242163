import {
    ChangeDetectionStrategy,
    Component,
    inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { isDefined, uuid } from '@interacta-shared/util';
import { AppSelectors, AppState } from '@modules/core/store';
import { decodeAdvancedSearchQuery } from '@modules/header/models/advanced-search/advanced-search.decoder';
import { encodeAdvancedSearchQuery } from '@modules/header/models/advanced-search/advanced-search.encoder';
import { AdvancedSearchQueryBuilder } from '@modules/header/models/advanced-search/advanced-search.model';
import { AdvancedSearchBarComponent } from '@modules/shared-v2/components/advanced-search-bar/advanced-search-bar.component';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'interacta-search',
    templateUrl: './search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
    private readonly fb = inject(FormBuilder);
    private readonly store = inject(Store<AppState>);
    private readonly router = inject(Router);

    formGroup = this.fb.group({
        search: this.fb.control<string | null>(null),

        advanced: this.fb.group({
            some: this.fb.control<string | null>(null),
            exactly: this.fb.control<string | null>(null),
            every: this.fb.control<string | null>(null),
            none: this.fb.control<string | null>(null),
        }),
    });

    isAdvancedSearchOpen = false;

    private search$ = new Subject<string>();
    private destroy$ = new Subject<void>();

    @ViewChild('advancedSearchBar')
    advancedSearchBar?: AdvancedSearchBarComponent;

    ngOnInit(): void {
        this.search$
            .pipe(
                withLatestFrom(
                    this.store.select(AppSelectors.selectRouteState),
                ),
                filter(([search]) => !!search),
                takeUntil(this.destroy$),
            )
            .subscribe(([search, route]) => {
                this.advancedSearchBar?.closeSearch();
                this.router.navigate(['/search-v2'], {
                    queryParams: {
                        q: search,
                        filter: uuid(),
                    },
                    replaceUrl:
                        isDefined(route) && route.appBaseRoute === 'search-v2',
                });
            });

        this.store
            .select(AppSelectors.selectRouteState)
            .pipe(filter(isDefined), takeUntil(this.destroy$))
            .subscribe((route) => {
                const query = route.queryParams.q;

                if (this.formGroup.value.search !== query) {
                    this.formGroup.controls.search.setValue(
                        route.appBaseRoute === 'search-v2'
                            ? route.queryParams.q
                            : '',
                    );
                }
            });
    }

    search(query: string): void {
        this.search$.next(query);
    }

    advancedSearch(builder: AdvancedSearchQueryBuilder): void {
        const query = encodeAdvancedSearchQuery(builder);
        if (query) {
            this.search(query);
        }
        this.isAdvancedSearchOpen = false;
    }

    setAdvancedSearch(value: boolean): void {
        if (value) {
            if (this.formGroup.controls.search.value) {
                const builder = decodeAdvancedSearchQuery(
                    this.formGroup.controls.search.value,
                );
                this.formGroup.controls.advanced.patchValue(builder);
            } else {
                this.formGroup.controls.advanced.reset();
            }
        }
        this.isAdvancedSearchOpen = value;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
