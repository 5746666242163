import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Language } from '@interacta-shared/data-access-configuration';

@Component({
    selector: 'interacta-user-menu-language',
    templateUrl: './user-menu-language.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuLanguageComponent {
    @Input() availableLanguages!: Language[];
    @Input() currentLanguage!: Language;

    @Output() changeLanguage = new EventEmitter();
    @Output() back = new EventEmitter();
}
