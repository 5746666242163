<ng-container *ngIf="data$ | async as $">
    <interacta-dialog size="regular" [isOpen]="$.isOpen" (closing)="close()">
        <div
            *ngIf="!$.isFetching && $.user"
            class="flex space-x-24"
            title
            [ngClass]="{
                'items-center':
                    $.user.privateProfile ||
                    !$.currentUser?.userSettings?.viewUserProfiles,
            }"
        >
            <interacta-avatar size="large" [user]="$.user"></interacta-avatar>
            <div class="flex flex-col">
                <span class="typo-t5 line-clamp-2">{{ $.user.fullName }}</span>
                <span *ngIf="$.user.caption" class="typo-sm">
                    {{ $.user.caption }}
                </span>
                <span *ngIf="$.user.role" class="typo-sm">{{
                    $.user.role
                }}</span>

                <div
                    *ngIf="$.user.deleted"
                    class="typo-sm text-text-primary flex items-center gap-4"
                >
                    <interacta-icon
                        class="h-24 w-24"
                        [icon]="'ban'"
                    ></interacta-icon>
                    {{
                        ($.user.blocked
                            ? 'PROFILE.USER_BLOCKED'
                            : 'PROFILE.USER_DELETED'
                        ) | translate
                    }}
                </div>
                <div
                    *ngIf="
                        $.isAdmin ||
                        $.user.id === $.currentUser?.id ||
                        (!$.user.privateProfile &&
                            $.currentUser?.userSettings?.viewUserProfiles)
                    "
                    class="space-x-18 mt-20 flex"
                >
                    <button
                        *ngIf="
                            $.currentUser &&
                            $.currentUser.id !== $.user.id &&
                            !$.user.deleted &&
                            !$.isAdmin
                        "
                        icon="bell"
                        interacta-button
                        type="button"
                        [active]="$.user.followedByMe ?? false"
                        [appearance]="$.user.followedByMe ? 'border' : 'fill'"
                        [label]="
                            ($.user.followedByMe
                                ? 'PEOPLE.ALREADY_FOLLOWING'
                                : 'PEOPLE.FOLLOW'
                            ) | translate
                        "
                        (click)="$event.stopPropagation(); toggleFollow()"
                    ></button>
                    <button
                        appearance="border"
                        interacta-button
                        type="button"
                        [bgColor]="'gray-100'"
                        [label]="
                            ($.isAdmin
                                ? 'PEOPLE.GO_TO_PROFILE'
                                : 'PEOPLE.GO_TO_FULL_PROFILE'
                            ) | translate
                        "
                        (click)="goToProfile($.user.id, $.isAdmin)"
                    ></button>
                </div>
            </div>
        </div>
        <div
            *ngIf="
                !$.isFetching &&
                $.user &&
                ($.isAdmin ||
                    $.user.id === $.currentUser?.id ||
                    (!$.user.privateProfile &&
                        $.currentUser?.userSettings?.viewUserProfiles)) &&
                !$.user.deleted
            "
            class="flex flex-col"
            content
        >
            <span *ngIf="$.user.biography" class="typo-xs italic">{{
                $.user.biography
            }}</span>
            <interacta-separator
                class="mb-4 mt-16"
                thickness="strong"
            ></interacta-separator>
            <interacta-people-details
                class="flex flex-col space-y-8"
                [items]="$.items"
            ></interacta-people-details>
        </div>
        <div *ngIf="$.isFetching" class="h-200 m-auto flex items-center" title>
            <interacta-loader [size]="'large'"></interacta-loader>
        </div>
    </interacta-dialog>
</ng-container>
