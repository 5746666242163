import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
} from '@angular/core';
import { translateFadeAnimation } from '@interacta-shared/ui';
import { Store } from '@ngrx/store';
import { TipActionCallback } from '../../models/tip.model';
import { isProTip, isSimpleTip, isSystemTip } from '../../models/tip.utils';
import { closeCurrentTip } from '../../store/tip.actions';
import { selectCurrentTip } from '../../store/tip.selectors';
import { ProTipComponent } from '../pro-tip/pro-tip.component';
import { SimpleTipComponent } from '../simple-tip/simple-tip.component';
import { SystemTipComponent } from '../system-tip/system-tip.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        SimpleTipComponent,
        ProTipComponent,
        SystemTipComponent,
    ],
    selector: 'interacta-tip-overlay',
    templateUrl: './tip-overlay.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [translateFadeAnimation('tip', null, '3rem', 'in-out')],
})
export class OverlayTipsComponent<A, P> {
    private readonly store = inject(Store);
    currentTip = this.store.selectSignal(selectCurrentTip);

    simpleTip = computed(() =>
        isSimpleTip(this.currentTip()) ? this.currentTip() : null,
    );

    proTip = computed(() => {
        const current = this.currentTip();
        return isProTip<A, P>(current) ? current : null;
    });

    systemTip = computed(() => {
        const current = this.currentTip();
        return isSystemTip<A, P>(current) ? current : null;
    });

    close(callback?: () => void): void {
        this.store.dispatch(closeCurrentTip());
        if (callback) {
            callback();
        }
    }

    forceReload(): void {
        window.location.reload();
    }

    actionClicked(actionCallback: TipActionCallback<A>): void {
        this.close();
        actionCallback.callback(actionCallback.action);
    }
}
