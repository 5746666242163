import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { CurrentUser } from '@interacta-shared/data-access-auth';
import { Language } from '@interacta-shared/data-access-configuration';
import { Theme } from '@interacta-shared/data-access-theme';
import { UserMenuPage } from '@modules/shared-v2/models/user-menu';

@Component({
    selector: 'interacta-user-menu-home',
    templateUrl: './user-menu-home.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuHomeComponent {
    @Input() availableLanguages: Language[] = [];
    @Input() currentLanguage!: Language;
    @Input() currentUser!: CurrentUser;
    @Input() currentTheme!: Theme;

    @Output() changePage = new EventEmitter<UserMenuPage>();
    @Output() logout = new EventEmitter<void>();
    @Output() openProfile = new EventEmitter<void>();
    @Output() openNotificationsSettings = new EventEmitter<void>();
}
