import { AdvancedSearchQueryBuilder } from './advanced-search.model';
import {
    allTokensExp,
    allwrappedTokensExp,
    wrappedTokenExp,
} from './advanced-search.utils';

type TokensClassifier = Record<keyof AdvancedSearchQueryBuilder, string[]>;

export function decodeAdvancedSearchQuery(
    query: string,
): AdvancedSearchQueryBuilder {
    const tokens = query.match(allTokensExp);
    const tt = query.match(allwrappedTokensExp);
    const exactlyCount = tt?.length ?? 0;
    const classifiedTokens: TokensClassifier = {
        some: [],
        exactly: [],
        every: [],
        none: [],
    };
    tokens?.forEach((t) =>
        classifyToken(t, classifiedTokens, exactlyCount > 1),
    );
    return builderFromClassifiedTokens(classifiedTokens);
}

function classifyToken(
    token: string,
    classifiedTokens: TokensClassifier,
    handleExactlyAsSome: boolean,
) {
    if (token.startsWith('+')) {
        classifiedTokens.every.push(token.slice(1));
    } else if (token.startsWith('-')) {
        classifiedTokens.none.push(token.slice(1));
    } else if (!handleExactlyAsSome && wrappedTokenExp.test(token)) {
        classifiedTokens.exactly.push(token.replace(/(^"|"$)/g, ''));
    } else {
        classifiedTokens.some.push(token);
    }
}

function builderFromClassifiedTokens(
    classifiedTokens: TokensClassifier,
): AdvancedSearchQueryBuilder {
    return {
        some: joinClassifiedTokens(classifiedTokens.some),
        every: joinClassifiedTokens(classifiedTokens.every),
        exactly: joinClassifiedTokens(classifiedTokens.exactly),
        none: joinClassifiedTokens(classifiedTokens.none),
    };
}

function joinClassifiedTokens(tokens: string[]): string | null {
    return tokens.length ? tokens.join(' ') : null;
}
