interface PathMapping {
    legacy: RegExp;
    new: {
        path: string;
        params?: Record<string, number>;
        query?: Record<string, number>;
    };
}

const PATH_MAPPINGS: Array<PathMapping> = [
    {
        legacy: /^\/login$/,
        new: {
            path: '/login',
        },
    },
    {
        legacy: /^\/login\/change-password\/(.+)$/,
        new: {
            path: '/login/change-password/:confirmToken',
            params: {
                confirmToken: 1,
            },
        },
    },
    {
        legacy: /^\/dashboard$/,
        new: {
            path: '/',
        },
    },
    {
        legacy: /^\/dashboard\/community\/(\d+)\/post$/,
        new: {
            path: '/community/:communityId',
            params: {
                communityId: 1,
            },
        },
    },
    {
        legacy: /^\/post\/details\/(\d+)(\?.*commentId=(\d+).*)$/,
        new: {
            path: '/post/:id/comments',
            params: {
                id: 1,
            },
            query: {
                commentId: 3,
            },
        },
    },
    {
        legacy: /^\/post\/details\/(\d+)(\?.*entity=workflow.*)$/,
        new: {
            path: '/post/:id/workflow',
            params: {
                id: 1,
            },
        },
    },
    {
        legacy: /^\/post\/details\/(\d+)/,
        new: {
            path: '/post/:id',
            params: {
                id: 1,
            },
        },
    },
    {
        legacy: /^\/task\/post\/(\d+)(\?.*taskId=(\d+).*)*$/,
        new: {
            path: '/post/:id/tasks',
            params: {
                id: 1,
            },
            query: {
                taskId: 3,
            },
        },
    },
    {
        legacy: /^\/post\/create\/(\d+)(\?q=(.+))*$/,
        new: {
            path: '/post/create/:communityId',
            params: {
                communityId: 1,
            },
            query: {
                q: 3,
            },
        },
    },
    {
        legacy: /^\/verify-private-email\?verifyToken=(.+)$/,
        new: {
            path: '/verify-private-email',
            query: {
                verifyToken: 1,
            },
        },
    },
    {
        legacy: /^\/unsubscribe$/,
        new: {
            path: '/unsubscribe',
        },
    },
];

function isLegacy(location: string) {
    return location.includes('#');
}

function isLegacyNested(location: string) {
    return location.includes('/legacy');
}

function isNewNested(location: string) {
    return location.includes('/app');
}

function getBasePath(originalLocation: string) {
    if (isLegacy(originalLocation)) {
        return originalLocation
            .split('#')[0]
            .replace(/\/index.html$/, '')
            .replace(/\/$/, '');
    }
    return originalLocation;
}

function getInnerPath(originalLocation: string) {
    if (isLegacy(originalLocation)) {
        return originalLocation.split('#')[1];
    }
    return null;
}

function getNewPath(legacyPath: string) {
    let result = '';
    PATH_MAPPINGS.some((mapping) => {
        const match = mapping.legacy.exec(legacyPath);
        if (match != null && match.length > 0) {
            result = mapping.new.path;
            const params = mapping.new.params;
            const query = mapping.new.query;
            if (params) {
                Object.keys(params).forEach((param) => {
                    const groupIndex = params[param];
                    const value = match[groupIndex];
                    result = result.replace(`:${param}`, value);
                });
            }
            if (query) {
                Object.keys(query).forEach((param) => {
                    const groupIndex = query[param];
                    const value = match[groupIndex];
                    if (value !== null && value !== undefined) {
                        if (result.includes('?')) {
                            result = `${result}&${param}=${value}`;
                        } else {
                            result = `${result}?${param}=${value}`;
                        }
                    }
                });
            }
            return true;
        } else {
            return false;
        }
    });
    return result;
}

function getNewMappedLocation(originalLocation: string) {
    let mappedLocation = getBasePath(originalLocation);
    const innerPath = getInnerPath(originalLocation);
    if (innerPath) {
        const newPath = getNewPath(innerPath);
        if (newPath) {
            mappedLocation = `${mappedLocation}${newPath}`;
        }
    }
    return mappedLocation;
}

export function getMappedLocation(originalLocation: string): string {
    let mappedLocation = originalLocation;

    if (isLegacyNested(originalLocation)) {
        mappedLocation = mappedLocation.replace('/legacy', '');
    }

    if (isLegacy(originalLocation)) {
        mappedLocation = getNewMappedLocation(mappedLocation);
    } else if (isNewNested(originalLocation)) {
        mappedLocation = mappedLocation.replace('/app', '');
    }

    return mappedLocation;
}
