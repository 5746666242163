<interacta-menu
    [open]="isOpen"
    [origin]="origin"
    [overridePositions]="[
        {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top',
        },
    ]"
    (closed)="currentPage = 'home'"
    (closing)="closing.emit()"
>
    <interacta-menu-decorator>
        <div class="min-w-252 w-min overflow-hidden p-16">
            <interacta-user-menu-home
                *ngIf="currentPage === 'home'"
                cdkTrapFocus
                cdkTrapFocusAutoCapture
                class="block"
                @slideLeft
                [availableLanguages]="availableLanguages"
                [currentLanguage]="currentLanguage"
                [currentTheme]="currentTheme"
                [currentUser]="currentUser"
                (changePage)="currentPage = $event"
                (logout)="logout.emit()"
                (openNotificationsSettings)="openNotificationsSettings.emit()"
                (openProfile)="openProfile.emit()"
            />

            <interacta-user-menu-theme
                *ngIf="currentPage === 'theme'"
                cdkTrapFocus
                cdkTrapFocusAutoCapture
                class="block"
                @slideRight
                [currentTheme]="currentTheme"
                (back)="currentPage = 'home'"
                (changeTheme)="changeTheme.emit($event)"
            />

            <interacta-user-menu-language
                *ngIf="currentPage === 'language'"
                cdkTrapFocus
                cdkTrapFocusAutoCapture
                class="block"
                @slideRight
                [availableLanguages]="availableLanguages"
                [currentLanguage]="currentLanguage"
                (back)="currentPage = 'home'"
                (changeLanguage)="changeLanguage.emit($event)"
            />
        </div>
    </interacta-menu-decorator>
</interacta-menu>
