import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { Observable, map } from 'rxjs';
import { UserCapabilities, UserDeserilize } from '../models/user.model';

@Injectable({
    providedIn: 'root',
})
export class GlobalAdminCapabilitiesService {
    private readonly http = inject(HttpClient);

    private readonly baseUrlAdminV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/data`;

    getCurrentUserCapabilities(): Observable<UserCapabilities> {
        return this.http
            .get<any>(`${this.baseUrlAdminV3}/global/admin-capabilities`)
            .pipe(map((c) => UserDeserilize.userCapabilities(c)));
    }
}
