<ng-container *ngIf="data$ | async as $">
    @let dialogId = 'group-info-dialog-v2';
    <interacta-dialog
        [contentOverflowAuto]="false"
        [id]="dialogId"
        [isOpen]="$.isOpen"
        [size]="'small'"
        (closed)="close()"
    >
        <h2 class="typo-t6" title>
            {{ 'GROUP_DETAILS.GROUP_DETAILS' | translate }}
        </h2>

        @if (!$.isFetching && $.group) {
            <div class="flex flex-col gap-y-8" subtitle>
                <div class="flex items-center gap-x-16">
                    <interacta-group-avatar
                        [group]="$.group"
                        [size]="'medium'"
                    />

                    <div class="flex flex-col items-start gap-4">
                        <p class="typo-medium">
                            {{ $.group.fullName }}
                        </p>

                        @if (!$.group.deleted) {
                            <p class="typo-xs-bold-uppercase text-gray-700">
                                {{
                                    $.group.membersCount
                                        | i18nPlural
                                            : {
                                                  '=1': 'GROUP_DETAILS.NUMBER_OF_MEMBERS_SINGULAR',
                                                  other: 'GROUP_DETAILS.NUMBER_OF_MEMBERS_PLURAL',
                                              }
                                        | translate
                                            : { count: $.group.membersCount }
                                }}
                            </p>
                        }

                        @if ($.group.tags && $.group.tags.length) {
                            <div
                                class="max-w-240 flex gap-x-4 overflow-hidden"
                                interactaShowMoreChildren
                                (showMore)="remainingTags = $event"
                            >
                                @for (tag of $.group.tags; track tag.id) {
                                    <interacta-group-tag
                                        interactaShowMoreItem
                                        [label]="tag.name"
                                        [size]="'small'"
                                    />
                                }
                                @if (remainingTags) {
                                    <span class="typo-xs">{{
                                        '+' + remainingTags
                                    }}</span>
                                }
                            </div>
                        }
                    </div>
                </div>
                @if ($.group.description) {
                    <p class="typo-sm line-clamp-3 whitespace-pre-line italic">
                        {{ $.group.description }}
                    </p>
                }
                <interacta-separator class="mb-16" />
            </div>
        }

        @if (
            $.group &&
            ($.group.membersCount ?? 0) > N_MEMBERS_TO_SHOW &&
            showMoreMembers
        ) {
            <interacta-input-search
                class="mb-16"
                search
                [control]="searchMemberControl"
                [iconPosition]="'right'"
            />
        }

        @let editGroupButtonId = dialogId + '-edit-group-button';
        @if (!$.isFetching && $.group) {
            <div
                *ngIf="members$ | async as members"
                class="min-h-360 flex flex-col gap-y-16 overflow-hidden"
                content
            >
                <!-- MEMBERS PREVIEW -->
                @if (members) {
                    @if (showMoreMembers) {
                        <div
                            class="h-full overflow-y-auto py-4"
                            interactaScrollTracker
                            (scrolled)="onScroll($event, members.nextPageToken)"
                        >
                            @if (
                                members.isFetching &&
                                members.nextPageToken.tag === 'firstLoading'
                            ) {
                                <interacta-member-list-placeholder />
                            } @else if (members.list.length) {
                                <ul
                                    class="flex grow flex-col gap-y-16"
                                    interactaInfiniteScroll
                                    [anchor]="
                                        showEditButton()
                                            ? editGroupButtonId
                                            : dialogId + '-close-button'
                                    "
                                    [ariaBusy]="members.isFetching"
                                >
                                    @for (
                                        member of members.list;
                                        track member.id
                                    ) {
                                        <li
                                            interactaInfiniteScrollItem
                                            [ariaPosinset]="$index + 1"
                                            [ariaSetsize]="members.list.length"
                                        >
                                            <ng-template
                                                *ngTemplateOutlet="
                                                    memberTemplate;
                                                    context: {
                                                        member,
                                                    }
                                                "
                                            />
                                        </li>
                                    }
                                </ul>
                                @if (
                                    members.nextPageToken.tag ===
                                    'regularLoading'
                                ) {
                                    <interacta-load-more
                                        class="flex items-center justify-center py-8"
                                        [isLoading]="members.isFetching"
                                        [label]="
                                            'UI.SHOW_MORE_UNCOUNT' | translate
                                        "
                                        [size]="'regular'"
                                        (loadMore)="
                                            fetchNextMembers(
                                                members.nextPageToken
                                            )
                                        "
                                    />
                                }
                            }
                        </div>
                    } @else {
                        @if (members.isFetching) {
                            <interacta-member-list-placeholder />
                        } @else {
                            <p *ngIf="$.group.deleted" class="typo-sm">
                                {{ 'GROUP_DETAILS.DELETED_GROUP' | translate }}
                            </p>

                            <ul
                                *ngIf="$.group.membersCount"
                                class="flex h-full flex-col gap-y-16 overflow-y-auto pe-8"
                            >
                                @for (member of members.list; track member.id) {
                                    <li
                                        class="flex items-center justify-between"
                                    >
                                        <ng-template
                                            *ngTemplateOutlet="
                                                memberTemplate;
                                                context: {
                                                    member,
                                                }
                                            "
                                        />
                                        <button
                                            *ngIf="
                                                $last && remainingMembers > 0
                                            "
                                            class="typo-sm text-palette-secondary"
                                            type="button"
                                            (click)="loadMoreMembers()"
                                        >
                                            {{
                                                remainingMembers
                                                    | i18nPlural
                                                        : {
                                                              '=1': 'GROUP_DETAILS.OTHER_MEMBERS_SINGULAR',
                                                              other: 'GROUP_DETAILS.OTHER_MEMBERS_PLURAL',
                                                          }
                                                    | translate
                                                        : {
                                                              remainingMembers,
                                                          }
                                            }}
                                        </button>
                                    </li>
                                }
                            </ul>
                        }
                    }
                }
            </div>
        } @else {
            <div class="h-200 m-auto flex items-center" content>
                <interacta-loader [size]="'large'" />
            </div>
        }

        <interacta-dialog-buttons
            *ngIf="$.group && showEditButton()"
            [showSeparator]="true"
        >
            <button
                interacta-dialog-button
                type="button"
                [active]="true"
                [appearance]="'ghost'"
                [attr.id]="editGroupButtonId"
                [bgColor]="'primary'"
                [label]="'GROUP_DETAILS.EDIT_GROUP' | translate"
                (click)="edit($.group.id)"
            ></button>
        </interacta-dialog-buttons>
    </interacta-dialog>

    <ng-template #memberTemplate let-member="member">
        @if (isAdminSection()) {
            <interacta-admin-v2-member [member]="member" />
        } @else {
            <interacta-member [member]="member" [size]="'small'" />
        }
    </ng-template>
</ng-container>
