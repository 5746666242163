import { Injectable, inject } from '@angular/core';
import { ErrorService } from '@interacta-shared/data-access-error';
import { LoadingLayerService } from '@interacta-shared/util-common';
import { adminFlush, flush } from '@modules/core/store/app.actions';
import { tableMentionClick } from '@modules/shared-v2/store/mentions.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, groupBy, map, mergeMap, of, tap } from 'rxjs';
import { TableInfoService } from '../service/table-info.service';
import * as TableInfoApiActions from './table-info-api.actions';
import * as TableInfoActions from './table-info.actions';

@Injectable()
export class TableInfoEffects {
    private actions$ = inject(Actions);
    private tableInfoService = inject(TableInfoService);
    private errorService = inject(ErrorService);
    private loadingLayerService = inject(LoadingLayerService);

    appFlush$ = createEffect(() =>
        this.actions$.pipe(
            ofType(flush, adminFlush),
            map(() => TableInfoActions.clear()),
        ),
    );

    openDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tableMentionClick),
            map(({ id }) =>
                TableInfoActions.openDetail({
                    tableId: id,
                }),
            ),
        ),
    );

    fetchTableInfoOnOpenDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TableInfoActions.openDetail),
            map(({ tableId }) =>
                TableInfoActions.fetchTableInfo({
                    tableId,
                }),
            ),
        ),
    );

    fetchTable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TableInfoActions.fetchTableInfo),
            groupBy(({ tableId }) => tableId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ tableId }) => {
                        this.loadingLayerService.open({
                            firstLabel: '',
                            delay: 1000,
                        });
                        return this.tableInfoService.getTableInfo(tableId).pipe(
                            map((table) =>
                                TableInfoApiActions.fetchTableInfoSuccess({
                                    table,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    TableInfoApiActions.fetchTableInfoError({
                                        error,
                                        tableId,
                                    }),
                                ),
                            ),
                        );
                    }),
                ),
            ),
        ),
    );

    closeLoader$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    TableInfoApiActions.fetchTableInfoSuccess,
                    TableInfoApiActions.fetchTableInfoError,
                ),
                tap(() => this.loadingLayerService.close()),
            ),
        { dispatch: false },
    );

    error$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TableInfoApiActions.fetchTableInfoError),
                tap(({ error }) => {
                    this.errorService.handle(error);
                }),
            ),
        { dispatch: false },
    );
}
