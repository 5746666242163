import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canMatchAuthGuard } from '@interacta-shared/data-access-auth';
import { featureErrorRoutes } from '@interacta-shared/feature-error';
import { SKIP_TITLE } from '@interacta-shared/util-common';
import { CapabilitiesGuard } from '@modules/app-routing';
import { EnvironmentInfoGuard } from './guards/environment-info.guard';
import { EnvironmentGuard } from './guards/environment.guard';
import { UserSettingsGuard } from './guards/user-settings.guard';
import { notificationSettings } from './routes';

const appRoutes: Routes = [
    {
        path: 'external-auth-redirect/:resultToken',
        loadComponent: () =>
            import('@interacta-shared/feature-login').then(
                (m) => m.ExternalAuthRedirectPageComponent,
            ),
    },
    {
        path: 'error',
        children: featureErrorRoutes,
    },
    {
        path: 'unsubscribe',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../unsubscribe/unsubscribe.module').then(
                (m) => m.UnsubscribeModule,
            ),
    },
    {
        path: 'login',
        loadChildren: () =>
            import('@interacta-shared/feature-login').then(
                (m) => m.featureLoginRoutes,
            ),
    },
    {
        path: 'people',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../people/people.module').then((m) => m.PeopleModule),
    },
    {
        path: 'manage',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../manage/manage.module').then((m) => m.ManageModule),
    },
    {
        path: 'digital-workplace-admin',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import(
                '../digital-workplace-admin/digital-workplace-admin.module'
            ).then((m) => m.DigitalWorkplaceAdminModule),
    },
    {
        path: 'digital-workplace',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../digital-workplace/digital-workplace.module').then(
                (m) => m.DigitalWorkplaceModule,
            ),
    },
    {
        path: 'insights',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../insights/insights.module').then((m) => m.InsightsModule),
    },
    {
        path: 'admin-v2',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../admin-v2-routing/admin-v2-routing.module').then(
                (m) => m.AdminV2RoutingModule,
            ),
    },
    {
        path: 'bootcamp',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../bootcamp/bootcamp.module').then((m) => m.BootcampModule),
    },

    {
        path: 'search-v2',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../search-v2/search-v2.module').then(
                (m) => m.SearchV2Module,
            ),
    },
    {
        path: 'post/create',
        canMatch: [canMatchAuthGuard],
        data: {
            id: 'Create Post',
            mode: 'create',
            title: SKIP_TITLE,
        },
        loadChildren: () =>
            import('../post-create/post-create.module').then(
                (m) => m.PostCreateModule,
            ),
    },
    {
        path: 'post/edit',
        canMatch: [canMatchAuthGuard],
        data: {
            id: 'Edit Post',
            mode: 'edit',
            title: SKIP_TITLE,
        },
        loadChildren: () =>
            import('../post-create/post-create.module').then(
                (m) => m.PostCreateModule,
            ),
    },
    {
        path: 'post/copy',
        canMatch: [canMatchAuthGuard],
        data: {
            id: 'Copy Post',
            mode: 'copy',
            title: SKIP_TITLE,
        },
        loadChildren: () =>
            import('../post-create/post-create.module').then(
                (m) => m.PostCreateModule,
            ),
    },
    {
        path: 'post/print',
        canMatch: [canMatchAuthGuard],
        data: { id: 'Print Post', title: 'PAGE_TITLE.POST.PRINT' },
        loadChildren: () =>
            import('../post-print/post-print.module').then(
                (m) => m.PostPrintModule,
            ),
    },
    {
        path: 'post/feedback',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../feedback/feedback.module').then((m) => m.FeedbackModule),
    },
    {
        path: 'post',
        canMatch: [canMatchAuthGuard],
        data: { title: SKIP_TITLE },
        loadChildren: () =>
            import('../post-detail/post-detail.module').then(
                (m) => m.PostDetailModule,
            ),
    },
    {
        path: 'profile',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../profile/profile.module').then((m) => m.ProfileModule),
    },
    {
        path: 'community-detail',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../community-detail/community-detail.module').then(
                (m) => m.CommunityDetailModule,
            ),
    },
    {
        path: notificationSettings,
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import(
                '../notification-settings/notification-settings.module'
            ).then((m) => m.NotificationSettingsModule),
    },
    {
        path: '',
        canMatch: [canMatchAuthGuard],
        loadChildren: () =>
            import('../dashboard/dashboard.module').then(
                (m) => m.DashboardModule,
            ),
    },
    {
        path: '**',
        redirectTo: 'error/page-not-found',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            // enableTracing: true,
            // preloadingStrategy: PreloadAllModules,
        }),
    ],
    declarations: [],
    providers: [
        CapabilitiesGuard,
        UserSettingsGuard,
        EnvironmentGuard,
        EnvironmentInfoGuard,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
