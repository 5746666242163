import { HashtagInfo } from './hashtag-info.model';

export const toHashtagInfo = (record: any): HashtagInfo => ({
    id: record.hashtag.id,
    name: record.hashtag.name,
    communityId: record.hashtag.communityId,
    followedByMe: record.hashtag.followedByMe,
    deleted: record.hashtag.deleted,
    count: record.count,
});
