<ng-container
    *ngIf="{
        hashtag: hashtag$ | async,
        community: community$ | async,
        visible: (visible$ | async) ?? false,
    } as $"
>
    <interacta-dialog
        [isOpen]="$.visible"
        [size]="'regular'"
        (closing)="close()"
    >
        <!-- Title -->
        <ng-container title>
            @if ($.hashtag && $.community) {
                <h5 class="typo-t5">#{{ $.hashtag.name }}</h5>
            } @else {
                <section class="h-200 m-auto flex items-center">
                    <interacta-loader [size]="'large'" />
                </section>
            }
        </ng-container>

        <!-- Content -->
        <ng-container content>
            @if ($.hashtag && $.community?.metadata?.hashTagEnabled) {
                <p class="typo-sm mb-16">
                    {{
                        $.hashtag.count
                            | i18nPlural
                                : {
                                      '=1': 'HASHTAG_INFO.COUNT_IN_COMMUNITY_SING',
                                      other: 'HASHTAG_INFO.COUNT_IN_COMMUNITY_PLUR',
                                  }
                            | translate
                                : {
                                      count: $.hashtag.count,
                                      community: $.community?.name,
                                  }
                    }}
                </p>

                <!-- Follow/unfollow button -->
                @if (!$.hashtag.deleted) {
                    <button
                        class="mb-16"
                        interacta-button
                        type="button"
                        [active]="$.hashtag.followedByMe ?? false"
                        [appearance]="
                            $.hashtag.followedByMe ? 'border' : 'fill'
                        "
                        [icon]="'bell'"
                        [label]="
                            ($.hashtag.followedByMe
                                ? 'HASHTAG_INFO.ALREADY_FOLLOWING'
                                : 'HASHTAG_INFO.FOLLOW'
                            ) | translate
                        "
                        (click)="
                            $event.stopPropagation(); toggleFollow($.hashtag)
                        "
                    ></button>
                }

                <interacta-separator
                    class="mb-8 block"
                    [thickness]="'strong'"
                />

                <!-- Search type selection -->
                <p class="typo-sm-bold">
                    {{ 'HASHTAG_INFO.SEARCH' | translate }}
                </p>
                @if ($.community?.metadata?.attachmentEnabled) {
                    <interacta-radio-group [formControl]="searchType">
                        <interacta-radio-button
                            class="typo-sm mt-12"
                            [value]="'post'"
                        >
                            {{ 'HASHTAG_INFO.SEARCH_TYPE.POST' | translate }}
                        </interacta-radio-button>
                        <interacta-radio-button
                            class="typo-sm mt-12"
                            [value]="'attachment'"
                        >
                            {{
                                'HASHTAG_INFO.SEARCH_TYPE.ATTACHMENT'
                                    | translate
                            }}
                        </interacta-radio-button>
                        <interacta-radio-button
                            class="typo-sm mt-12"
                            [value]="'both'"
                        >
                            {{ 'HASHTAG_INFO.SEARCH_TYPE.BOTH' | translate }}
                        </interacta-radio-button>
                    </interacta-radio-group>
                } @else {
                    <interacta-checkbox-group [formControl]="searchType">
                        <interacta-checkbox-v2
                            class="typo-sm mt-12"
                            [value]="'post'"
                        >
                            {{ 'HASHTAG_INFO.SEARCH_TYPE.POST' | translate }}
                        </interacta-checkbox-v2>
                    </interacta-checkbox-group>
                }

                <!-- Apply / cancel buttons -->
                <interacta-dialog-buttons>
                    <button
                        interacta-dialog-button
                        type="button"
                        [appearance]="'ghost'"
                        [bgColor]="'surface-A'"
                        [label]="
                            'BUTTON.LABEL_BUTTON_CANCEL'
                                | translate: { hashtag: $.hashtag.name }
                        "
                        (click)="close()"
                    ></button>
                    <button
                        interacta-dialog-button
                        type="button"
                        [appearance]="'fill'"
                        [bgColor]="'primary'"
                        [label]="'BUTTON.LABEL_BUTTON_APPLY' | translate"
                        (click)="
                            apply($.hashtag, searchType.value, $.community)
                        "
                    ></button>
                </interacta-dialog-buttons>
            } @else if ($.community) {
                <!-- Content in case of disabled hashtags in community -->
                <p>
                    {{ 'HASHTAG_INFO.DISABLED_COMMUNITY' | translate }}
                </p>
                <interacta-dialog-buttons>
                    <button
                        interacta-dialog-button
                        type="button"
                        [appearance]="'fill'"
                        [bgColor]="'primary'"
                        [label]="'HASHTAG_INFO.UNDERSTOOD' | translate"
                        (click)="close()"
                    ></button>
                </interacta-dialog-buttons>
            }
        </ng-container>
    </interacta-dialog>
</ng-container>
