import { AdvancedSearchQueryBuilder } from './advanced-search.model';
import {
    allNoOperatorTokensExp,
    specialOperatorExp,
    wrappedTokenExp,
} from './advanced-search.utils';

export function encodeAdvancedSearchQuery(
    builder: AdvancedSearchQueryBuilder,
): string {
    const queryString: string[] = [];

    encodePartial(queryString, builder.some, encodeSome);
    encodePartial(queryString, builder.exactly, encodeExactly);
    encodePartial(queryString, builder.every, encodeEvery);
    encodePartial(queryString, builder.none, encodeNone);

    return queryString.join(' ');
}

function encodePartial(
    queryString: string[],
    partial: string | null,
    encodeFunction: (partial: string | null) => string | null,
): void {
    const encodedPartial = encodeFunction(partial);
    if (encodedPartial) {
        queryString.push(encodedPartial);
    }
}

const encodeSome = (some: string | null): string | null => some;

const encodeExactly = (exactly: string | null): string | null => {
    const unwrapped = exactly?.replace(/"/g, '');
    if (unwrapped) {
        return `"${unwrapped}"`;
    } else {
        return null;
    }
};

const encodeEvery = (every: string | null): string | null => {
    return encodeSpecialOperator(every, '+');
};

const encodeNone = (none: string | null): string | null => {
    return encodeSpecialOperator(none, '-');
};

function encodeSpecialOperator(
    partial: string | null,
    operator: '+' | '-',
): string | null {
    if (partial) {
        const tokens = partial.match(allNoOperatorTokensExp);
        if (tokens?.length) {
            return tokens
                .map((t) => {
                    let sanified = t;
                    if (
                        specialOperatorExp.test(t) &&
                        !wrappedTokenExp.test(t)
                    ) {
                        sanified = `"${t}"`;
                    }
                    return `${operator}${sanified}`;
                })
                .join(' ');
        }
    }
    return null;
}
