import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    ApplyPipe,
    DeltaViewComponent,
    DialogButtonComponent,
    IconButtonComponent,
} from '@interacta-shared/ui';
import { IconComponent } from '@interacta-shared/ui-icons';
import { TranslateModule } from '@ngx-translate/core';
import { TipData, TipSeverity } from '../../models/tip.model';
import { asDelta, asString } from '../../models/tip.utils';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        IconComponent,
        IconButtonComponent,
        DialogButtonComponent,
        DeltaViewComponent,
        ApplyPipe,
    ],
    selector: 'interacta-simple-tip',
    templateUrl: './simple-tip.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleTipComponent {
    @Input({ required: true }) tip!: TipData | null;
    @Output() closeTip = new EventEmitter<void>();
    @Output() forceReload = new EventEmitter<void>();

    protected readonly TipSeverity = TipSeverity;
    protected readonly asString = asString;
    protected readonly asDelta = asDelta;
}
