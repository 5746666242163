import {
    APP_BASE_HREF,
    PlatformLocation,
    registerLocaleData,
} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEs from '@angular/common/locales/es';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeElExtra from '@angular/common/locales/extra/el';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeItExtra from '@angular/common/locales/extra/it';
import localePtExtra from '@angular/common/locales/extra/pt';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AuthEffects } from '@interacta-shared/data-access-auth';
import { PageTitleEffects } from '@interacta-shared/data-access-common';
import {
    ConfigurationService,
    ENVIRONMENT,
    provideEnvironment,
} from '@interacta-shared/data-access-configuration';
import {
    ErrorService,
    provideErrorHandler,
    provideErrorPageInfoRegistry,
} from '@interacta-shared/data-access-error';
import { ThemeEffects } from '@interacta-shared/data-access-theme';
import { PrivateEmailEffects } from '@interacta-shared/feature-private-email';
import {
    OverlayTipsComponent,
    TipsEffects,
    TipService,
} from '@interacta-shared/feature-tip';
import { DEFAULT_INFINITE_SCROLL_ANCHOR } from '@interacta-shared/ui';
import {
    ConfirmDialogComponent,
    LoadingLayerComponent,
} from '@interacta-shared/ui-common';
import { AppLogoPipe } from '@interacta-shared/util-common';
import {
    BulkWorkflowTransitionBannerComponent,
    BulkWorkflowTransitionEffects,
} from '@interacta/feature-bulk-workflow-transition';
import { AIModule } from '@modules/ai/ai.module';
import { AppRoutingModule } from '@modules/app-routing/app-routing.module';
import { AttachmentsShareModule } from '@modules/attachments-share/attachments-share.module';
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { BottomMenuComponent } from '@modules/bottom-menu/components/bottom-menu/bottom-menu.component';
import { CarouselModule } from '@modules/carousel/carousel.module';
import { ChatModule } from '@modules/chat/chat.module';
import { CommunitiesModule } from '@modules/communities/communities.module';
import { CoreModule } from '@modules/core/core.module';
import { getBaseHref } from '@modules/core/helpers/base-href.utils';
import { translateHttpLoaderFactory } from '@modules/core/helpers/translate-http-loader-factory';
import { errorPageInfoRegistryConfig } from '@modules/core/models/error-page-info.model';
import { BootService } from '@modules/core/services/boot.service';
import { appMetaReducers, AppState } from '@modules/core/store';
import { UiEffects } from '@modules/core/store/UI/ui.effects';
import {
    AppRouterSerializer,
    AppRouterState,
} from '@modules/core/store/app-router.serializer';
import { AppEffects } from '@modules/core/store/app.effects';
import { reducers } from '@modules/core/store/app.reducer';
import { BoundedTipEffects } from '@modules/core/store/bounded-tip.effects';
import { UserCapabilitiesEffects } from '@modules/core/store/user-capabilities/user-capabilities.effetcs';
import { GroupInfoDialogV2Component } from '@modules/group-info/group-info-dialog-v2/group-info-dialog-v2.component';
import { HashtagInfoModule } from '@modules/hashtag-info/hashtag-info.module';
import { HeaderModule } from '@modules/header/header.module';
import { LightBoxModule } from '@modules/light-box/light-box.module';
import { MentionsModule } from '@modules/mentions/mentions.module';
import { NotificationsModule } from '@modules/notifications/notifications.module';
import { PeopleDialogModule } from '@modules/people-dialog/people-dialog.module';
import { PostCommentModule } from '@modules/post-comment/post-comment.module';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { CustomReuseStrategy } from '@modules/shared-v2/utils/route-reuse';
import { SidebarModule } from '@modules/sidebar/sidebar.module';
import { SkipLinksComponent } from '@modules/skip-links/skip-links/skip-links.component';
import { StateModule } from '@modules/state/state.module';
import { TableInfoModule } from '@modules/table-info/table-info.module';
import { UploadEffects } from '@modules/upload-dialog/store/upload.effects';
import { UploadDialogModule } from '@modules/upload-dialog/upload-dialog.module';
import { UsefulLinkModule } from '@modules/useful-link/useful-link.module';
import { EffectsModule } from '@ngrx/effects';
import {
    NavigationActionTiming,
    StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { appEnvironment } from 'environments/environment';
import { QuillModule } from 'ngx-quill';
import { AppComponent } from './app.component';

registerLocaleData(localeIt, 'it', localeItExtra);
registerLocaleData(localeEs, 'es', localeEsExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeEl, 'el', localeElExtra);
registerLocaleData(localePt, 'pt', localePtExtra);
registerLocaleData(localeFr, 'fr', localeFrExtra);

function initInteractaService(
    bootService: BootService,
    configurationService: ConfigurationService,
    errorService: ErrorService,
) {
    return (): Promise<any> =>
        bootService.initApp().catch((e) => {
            configurationService.initTranslateLang();
            errorService.handle(e);
        });
}

const standaloneComponents = [
    BulkWorkflowTransitionBannerComponent,
    ConfirmDialogComponent,
    BottomMenuComponent,
    GroupInfoDialogV2Component,
    OverlayTipsComponent,
    LoadingLayerComponent,
    SkipLinksComponent,
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        ReactiveFormsModule,
        //CoreModule have to init here before others Modules
        //because it has a custom error handler which must run at the beginning
        CoreModule.forRoot(),
        StateModule,
        SharedV2Module,
        SidebarModule,
        QuillModule.forRoot(),
        RouterModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateHttpLoaderFactory,
                deps: [HttpClient, ENVIRONMENT],
            },
        }),
        StoreModule.forRoot<AppState>(reducers, {
            metaReducers: appMetaReducers,
            runtimeChecks: {
                strictActionImmutability: false,
                strictStateImmutability: false,
                strictActionTypeUniqueness: true,
            },
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 50,
            logOnly: appEnvironment.production,
            connectInZone: true,
        }),
        EffectsModule.forRoot([
            AuthEffects,
            AppEffects,
            UiEffects,
            UploadEffects,
            TipsEffects,
            PageTitleEffects<AppRouterState>,
            BoundedTipEffects,
            UserCapabilitiesEffects,
            PrivateEmailEffects,
            ThemeEffects,
            BulkWorkflowTransitionEffects,
        ]),
        StoreRouterConnectingModule.forRoot({
            serializer: AppRouterSerializer,
            navigationActionTiming: NavigationActionTiming.PostActivation,
        }),
        UsefulLinkModule,
        ChatModule,
        CarouselModule,
        MentionsModule,
        LightBoxModule,
        UploadDialogModule,
        NotificationsModule,
        PostCommentModule,
        CommunitiesModule.forRoot(),
        PeopleDialogModule,
        HashtagInfoModule,
        TableInfoModule,
        AttachmentsModule,
        HeaderModule,
        AttachmentsShareModule,
        AIModule,
        MatDateFnsModule,
        AppLogoPipe,
        ...standaloneComponents,
    ],
    providers: [
        provideEnvironment(appEnvironment),
        provideErrorPageInfoRegistry(errorPageInfoRegistryConfig),
        provideErrorHandler(TipService),
        {
            provide: APP_INITIALIZER,
            useFactory: initInteractaService,
            deps: [BootService, ConfigurationService, ErrorService],
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
        {
            provide: APP_BASE_HREF,
            useFactory: getBaseHref,
            deps: [PlatformLocation],
        },
        {
            provide: DEFAULT_INFINITE_SCROLL_ANCHOR,
            useValue: 'skip-link-button-main-content',
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
