<ng-container
    *ngIf="{
        components: components$ | async,
    } as $"
>
    @if ($.components?.length) {
        @for (component of $.components; track component.type) {
            <div
                class="absolute bottom-0 h-0 overflow-visible"
                [class]="{ component, $index } | apply: getComponentClasses"
            >
                <ng-container
                    *ngComponentOutlet="component.type"
                ></ng-container>
            </div>
        }
    }
</ng-container>
