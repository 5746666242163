@if (tip(); as tip) {
    <div class="flex items-center gap-16">
        <img
            *ngIf="imageSrc()"
            alt="hint image"
            class="size-80 shrink-0"
            [src]="imageSrc()"
        />

        <div class="min-w-0 grow gap-y-4 break-words">
            @if (tip.title) {
                <h6
                    class="typo-medium-bold mb-4"
                    [innerHTML]="
                        tip.title | translate: tip.translateParams | safeHtml
                    "
                ></h6>
            }
            @if (tip.message | apply: asString; as message) {
                <p
                    class="typo-medium whitespace-pre-line"
                    [innerHtml]="message | translate: tip.translateParams"
                ></p>
            } @else {
                @if (tip.message | apply: asDelta; as deltaMessage) {
                    <interacta-delta-view
                        class="typo-medium text-text min-w-0 whitespace-pre-line break-all"
                        [delta]="deltaMessage"
                    />
                }
            }

            @if (tip.actionCallbacks.length) {
                <div class="mt-4 flex flex-wrap gap-x-16 gap-y-4">
                    @for (actionCallback of tip.actionCallbacks; track $index) {
                        <button
                            class="text-text-primary typo-sm-bold-uppercase hover:bg-surface-B hover:rounded-4 h-36 overflow-hidden whitespace-nowrap px-4 py-8"
                            type="button"
                            (click)="actionClicked.emit(actionCallback)"
                        >
                            {{ actionCallback.action.label | translate }}
                        </button>
                    }
                </div>
            }
        </div>
        <button
            *ngIf="!tip.hideCloseButton"
            class="shrink-0"
            interacta-icon-button
            type="button"
            [attr.aria-label]="
                'SHARED.TIPS.SYSTEM.ICON_CLOSE_ACCESSIBILITY' | translate
            "
            [bgColor]="'transparent'"
            [icon]="'close'"
            (click)="closeTip.emit()"
        ></button>
    </div>
}
