import { Injectable } from '@angular/core';
import { uuid } from '@interacta-shared/util';
import * as postApiActions from '@modules/communities/store/post/post-api.actions';
import { groupBy } from '@modules/core/helpers/generic.utils';
import { IAttachmentListEdit } from '@modules/post/models/attachment/attachment.model';
import { PostService } from '@modules/post/services/post.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import {
    bufferTime,
    catchError,
    concatMap,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { UploadActions } from '.';
import { UploadState } from './upload.reducer';
import { getUploadFeature, selectHashtagsToAttach } from './upload.selector';

@Injectable()
export class UploadEffects {
    constructor(
        private actions: Actions,
        private store: Store<UploadState>,
        private postService: PostService,
    ) {}

    readonly UPLOAD_BUFFER_TIME = 2000;

    attachmentLoadedSuccess$ = createEffect(() =>
        this.actions.pipe(
            ofType(UploadActions.attachmentLoadedSuccess),
            map(({ attachment }) => {
                if (!attachment.post) {
                    throw new Error('attachment.post must be defined');
                }
                return UploadActions.saveAttachment({
                    attachment,
                    postId: attachment.post.id,
                });
            }),
        ),
    );

    saveAttachment$ = createEffect(() =>
        this.actions.pipe(
            ofType(UploadActions.saveAttachment),
            bufferTime(this.UPLOAD_BUFFER_TIME),
            filter(
                (attachmentsByPost) =>
                    attachmentsByPost && attachmentsByPost.length > 0,
            ),
            map((attachmentsByPost) =>
                groupBy(
                    attachmentsByPost,
                    (item) =>
                        item.attachment.attachmentsUploadSessionId ?? uuid(),
                ),
            ),
            concatMap((groups) =>
                from(Object.entries(groups)).pipe(
                    withLatestFrom(this.store.select(selectHashtagsToAttach)),
                    map(([[attachmentsUploadSessionId, atts], hashtags]) => {
                        const addAttachments = atts.map((i) => {
                            return {
                                ...i.attachment,
                                ...(hashtags.length > 0 ? { hashtags } : {}),
                            };
                        });
                        const postId = atts[0].postId;
                        const attachmentListEdit: IAttachmentListEdit = {
                            addAttachments,
                            updateAttachments: [],
                            removeAttachmentIds: [],
                            attachmentsUploadSessionId:
                                attachmentsUploadSessionId,
                        };

                        return {
                            postId: +postId,
                            attachments: attachmentListEdit,
                        };
                    }),
                    mergeMap(({ attachments, postId }) =>
                        this.postService
                            .editPostAttachments(attachments, postId)
                            .pipe(
                                map(({ addAttachments, nextOccToken }) =>
                                    postApiActions.addAttachmentsSuccess({
                                        postId,
                                        attachments: addAttachments,
                                        nextOccToken,
                                        uploadSessionId:
                                            attachments.attachmentsUploadSessionId,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        postApiActions.addAttachmentsError({
                                            postId,
                                            attachments:
                                                attachments.addAttachments,
                                            error,
                                            uploadSessionId:
                                                attachments.attachmentsUploadSessionId,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    markPostAsTouced$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(
                    postApiActions.addAttachmentsSuccess,
                    postApiActions.addAttachmentsError,
                ),
                withLatestFrom(this.store.select(getUploadFeature)),
                filter(([action, state]) => {
                    if (!action.uploadSessionId) {
                        return false;
                    }
                    const sessionCountMap =
                        state.sessionAttachmentsCounterMap[
                            action.uploadSessionId
                        ];
                    return !!(
                        sessionCountMap &&
                        sessionCountMap.remainingAttachments === 0 &&
                        sessionCountMap.saveAttachmentsApiCalls > 1
                    );
                }),
                switchMap(([action, _]) =>
                    this.postService.markPostAsTouched(action.postId),
                ),
            ),
        { dispatch: false },
    );
}
