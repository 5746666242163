import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { formatDistanceToNow } from 'date-fns';

/**
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 */
@Pipe({
    name: 'notificationDate',
    standalone: true,
})
export class NotificationDatePipe implements PipeTransform {
    locale!: Locale;

    constructor(private configurationService: ConfigurationService) {
        this.configurationService
            .getLocaleDateFns()
            .subscribe((locale) => (this.locale = locale));
    }

    transform(date: Date): string {
        return formatDistanceToNow(date, {
            addSuffix: true,
            locale: this.locale,
        });
    }
}
