import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '@interacta-shared/data-access-auth';
import { adminV2UserOverview } from '@modules/admin-v2-routing/admin-v2-routes';
import { adminV2User, profile } from '@modules/app-routing/routes';
import { PartialSharedProfile } from '@modules/core/models/user.model';
import { PeopleField } from '@modules/people-dialog/models/people-dialog.model';
import { ProfilePreviewDialogStateService } from '@modules/state/services/profile-preview-dialog-state.service';

@Component({
    selector: 'interacta-people-dialog',
    templateUrl: './people-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleDialogComponent {
    private profilePreviewDialogStateService = inject(
        ProfilePreviewDialogStateService,
    );
    private authService = inject(AuthService);

    data = toSignal(this.profilePreviewDialogStateService.state.data);
    user = computed(() => this.data()?.user);
    items = computed(() => this.buildItems(this.user()));
    userIsCurrentUser = computed(
        () => this.user()?.id === this.authService.currentUserData()?.id,
    );
    canViewProfile = computed(
        () =>
            !this.user()?.privateProfile &&
            this.authService.currentUserData()?.userSettings?.viewUserProfiles,
    );
    isAdmin = computed(() => this.data()?.isAdmin ?? false);

    viewContent = computed(() => {
        const user = this.user();
        return (
            user &&
            !user.deleted &&
            (this.isAdmin() ||
                this.userIsCurrentUser() ||
                this.canViewProfile())
        );
    });

    profileLink = computed(() => {
        const id = this.user()?.id;
        if (!id) return null;
        return this.isAdmin()
            ? [adminV2User, id, adminV2UserOverview].join('/')
            : `/${profile}/${id}`;
    });

    private buildItems(user?: PartialSharedProfile): PeopleField[] {
        let items: PeopleField[] = [
            {
                label: 'PEOPLE.EMAIL',
                value: user?.contactEmail ?? '---',
                type: user?.contactEmail ? 'email' : 'string',
            },
            {
                label: 'PEOPLE.TELEPHONE',
                value: user?.phone !== '' && user?.phone ? user.phone : '---',
                type: 'string',
            },
        ];
        if (user?.internalPhone) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.EXTENSION_NUMBER',
                    value: user.internalPhone,
                    type: 'string',
                },
            ];
        }
        if (user?.mobilePhone) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.MOBILE',
                    value: user.mobilePhone,
                    type: 'string',
                },
            ];
        }
        if (user?.place) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.PLACE',
                    value: user.place,
                    type: 'string',
                },
            ];
        }
        if (user?.area?.name) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.AREA',
                    value: user.area.name,
                    type: 'string',
                },
            ];
        }
        if (user?.businessUnit?.name) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.BUSINESS_UNIT',
                    value: user.businessUnit.name,
                    type: 'string',
                },
            ];
        }

        return items;
    }

    close(): void {
        this.profilePreviewDialogStateService.close();
    }

    toggleFollow(): void {
        this.profilePreviewDialogStateService.toggleFollow();
    }
}
