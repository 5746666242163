import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ArrayPipe, ImageComponent } from '@interacta-shared/ui';
import {
    LoadingLayerService,
    LoadingLayerState,
} from '@interacta-shared/util-common';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    standalone: true,
    imports: [CommonModule, ImageComponent, TranslateModule, ArrayPipe],
    selector: 'interacta-loading-layer',
    templateUrl: './loading-layer.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingLayerComponent implements OnInit {
    loadingLayerState$!: Observable<LoadingLayerState>;
    @Input({ required: true }) src!: string;

    constructor(private loadingLayerService: LoadingLayerService) {}

    ngOnInit(): void {
        this.loadingLayerState$ = this.loadingLayerService.getState();
    }

    points$ = timer(0, 1000).pipe(map((t) => t % 4));

    showSecondLabel$ = timer(6000).pipe(map(() => true));
}
