import {
    CommandMessage,
    CommandType,
    CurrentChatStatusUpdatedEvent,
    EventMessage,
    EventType,
    OpenLinkCommand,
    OpenListChatCommand,
    RequestChatVisibilityCommand,
    UnreadChatStatusUpdatedEvent,
    VersionUpdateFoundEvent,
} from '../models/chat.models';

export function isUpdateUnreadChatStatusEvent(
    event: EventMessage<EventType, unknown>,
): event is UnreadChatStatusUpdatedEvent {
    return event.event === 'unread-chat-status-updated';
}

export function isCurrentChatStatusUpdatedEvent(
    event: EventMessage<EventType, unknown>,
): event is CurrentChatStatusUpdatedEvent {
    return event.event === 'current-chat-status-updated';
}

export function isVersionUpdateFoundEvent(
    event: EventMessage<EventType, unknown>,
): event is VersionUpdateFoundEvent {
    return event.event === 'version-update-found';
}

export function isOpenChatListCommand(
    command: CommandMessage<CommandType, unknown>,
): command is OpenListChatCommand {
    return command.command === 'open-chat-list';
}

export function isOpenLinkCommand(
    command: CommandMessage<CommandType, unknown>,
): command is OpenLinkCommand {
    return command.command === 'open-link';
}

export function isRequestChatVisibilityCommand(
    command: CommandMessage<CommandType, unknown>,
): command is RequestChatVisibilityCommand {
    return command.command === 'request-chat-visibility';
}
