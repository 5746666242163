import { ICarousel, ISlide } from './carousel.model';

export const toSlides = (record: any): ISlide => ({
    title: record.title,
    titleColor: record.titleColor,
    description: record.description,
    descriptionColor: record.descriptionColor,
    descriptionFontSize: record.descriptionFontSize,
    urlImage: record.urlImage,
    colorBegin: record.colorBegin,
    colorEnd: record.colorEnd,
    orientation_WebApp: record.orientation_WebApp ?? 'row',
});

export const toCarousel = (record: any): ICarousel => ({
    slides: record.slides.map(toSlides),
    config: {
        nameSkipBtn: record.nameSkipBtn,
        colorNameSkipBtn: record.colorNameSkipBtn,
        nameDoneBtn: record.nameDoneBtn,
        colorNameDoneBtn: record.colorNameDoneBtn,
        nameNextBtn: record.nameNextBtn,
        colorNameNextBtn: record.colorNameNextBtn,
        colorDot: record.colorDot,
        descriptionFontSize_WebApp: record.descriptionFontSize_WebApp,
    },
});
