<button
    class="flex w-full items-start"
    type="button"
    (click)="openNotificationDetails()"
>
    @if (notification.authorUsers.length) {
        @if (notification.authorUsers.length === 1) {
            <interacta-avatar [user]="notification.authorUsers[0]" />
        } @else {
            <div class="relative h-full w-40 shrink-0">
                <interacta-avatar
                    [size]="'extra-small'"
                    [user]="notification.authorUsers[0]"
                />
                <interacta-avatar
                    class="absolute right-0 top-12"
                    [size]="'extra-small'"
                    [user]="notification.authorUsers[1]"
                />
            </div>
        }
    }

    <div class="ml-16 mr-12 flex h-full min-w-0 grow flex-col">
        <div
            class="typo-sm mb-4 line-clamp-3 text-ellipsis text-left"
            [innerHTML]="notificationText"
            [ngClass]="{
                'text-text': !notification.read,
                'text-text-mid-contrast': notification.read,
            }"
        ></div>
        <div class="flex items-center gap-x-16">
            <div
                *ngIf="notification.timestamp"
                class="typo-xs-bold shrink-0"
                [matTooltip]="notification.timestamp | dateTooltip"
                [ngClass]="{
                    'text-text-primary': !notification.read,
                    'text-text-mid-contrast': notification.read,
                }"
            >
                {{ notification.timestamp | notificationDate }}
            </div>
            <span
                *ngIf="notification.community"
                class="typo-xs-bold-uppercase line-clamp-1 break-words text-left"
                [ngClass]="{
                    'text-text-low-contrast': !notification.read,
                    'text-text-mid-contrast': notification.read,
                }"
            >
                {{ notification.community.name | currentLanguage }}</span
            >
        </div>
        <div
            *ngIf="
                TaskEventTypes.includes(notification.typeId) &&
                notification.task &&
                notification.task.expiration &&
                notification.task.expiration.zonedDatetime
            "
            class="typo-xs bg-surface-B mt-8 h-24 w-max rounded-3xl px-8 py-4"
            matTooltipPosition="above"
            [matTooltip]="
                notification.task.expiration.zonedDatetime | dateTooltip
            "
        >
            <span>
                {{
                    notification.task.expiration.zonedDatetime
                        | taskExpirationDate
                }}
            </span>
        </div>
        <div *ngIf="eventStartAt" class="mt-8 flex gap-x-4">
            <interacta-icon class="size-20" [icon]="'clock'" />
            <div class="typo-sm flex items-center">
                <interacta-zoned-date
                    [allDay]="eventAllDay"
                    [date]="eventStartAt"
                />
            </div>
        </div>

        @switch (notification.typeId) {
            @case (EventType.CHANGE_STATUS) {
                <div class="mt-8 flex items-center gap-x-4">
                    <interacta-workflow-state-badge
                        *ngIf="fromState"
                        [color]="fromState.metadata.styleColor"
                        [colorCode]="fromState.metadata.styleColorCode"
                        [size]="'small'"
                        [workflowStateName]="fromState.name"
                    />
                    <interacta-icon class="size-20" [icon]="'arrow-right'" />
                    @if (notification.aggregated) {
                        <interacta-icon
                            class="size-20"
                            [icon]="'ellipsis-horizontal'"
                        />
                        <interacta-icon
                            class="size-20"
                            [icon]="'arrow-right'"
                        />
                    }
                    <interacta-workflow-state-badge
                        *ngIf="toState"
                        [color]="toState.metadata.styleColor"
                        [colorCode]="toState.metadata.styleColorCode"
                        [size]="'small'"
                        [workflowStateName]="toState.name"
                    />
                </div>
            }

            @case (EventType.FEEDBACK_TASK_CHANGE_STATUS) {
                <div class="mt-8 flex items-center space-x-4">
                    <interacta-workflow-state-badge
                        *ngIf="feedbackTaskFromStateStyle"
                        [colorClass]="feedbackTaskFromStateStyle.background"
                        [rounded]="'leaf'"
                        [size]="'small'"
                        [workflowStateName]="
                            feedbackTaskFromStateStyle.label | translate
                        "
                    />
                    <interacta-icon class="size-20" [icon]="'arrow-right'" />
                    <interacta-workflow-state-badge
                        *ngIf="feedbackTaskToStateStyle"
                        [colorClass]="feedbackTaskToStateStyle.background"
                        [rounded]="'leaf'"
                        [size]="'small'"
                        [workflowStateName]="
                            feedbackTaskToStateStyle.label | translate
                        "
                    />
                </div>
            }

            @case (EventType.EDITED_POST_ATTACHMENTS) {
                <div class="mt-8 flex space-x-4">
                    <interacta-attachment-chip
                        *ngIf="attachment"
                        class="min-w-0"
                        [attachment]="attachment"
                        [bgColor]="'surface-B'"
                        [slim]="true"
                        (click)="clickOnAttachment($event)"
                    />
                    <button
                        *ngIf="otherAttachments > 0"
                        class="typo-xs-bold bg-surface-B shrink-0 rounded-full px-8 py-4"
                        type="button"
                        (click)="clickOnAttachment($event)"
                    >
                        + {{ otherAttachments }}
                    </button>
                </div>
            }

            @case (
                ActivityPostEventTypes.includes(notification.typeId)
                    ? notification.typeId
                    : null
            ) {
                @if (activityNotificationPostData) {
                    @if (notification.typeId === EventType.ACTIVITY_EDIT) {
                        @if (notification.postDataChangedValues) {
                            <div class="mt-8 flex flex-col gap-y-4">
                                @if (
                                    notification.postDataChangedValues.assignee
                                        ?.newValue !==
                                    notification.postDataChangedValues.assignee
                                        ?.oldValue
                                ) {
                                    <div class="flex items-center gap-x-4">
                                        @let oldValueAssignee =
                                            notification.postDataChangedValues
                                                .assignee?.oldValue ??
                                            emptyMemberPlaceholder;
                                        @let newValueAssignee =
                                            notification.postDataChangedValues
                                                .assignee?.newValue ??
                                            emptyMemberPlaceholder;

                                        <interacta-member
                                            [compactUserName]="
                                                oldValueAssignee.innerId !==
                                                emptyMemberPlaceholder.innerId
                                            "
                                            [fontWeight]="'regular'"
                                            [member]="
                                                oldValueAssignee
                                                    | apply: markAsDeleted
                                            "
                                            [memberClickBehavior]="'none'"
                                            [size]="'extra-small'"
                                        />

                                        <interacta-icon
                                            class="size-16"
                                            [icon]="'arrow-right'"
                                        />

                                        <interacta-member
                                            [compactUserName]="
                                                newValueAssignee.innerId !==
                                                emptyMemberPlaceholder.innerId
                                            "
                                            [fontWeight]="'regular'"
                                            [member]="newValueAssignee"
                                            [memberClickBehavior]="'none'"
                                            [size]="'extra-small'"
                                        />
                                    </div>
                                }

                                @if (
                                    notification.postDataChangedValues
                                        .expectedStartDate ||
                                    notification.postDataChangedValues
                                        .expectedEndDate
                                ) {
                                    <div class="flex items-center gap-x-4">
                                        @let expectedStart =
                                            notification.postDataChangedValues
                                                .expectedStartDate;

                                        @let expectedEnd =
                                            notification.postDataChangedValues
                                                .expectedEndDate;

                                        @let oldVisible =
                                            !!expectedStart?.oldValue ||
                                            !!expectedEnd?.oldValue;
                                        @let newVisible =
                                            !!expectedStart?.newValue ||
                                            !!expectedEnd?.newValue;

                                        @if (oldVisible) {
                                            <interacta-activity-date-container
                                                [activity]="{
                                                    expectedStart:
                                                        expectedStart?.oldValue ??
                                                        undefined,
                                                    expectedEnd:
                                                        expectedEnd?.oldValue ??
                                                        undefined,
                                                }"
                                                [lineThrough]="true"
                                            />
                                        }

                                        @if (oldVisible && newVisible) {
                                            <interacta-icon
                                                class="size-16"
                                                [icon]="'arrow-right'"
                                            />
                                        }

                                        @if (newVisible) {
                                            <interacta-activity-date-container
                                                [activity]="{
                                                    expectedStart:
                                                        expectedStart?.newValue ??
                                                        undefined,
                                                    expectedEnd:
                                                        expectedEnd?.newValue ??
                                                        undefined,
                                                }"
                                            />
                                        }
                                    </div>
                                }
                            </div>
                        }
                    } @else {
                        <div
                            class="mt-8 flex flex-wrap items-center gap-x-12 gap-y-4"
                        >
                            @let assignee =
                                activityNotificationPostData.assignee ??
                                emptyMemberPlaceholder;
                            <interacta-member
                                [compactUserName]="
                                    assignee.innerId !==
                                    emptyMemberPlaceholder.innerId
                                "
                                [fontWeight]="'regular'"
                                [member]="assignee"
                                [memberClickBehavior]="'none'"
                                [size]="'extra-small'"
                            />

                            @if (
                                activityNotificationPostData.expectedStart ||
                                activityNotificationPostData.expectedEnd
                            ) {
                                <interacta-activity-date-container
                                    [activity]="activityNotificationPostData"
                                />
                            }

                            @if (
                                activityNotificationPostData.priority != null
                            ) {
                                <interacta-priority
                                    [fullLabel]="false"
                                    [priority]="
                                        activityNotificationPostData.priority
                                    "
                                    [size]="'extra-small'"
                                />
                            }
                        </div>
                    }
                }
            }
        }
    </div>
</button>
@if (!notification.read) {
    <button
        class="bg-surface-primary absolute right-0 top-0 size-12 shrink-0 rounded-full"
        type="button"
        [attr.aria-label]="'SHARED.NOTIFICATIONS.MARK_AS_READ' | translate"
        [matTooltip]="'SHARED.NOTIFICATIONS.MARK_AS_READ' | translate"
        (click)="$event.stopPropagation(); markAsRead.emit()"
    ></button>
}
