import { getRandomNumber } from '@interacta-shared/util';
import { Delta } from 'quill/core';
import {
    ProTipData,
    ProTipImage,
    SystemTipData,
    TipData,
    TipSeverity,
} from './tip.model';

const PRO_TIP_IMAGES: ProTipImage[] = ['reading', 'gardening'];

export function isProTip<A, P>(tip: TipData | null): tip is ProTipData<A, P> {
    return tip?.severity === TipSeverity.PRO;
}

export function isSimpleTip(tip: TipData | null): tip is TipData {
    return (
        tip != null &&
        ![TipSeverity.PRO, TipSeverity.SYSTEM].includes(tip.severity)
    );
}

export function isSystemTip<A, P>(
    tip: TipData | null,
): tip is SystemTipData<A, P> {
    return tip?.severity === TipSeverity.SYSTEM;
}

export function getRandomProTipImage(): ProTipImage {
    const idx = getRandomNumber(0, PRO_TIP_IMAGES.length);
    return PRO_TIP_IMAGES[idx];
}

export function asString(message: TipData['message']): string | undefined {
    return typeof message === 'string' ? message : undefined;
}

export function asDelta(message: TipData['message']): Delta | undefined {
    return typeof message === 'string' ? undefined : message;
}
