import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@interacta-shared/data-access-common';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { TipService } from '@interacta-shared/feature-tip';
import { tap } from 'rxjs';

/**
 * corresponding to SystemMessageDTO
 * @property {string} [validityStartDate] not used now
 * @property {string} [validityEndDate] not used now
 * @property {Record<string, unknown>} [metadata] not used now
 */
interface SystemMessage {
    id: number;
    text: string;
    validityStartDate: string;
    validityEndDate: string;
    metadata: Record<string, unknown>;
}

const SYSTEM_MESSAGES_KEY = 'system-messages';
@Injectable({ providedIn: 'root' })
export class SystemMessageService {
    private readonly tipService = inject(TipService);
    private readonly httpClient = inject(HttpClient);
    private readonly localStorageService = inject(LocalStorageService);

    private baseUrlSystemMessage = `${inject(ENVIRONMENT).apiBasePath.common}/core/settings/messages`;
    private messages: SystemMessage[] = [];

    fetchMessages(): void {
        this.httpClient
            .get<{
                items: SystemMessage[];
            }>(this.baseUrlSystemMessage, { params: { clientType: 'web' } })
            .pipe(tap(({ items }) => (this.messages = items)))
            .subscribe(() => this.findMessageToShow());
    }

    private findMessageToShow(): void {
        const readMessagesFromStorage = this.getReadMessagesFromStorage();
        const itemToShow = this.messages.find(
            (m) =>
                !readMessagesFromStorage ||
                !readMessagesFromStorage.includes(m.id),
        );

        if (itemToShow) {
            this.openTip(itemToShow);
        }
    }

    private openTip(itemToShow: SystemMessage): void {
        this.tipService
            .openSystemTip({
                message: itemToShow.text,
                title: 'SHARED.TIPS.SYSTEM.TITLE',
                tipActions: [
                    {
                        id: 'confirm',
                        label: 'SHARED.TIPS.UNDERSTOOD',
                        data: itemToShow,
                    },
                ],
            })
            .subscribe(() => {
                this.setMessageAsRead(itemToShow.id);
                this.findMessageToShow();
            });
    }

    private getReadMessagesFromStorage(): SystemMessage['id'][] {
        return (
            this.localStorageService.getEntry<SystemMessage['id'][]>(
                SYSTEM_MESSAGES_KEY,
            ) || []
        );
    }

    private setMessageAsRead(id: SystemMessage['id']): void {
        this.localStorageService.setEntry(SYSTEM_MESSAGES_KEY, [
            ...this.getReadMessagesFromStorage(),
            id,
        ]);
    }
}
