import {
    EnvironmentProviders,
    ErrorHandler,
    Type,
    makeEnvironmentProviders,
} from '@angular/core';
import { DefaultErrorDisplayService } from '../services/default-error-diplay.service';
import { ErrorDisplayService } from '../services/error-display.abstract-service';
import { InteractaErrorHandler } from '../services/error.handler';

export function provideErrorHandler(
    existing?: Type<ErrorDisplayService>,
): EnvironmentProviders {
    const deps = existing ? [existing] : [];
    return makeEnvironmentProviders([
        {
            provide: ErrorDisplayService,
            useFactory: (override?: ErrorDisplayService) =>
                override ?? new DefaultErrorDisplayService(),
            deps,
        },
        { provide: ErrorHandler, useClass: InteractaErrorHandler },
    ]);
}
