import { Injectable } from '@angular/core';
import { filterMap, isDefined } from '@interacta-shared/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import {
    concatMap,
    delay,
    filter,
    map,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import {
    ProTipData,
    TipData,
    TipSeverity,
    tipDefaultDuration,
} from '../models/tip.model';
import { getRandomProTipImage, isProTip } from '../models/tip.utils';
import * as actions from './tip.actions';
import { selectCurrentTip } from './tip.selectors';

@Injectable()
export class TipsEffects {
    success$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.success),
            withLatestFrom(this.store.select(selectCurrentTip)),
            filter(([_, currentTip]) =>
                this.couldReplaceCurrentTip(TipSeverity.SUCCESS, currentTip),
            ),
            map(([{ message, translateParams, closeBehavior }, _]) =>
                actions.setCurrentTip({
                    currentTip: {
                        severity: TipSeverity.SUCCESS,
                        message,
                        closeBehavior: closeBehavior ?? 'duration',
                        translateParams,
                    },
                }),
            ),
        ),
    );

    info$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.info),
            withLatestFrom(this.store.select(selectCurrentTip)),
            filter(([_, currentTip]) =>
                this.couldReplaceCurrentTip(TipSeverity.INFO, currentTip),
            ),
            map(([action, _]) =>
                actions.setCurrentTip({
                    currentTip: {
                        severity: TipSeverity.INFO,
                        message: action.message,
                        closeBehavior: action.closeBehavior ?? 'duration',
                        translateParams: action.translateParams,
                    },
                }),
            ),
        ),
    );

    warn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.warn),
            withLatestFrom(this.store.select(selectCurrentTip)),
            filter(([_, currentTip]) =>
                this.couldReplaceCurrentTip(TipSeverity.WARN, currentTip),
            ),
            map(([action, _]) =>
                actions.setCurrentTip({
                    currentTip: {
                        severity: TipSeverity.WARN,
                        message: action.message,
                        closeBehavior: action.closeBehavior ?? 'duration',
                        translateParams: action.translateParams,
                    },
                }),
            ),
        ),
    );

    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.error),
            withLatestFrom(this.store.select(selectCurrentTip)),
            filter(([_, currentTip]) =>
                this.couldReplaceCurrentTip(TipSeverity.ERROR, currentTip),
            ),
            map(([action, _]) =>
                actions.setCurrentTip({
                    currentTip: {
                        severity: TipSeverity.ERROR,
                        message: action.message,
                        closeBehavior: action.closeBehavior ?? 'duration',
                        translateParams: action.translateParams,
                    },
                }),
            ),
        ),
    );

    openProTip$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.openProTip),
            withLatestFrom(this.store.select(selectCurrentTip)),
            filter(([_, currentTip]) =>
                this.couldReplaceCurrentTip(TipSeverity.PRO, currentTip),
            ),
            map(([action, _]) => {
                const currentTip: ProTipData<unknown, unknown> = {
                    severity: TipSeverity.PRO,
                    ...action,
                    title: action.title,
                    closeBehavior: action.closeBehavior ?? 'duration',
                    translateParams: action.translateParams,
                    image: action.image ?? getRandomProTipImage(),
                    actionCallbacks: action.actionCallbacks ?? [],
                    closeCallback: action.closeCallback,
                    payload: action.payload,
                    hideCloseButton: action.hideCloseButton ?? false,
                    direction: action.direction ?? 'vertical',
                };

                return actions.setCurrentTip({
                    currentTip,
                });
            }),
        ),
    );

    duration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.setCurrentTip),
            switchMap(({ currentTip }) =>
                currentTip?.closeBehavior === 'duration'
                    ? of(actions.closeCurrentTip()).pipe(
                          delay(tipDefaultDuration),
                      )
                    : EMPTY,
            ),
        ),
    );

    closeCurrentProTipIf$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.closeCurrentProTip),
            withLatestFrom(this.store.select(selectCurrentTip)),
            filterMap(([{ condition }, currentTip]) =>
                isDefined(currentTip) && isProTip(currentTip)
                    ? { condition, currentTip }
                    : undefined,
            ),
            concatMap(({ condition, currentTip }) =>
                from(condition(currentTip.payload)),
            ),
            filter((close) => close),
            map(() => actions.closeCurrentTip()),
        ),
    );

    constructor(
        private store: Store,
        private actions$: Actions,
    ) {}

    private couldReplaceCurrentTip(
        severity: TipSeverity,
        currentTip: TipData | null,
    ): boolean {
        return (
            !currentTip ||
            currentTip.closeBehavior === 'duration' ||
            severity >= currentTip.severity
        );
    }
}
