import { NgModule } from '@angular/core';
import { DateTooltipPipe } from '@interacta-shared/data-access-common';
import { NotificationDatePipe } from '@interacta-shared/data-access-notification';
import {
    ApplyPipe,
    AvatarComponent,
    IconButtonComponent,
    LoadMoreComponent,
    PriorityComponent,
    SeparatorComponent,
    ToggleComponent,
} from '@interacta-shared/ui';
import { NoResultComponent } from '@interacta-shared/ui-common';
import { IconComponent } from '@interacta-shared/ui-icons';
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { NotificationRowComponent } from './components/notification-row/notification-row.component';
import { NotificationsPanelComponent } from './components/notifications-panel/notifications-panel.component';

const standaloneComponents = [
    IconComponent,
    AvatarComponent,
    LoadMoreComponent,
    NoResultComponent,
    SeparatorComponent,
    ToggleComponent,
    IconButtonComponent,
    PriorityComponent,
];

const standalonePipes = [ApplyPipe, NotificationDatePipe, DateTooltipPipe];

@NgModule({
    imports: [
        SharedV2Module,
        AttachmentsModule,
        ...standaloneComponents,
        ...standalonePipes,
    ],
    exports: [NotificationsPanelComponent],
    declarations: [NotificationsPanelComponent, NotificationRowComponent],
})
export class NotificationsModule {}
