import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@interacta-shared/data-access-common';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { IStateService } from '@modules/state/services/istate-service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { toCarousel } from '../../carousel/models/carousel/carousel.deserialize';
import { ICarousel } from '../../carousel/models/carousel/carousel.model';

@Injectable({ providedIn: 'root' })
export class CarouselService implements IStateService<void> {
    private _carousel: BehaviorSubject<ICarousel | null>;

    private readonly alreadySeen = 'alreadySeen';
    private baseUrlCommunication = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/settings`;

    state!: void;

    constructor(
        private http: HttpClient,
        protected localStorageService: LocalStorageService,
    ) {
        this._carousel = new BehaviorSubject<ICarousel | null>(null);
    }

    get getCarousel$(): Observable<ICarousel | null> {
        return this._carousel.asObservable();
    }

    private getSlides(): Observable<ICarousel> {
        return this.http
            .get<any>(`${this.baseUrlCommunication}/info-carousel`)
            .pipe(
                map((result) =>
                    toCarousel(JSON.parse(result.config as string)),
                ),
                catchError((error: any) => throwError(error)),
            );
    }

    private checkCarousel() {
        if (!this.localStorageService.getEntry(this.alreadySeen)) {
            this.getSlides().subscribe(
                (item) => this._carousel.next(item),
                (_) => {
                    this.localStorageService.setEntry(this.alreadySeen, false);
                    this._carousel.next(null);
                },
            );
        } else {
            this._carousel.next(null);
        }
    }

    markAsSeen(): void {
        this.localStorageService.setEntry(this.alreadySeen, true);
        this._carousel.next(null);
    }

    initialize(): void {
        this.checkCarousel();
    }

    flush(): void {
        this._carousel.next(null);
    }
}
