import { Injectable } from '@angular/core';
import { LocalStorageService } from '@interacta-shared/data-access-common';
import {
    InteractaBuildInfo,
    isNewBuild,
} from '@interacta-shared/data-access-configuration';
import { CarouselService } from '@modules/carousel/services/carousel.service';
import { FilterCommunitiesService } from '@modules/communities/services/filter-communities.service';
import { GoogleAPIService } from '@modules/core/services/google-api.service';
import { AppActions } from '@modules/core/store';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { CatalogsStateService } from './catalogs-state.service';
import { CommunitiesStateService } from './communities-state.service';
import { ExternalLinksStateService } from './external-links-state.service';
import { GalleryStateService } from './gallery-state.service';
import { IStateService } from './istate-service';
import { NotificationBellStateService } from './notification-bell-state.service';
import { ProfilePreviewDialogStateService } from './profile-preview-dialog-state.service';
import { UIStateService } from './ui-state.service';

const BUILD_INFO_KEY = 'buildInfo';

@Injectable({ providedIn: 'root' })
export class StateManagerService {
    private readonly stateServices: IStateService<any>[];

    constructor(
        galleryStateService: GalleryStateService,
        notificationBellStateService: NotificationBellStateService,
        uiStateService: UIStateService,
        googleAPIService: GoogleAPIService,
        carouselService: CarouselService,
        externalLinksStateService: ExternalLinksStateService,
        profilePreviewDialogStateService: ProfilePreviewDialogStateService,
        catalogsStateService: CatalogsStateService,
        private communitiesStateService: CommunitiesStateService,
        private filterCommunitiesService: FilterCommunitiesService,
        private localStorageService: LocalStorageService,
        private store: Store,
    ) {
        this.stateServices = [
            galleryStateService,
            catalogsStateService,
            communitiesStateService,
            notificationBellStateService,
            uiStateService,
            googleAPIService,
            carouselService,
            externalLinksStateService,
            profilePreviewDialogStateService,
        ];
    }

    initialize(): void {
        for (const state of this.stateServices) {
            state.initialize();
        }
    }

    flush(): void {
        for (const state of this.stateServices) {
            state.flush();
        }
        this.filterCommunitiesService.flush();
        this.store.dispatch(AppActions.flush());
    }

    /**
     * Match current build-info (FE and BE ) agaist cached ones.
     * If a new build is detected, version-aware cache data are deleted.
     * Current build-info are then stored in cache.
     *
     * @param currentBuildInfo
     */
    validateCache(currentBuildInfo: InteractaBuildInfo): Observable<void> {
        const cachedBuildInfo: InteractaBuildInfo | undefined =
            this.localStorageService.getEntry<InteractaBuildInfo>(
                BUILD_INFO_KEY,
                false,
            );
        this.localStorageService.setEntry(
            BUILD_INFO_KEY,
            currentBuildInfo,
            false,
        );

        if (cachedBuildInfo && isNewBuild(cachedBuildInfo, currentBuildInfo)) {
            console.log('A new version as been detected: do clear local cache');
            return this.communitiesStateService.clearCommunitiesCache(true);
        } else {
            return of(void 0);
        }
    }
}
