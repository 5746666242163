import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { closeDetail } from '@modules/table-info/store/table-info.actions';
import {
    selectTable,
    selectVisible,
} from '@modules/table-info/store/table-info.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'interacta-table-info-dialog',
    templateUrl: './table-info-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableInfoDialogComponent {
    visible$: Observable<boolean>;
    table$: Observable<QuillTable | undefined>;

    constructor(private store: Store) {
        this.table$ = store.select(selectTable);
        this.visible$ = store.select(selectVisible);
    }

    close(): void {
        this.store.dispatch(closeDetail());
    }
}
