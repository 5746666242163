import { Injectable, inject } from '@angular/core';
import { ErrorService } from '@interacta-shared/data-access-error';
import { GlobalAdminCapabilitiesService } from '@modules/core/services/global-admin-capabilities.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import {
    fetchUserCapabilitiesError,
    fetchUserCapabilitiesSuccess,
} from './user-capabilities-api.actions';
import { fetchUserCapabilities } from './user-capabilities.actions';

@Injectable()
export class UserCapabilitiesEffects {
    private readonly actions$ = inject(Actions);
    private readonly globalAdminCapabilitiesService = inject(
        GlobalAdminCapabilitiesService,
    );
    private readonly errorService = inject(ErrorService);

    fetchUserCapabilities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchUserCapabilities),
            switchMap(() =>
                this.globalAdminCapabilitiesService
                    .getCurrentUserCapabilities()
                    .pipe(
                        map((capabilities) =>
                            fetchUserCapabilitiesSuccess({ capabilities }),
                        ),
                        catchError((error) =>
                            of(fetchUserCapabilitiesError({ error })),
                        ),
                    ),
            ),
        ),
    );

    errors$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fetchUserCapabilitiesError),
                tap((error) => this.errorService.handle(error)),
            ),
        { dispatch: false },
    );
}
