import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdvancedSearchQueryBuilder } from '@modules/header/models/advanced-search/advanced-search.model';
import { advancedSearchQueryBuilder } from '@modules/header/models/advanced-search/advanced-search.utils';

@Component({
    selector: 'interacta-advanced-search-panel',
    templateUrl: './advanced-search-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearchPanelComponent {
    @Input({ required: true }) formGroup!: FormGroup<{
        some: FormControl<string | null>;
        exactly: FormControl<string | null>;
        every: FormControl<string | null>;
        none: FormControl<string | null>;
    }>;

    @Output() closePanel = new EventEmitter<void>();
    @Output() search = new EventEmitter<AdvancedSearchQueryBuilder>();

    doSearch(): void {
        this.search.emit(advancedSearchQueryBuilder(this.formGroup.value));
    }
}
