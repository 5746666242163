<interacta-dialog
    [isOpen]="data()?.isOpen ?? false"
    [size]="'regular'"
    (closing)="close()"
>
    @let _user = user();

    <ng-content title>
        @if (data()?.isFetching) {
            <div class="h-200 m-auto flex items-center">
                <interacta-loader [size]="'large'" />
            </div>
        } @else if (_user) {
            <div
                class="flex gap-x-24"
                title
                [ngClass]="{
                    'items-center': !canViewProfile(),
                }"
            >
                <interacta-avatar [size]="'large'" [user]="_user" />
                <div class="flex flex-col">
                    <span class="typo-t5 line-clamp-2">
                        {{ _user.fullName }}
                    </span>
                    @if (_user.caption) {
                        <span class="typo-sm"> {{ _user.caption }} </span>
                    }
                    @if (_user.role) {
                        <span class="typo-sm">{{ _user.role }}</span>
                    }

                    @if (_user.deleted) {
                        <div
                            class="typo-sm text-text-primary flex items-center gap-4"
                        >
                            <interacta-icon class="size-24" [icon]="'ban'" />
                            <span>{{
                                (_user.blocked
                                    ? 'PROFILE.USER_BLOCKED'
                                    : 'PROFILE.USER_DELETED'
                                ) | translate
                            }}</span>
                        </div>
                    }

                    @if (isAdmin() || userIsCurrentUser() || canViewProfile()) {
                        <div class="gap-x-18 mt-20 flex">
                            <button
                                *ngIf="
                                    !userIsCurrentUser() &&
                                    !_user.deleted &&
                                    !isAdmin()
                                "
                                interacta-button
                                type="button"
                                [active]="_user.followedByMe ?? false"
                                [appearance]="
                                    _user.followedByMe ? 'border' : 'fill'
                                "
                                [icon]="'bell'"
                                [label]="
                                    (_user.followedByMe
                                        ? 'PEOPLE.ALREADY_FOLLOWING'
                                        : 'PEOPLE.FOLLOW'
                                    ) | translate
                                "
                                (click)="
                                    $event.stopPropagation(); toggleFollow()
                                "
                            ></button>
                            <a
                                interacta-button
                                type="button"
                                [appearance]="'border'"
                                [bgColor]="'gray-100'"
                                [label]="
                                    (isAdmin()
                                        ? 'PEOPLE.GO_TO_PROFILE'
                                        : 'PEOPLE.GO_TO_FULL_PROFILE'
                                    ) | translate
                                "
                                [routerLink]="profileLink()"
                            ></a>
                        </div>
                    }
                </div>
            </div>
        }
    </ng-content>

    @if (viewContent() && _user) {
        <div class="flex flex-col" content>
            @if (_user.biography) {
                <span class="typo-xs italic">{{ _user.biography }}</span>
            }
            <interacta-separator class="mb-4 mt-16" [thickness]="'strong'" />
            <interacta-people-details
                class="flex flex-col gap-y-8"
                [items]="items()"
            />
        </div>
    }
</interacta-dialog>
