import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@interacta-shared/data-access-auth';
import { PartialSharedProfile } from '@modules/core/models/user.model';
import {
    PeopleDialogData,
    PeopleField,
} from '@modules/people-dialog/models/people-dialog.model';
import { ProfilePreviewDialogStateService } from '@modules/state/services/profile-preview-dialog-state.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'interacta-people-dialog',
    templateUrl: './people-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleDialogComponent implements OnDestroy {
    data$!: Observable<PeopleDialogData>;

    private destroy$ = new Subject<void>();

    constructor(
        private profilePreviewDialogStateService: ProfilePreviewDialogStateService,
        private router: Router,
        authService: AuthService,
    ) {
        this.data$ = this.profilePreviewDialogStateService.state.data.pipe(
            takeUntil(this.destroy$),
            map((data) => ({
                ...data,
                currentUser: authService.getCurrentUserData(),
                items: this.buildItems(data.user),
            })),
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    goToProfile(id: number, isAdmin: boolean): void {
        if (isAdmin) this.router.navigate(['admin-v2/user', id, 'overview']);
        else this.router.navigate(['/profile', id]);
    }

    buildItems(user?: PartialSharedProfile): PeopleField[] {
        let items: PeopleField[] = [
            {
                label: 'PEOPLE.EMAIL',
                value: user?.contactEmail ?? '---',
                type: user?.contactEmail ? 'email' : 'string',
            },
            {
                label: 'PEOPLE.TELEPHONE',
                value: user?.phone !== '' && user?.phone ? user.phone : '---',
                type: 'string',
            },
        ];
        if (user?.internalPhone) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.EXTENSION_NUMBER',
                    value: user.internalPhone,
                    type: 'string',
                },
            ];
        }
        if (user?.mobilePhone) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.MOBILE',
                    value: user.mobilePhone,
                    type: 'string',
                },
            ];
        }
        if (user?.place) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.PLACE',
                    value: user.place,
                    type: 'string',
                },
            ];
        }
        if (user?.area?.name) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.AREA',
                    value: user.area.name,
                    type: 'string',
                },
            ];
        }
        if (user?.businessUnit?.name) {
            items = [
                ...items,
                {
                    label: 'PEOPLE.BUSINESS_UNIT',
                    value: user.businessUnit.name,
                    type: 'string',
                },
            ];
        }

        return items;
    }

    close(): void {
        this.profilePreviewDialogStateService.close();
    }

    toggleFollow(): void {
        this.profilePreviewDialogStateService.toggleFollow();
    }
}
