<header
    *ngIf="{
        currentLanguage: currentLanguage$ | async,
        isSidebarOpen: isSidebarOpen$ | async,
        unreadNotification: (unreadNotification$ | async) ?? 0,
        newNotificationToggle: newNotificationToggle$ | async,
        notifications: notifications$ | async,
        baseRoute: baseRoute$ | async,
        showSearchBar: showSearchBar$ | async,
        rootLinkClickable: rootLinkClickable$! | async,
        currentUserClickable: currentUserClickable$ | async,
        showNotifications: showNotifications$ | async,
        homeSelectorItems: homeSelectorItems$ | async,
        currentHomeId: currentHomeId$ | async,
        chatCanShow: chatCanShow$ | async,
        hasUnreadChatNotifications: hasUnreadChatNotifications$ | async,
    } as $"
    class="relative flex h-72 justify-between px-16 py-12 print:hidden"
>
    <div class="flex shrink-0 items-center gap-24">
        @if (iconMode === 'menu' && isMd()) {
            <button
                interacta-icon-button
                type="button"
                [attr.aria-label]="'SIDEBAR.TOGGLE_SIDEBAR' | translate"
                [icon]="'hamburger'"
                (click)="toggleMenu()"
            ></button>
        } @else if (iconMode === 'back') {
            <interacta-back />
        }

        @if (
            $.homeSelectorItems &&
            $.homeSelectorItems.length > 0 &&
            $.currentHomeId != null
        ) {
            <interacta-home-selector
                [currentHomeId]="$.currentHomeId"
                [selectableHomes]="$.homeSelectorItems"
                (changeCurrentHome)="changeCurrentHome($event)"
            />
        }
    </div>

    @if (customerLogoSrc && ($.currentHomeId == null || isMd())) {
        <a
            class="absolute top-1/2"
            [attr.aria-label]="'SIDEBAR.HOME' | translate"
            [routerLink]="$.rootLinkClickable ? ['/'] : null"
            [style.transform]="
                'translate(' +
                ($.currentHomeId != null ? 'calc( 50vw - 50%)' : '3.5rem') +
                ', -50%)'
            "
        >
            <interacta-image
                [alt]="'Customer\'s logo'"
                [classes]="'h-44 object-contain'"
                [fallback]="
                    currentTheme.mode === 'dark'
                        ? customerLogoSrc.light
                        : undefined
                "
                [loading]="'eager'"
                [src]="
                    currentTheme.mode === 'light'
                        ? customerLogoSrc.light
                        : customerLogoSrc.dark
                "
            />
        </a>
    }

    <div class="flex items-center gap-16">
        @if ($.showSearchBar) {
            <interacta-search />
        }

        <button
            interacta-icon-button
            type="button"
            [attr.aria-label]="
                'DIGITAL_WORKPLACE.OPEN_LINKS_DIALOG' | translate
            "
            [icon]="'apps'"
            [matTooltip]="'DIGITAL_WORKPLACE.OPEN_LINKS_DIALOG' | translate"
            (click)="openLinksDialog()"
        ></button>

        @if ($.chatCanShow) {
            <div class="relative flex">
                <button
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="'CHAT_EMBEDDED.OPEN_CHAT' | translate"
                    [icon]="'chat'"
                    [matTooltip]="'CHAT_EMBEDDED.OPEN_CHAT' | translate"
                    [matTooltipPosition]="'below'"
                    (click)="toggleChat()"
                ></button>
                @if ($.hasUnreadChatNotifications) {
                    <div
                        class="bg-surface-primary border-surface-A absolute right-0 top-2 size-12 rounded-full border-2"
                    ></div>
                }
            </div>
        }

        @if (user) {
            <button
                #openMenuBtn="cdkOverlayOrigin"
                cdkOverlayOrigin
                type="button"
                [attr.aria-expanded]="isUserMenuOpen"
                [attr.aria-label]="user.fullName"
                [disabled]="!$.currentUserClickable"
                (click)="isUserMenuOpen = true"
            >
                <interacta-avatar
                    [enableChristmasEasterEgg]="enableChristmasEasterEgg"
                    [ngClass]="{ relative: enableChristmasEasterEgg }"
                    [size]="'regular'"
                    [user]="{
                        id: user.id,
                        fullName: user.fullName,
                        accountPhotoUrl: user.accountPhotoUrl,
                    }"
                />
            </button>

            @if ($.currentLanguage) {
                <interacta-user-menu
                    class="absolute"
                    [attr.aria-expanded]="isUserMenuOpen"
                    [availableLanguages]="availableLanguages"
                    [currentLanguage]="$.currentLanguage"
                    [currentTheme]="currentTheme"
                    [currentUser]="user"
                    [isOpen]="isUserMenuOpen"
                    [origin]="openMenuBtn"
                    (changeLanguage)="changeLanguage.emit($event)"
                    (changeTheme)="changeTheme.emit($event)"
                    (closing)="isUserMenuOpen = false"
                    (logout)="logout.emit()"
                    (openNotificationsSettings)="
                        openNotificationsSettings.emit()
                    "
                    (openProfile)="openProfile.emit()"
                />
            }
        }

        @if ($.showNotifications) {
            <div class="relative flex">
                <button
                    class="origin-top"
                    interacta-icon-button
                    type="button"
                    [active]="$.unreadNotification > 0"
                    [attr.aria-label]="'SHARED.NOTIFICATIONS.OPEN' | translate"
                    [disabled]="
                        $.notifications?.nextPageToken?.tag ===
                            'firstLoading' && !$.notifications?.isFetching
                    "
                    [icon]="'bell'"
                    [ngClass]="{
                        'hover:animate-swing': $.unreadNotification,
                        'animate-swing':
                            $.unreadNotification && $.newNotificationToggle,
                    }"
                    (click)="openNotificationPanel()"
                ></button>
                <div
                    *ngIf="$.unreadNotification"
                    class="bg-surface-primary absolute right-0 top-4 size-8 rounded-full"
                ></div>
            </div>
        }
    </div>
</header>

<!-- print header -->
<header
    class="border-border-300 bg-text-white mb-24 hidden items-center justify-between border-b px-16 py-12 print:flex"
>
    @if (customerLogoSrc) {
        <interacta-image
            [alt]="''"
            [classes]="'h-44 object-contain'"
            [loading]="'eager'"
            [src]="customerLogoSrc.light"
        />
    }
    <interacta-image
        [alt]="'SHARED.PAGE_TITLE.DEFAULT' | translate"
        [classes]="'h-44'"
        [src]="'./assets/images/logo_interacta.png'"
    />
</header>
