<div
    class="px-84 flex h-full w-full flex-col items-center justify-end pb-64"
    [ngStyle]="{ 'background-color': '#' + slide.colorBegin }"
>
    <div
        class="flex grow items-center"
        [ngClass]="{
            'flex-col': slide.orientation_WebApp === 'column',
        }"
    >
        <div class="flex-1 text-center">
            <img
                alt="interacta_logo"
                class="max-h-4/5 max-w-4/5"
                src="{{ slide.urlImage }}"
            />
        </div>
        <div
            class="flex flex-1"
            [ngClass]="{
                'flex-col gap-y-60': slide.orientation_WebApp !== 'column',
            }"
        >
            <div
                class="typo-t2"
                [innerHTML]="slide.title"
                [ngStyle]="{
                    color: '#' + slide.descriptionColor,
                }"
            ></div>
            <span
                class="font-400"
                [innerHTML]="slide.description"
                [ngStyle]="{
                    'font-size': config.descriptionFontSize_WebApp + 'px',
                    'line-height': '130%',
                    color: '#' + slide.descriptionColor,
                }"
            ></span>
            <div *ngIf="index === totalItems - 1">
                <button
                    interacta-button
                    size="regular"
                    type="button"
                    [label]="config.nameDoneBtn"
                    (click)="closeCarousel.emit()"
                ></button>
            </div>
        </div>
    </div>
    <div class="gap-x-100 flex items-center">
        <button
            *ngIf="index !== totalItems - 1"
            interacta-button
            size="regular"
            type="button"
            [bgColor]="'pure-white-30'"
            [label]="config.nameSkipBtn"
            [ngStyle]="{ color: '#' + config.colorNameSkipBtn }"
            (click)="closeCarousel.emit()"
        ></button>
        <div class="flex items-center gap-x-24">
            <button
                *ngFor="let item of [].constructor(totalItems); let i = index"
                class="bg-surface-100 border-text h-12 w-12 rounded-full border-2"
                type="button"
                [ngClass]="{ 'bg-surface-A-reverse h-14 w-14': index === i }"
                [ngStyle]="{
                    background:
                        index === i
                            ? '#' + config.colorDot
                            : 'rgba(0, 0, 0, 0.2)',
                }"
                (click)="nextStep.emit(i)"
            ></button>
        </div>
        <button
            *ngIf="index !== totalItems - 1"
            interacta-button
            type="button"
            [bgColor]="'pure-white-30'"
            [label]="config.nameNextBtn"
            [ngStyle]="{ color: '#' + config.colorNameNextBtn }"
            [size]="'regular'"
            (click)="nextStep.emit(index + 1)"
        ></button>
    </div>
</div>
