import { Injectable } from '@angular/core';
import { ErrorService } from '@interacta-shared/data-access-error';
import { openProTip } from '@interacta-shared/feature-tip';
import { adminFlush, flush } from '@modules/core/store/app.actions';
import { UserActivitiesService } from '@modules/profile/services/user-activities.service';
import { hashtagMentionClick } from '@modules/shared-v2/store/mentions.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    exhaustMap,
    filter,
    groupBy,
    map,
    mergeMap,
    tap,
} from 'rxjs/operators';
import { HashtagInfoService } from '../services/hashtag-info.service';
import * as apiActions from './hashtag-info-api.actions';
import * as actions from './hashtag-info.actions';

@Injectable()
export class HashtagInfoEffects {
    appFlush$ = createEffect(() =>
        this.actions$.pipe(
            ofType(flush, adminFlush),
            map(() => actions.clear()),
        ),
    );

    openDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(hashtagMentionClick),
            map(({ id }) =>
                actions.openDetail({
                    hashtagId: id,
                }),
            ),
        ),
    );

    fetchHashtagInfoOnOpenDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.openDetail),
            map(({ hashtagId }) =>
                actions.fetchHashtagInfo({
                    hashtagId,
                }),
            ),
        ),
    );

    fetchHashtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchHashtagInfo),
            groupBy(({ hashtagId }) => hashtagId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ hashtagId }) =>
                        this.hashtagInfoService.getHashtagInfo(hashtagId).pipe(
                            map((hashtag) =>
                                apiActions.fetchHashtagInfoSuccess({ hashtag }),
                            ),
                            catchError((error) =>
                                of(
                                    apiActions.fetchHashtagInfoError({
                                        error,
                                        hashtagId,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    followHashtag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.followHashtag),
            concatMap(({ hashtagId, follow }) =>
                this.userActivitiesService
                    .setHashtagFollow(hashtagId, follow)
                    .pipe(
                        map(() =>
                            apiActions.followHashtagSuccess({
                                hashtagId,
                                follow,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                apiActions.followHashtagError({
                                    hashtagId,
                                    follow,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    showTipOnFollowHashtagSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.followHashtag),
            filter((action) => !!action.showTip),
            map(({ follow }) =>
                openProTip({
                    title: follow
                        ? 'HASHTAG_INFO.FOLLOW_TIP_TITLE'
                        : 'HASHTAG_INFO.UNFOLLOW_TIP_TITLE',
                    message: follow
                        ? 'HASHTAG_INFO.FOLLOW_TIP_MESSAGE'
                        : 'HASHTAG_INFO.UNFOLLOW_TIP_MESSAGE',
                }),
            ),
        ),
    );

    error$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(apiActions.fetchHashtagInfoError),
                tap(({ error }) => {
                    this.errorService.handle(error);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private hashtagInfoService: HashtagInfoService,
        private errorService: ErrorService,
        private userActivitiesService: UserActivitiesService,
    ) {}
}
