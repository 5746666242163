@let selectedHome =
    ({ currentHomeId, selectableHomes } | apply: getHomeName) ?? ''
        | currentLanguageV2: true;
@if (selectableHomes.length > 1) {
    <button
        #openMenu="cdkOverlayOrigin"
        cdkOverlayOrigin
        class="bg-surface-A hover:bg-surface-B inline-flex items-center gap-8 rounded px-12 py-8"
        matRipple
        type="button"
        [attr.aria-controls]="'menu'"
        [attr.aria-expanded]="isOpen"
        [attr.aria-label]="
            'DIGITAL_WORKPLACE.ACCESSIBILITY_HOME_SELECTOR'
                | translate: { selectedHome }
        "
        [matTooltip]="'DIGITAL_WORKPLACE.HOME_LIST' | translate"
        [ngClass]="isMd() ? 'typo-t5' : 'typo-t6'"
        (click)="toggleOpen()"
    >
        <h1>{{ selectedHome }}</h1>
        <interacta-icon class="size-24" [icon]="'chevron-small-down'" />
    </button>

    <interacta-menu
        [attr.id]="'menu'"
        [classes]="'min-w-300 max-h-[min(80vh,17.625rem)] py-16'"
        [open]="isOpen"
        [origin]="openMenu"
        [positionX]="'after'"
        (closing)="isOpen = false"
    >
        @for (item of selectableHomes; track item.id) {
            <button
                class="justify-between"
                interactaArrowKeysItem
                interactaMenuItemDecorator
                type="button"
                (click)="itemSelected(item)"
            >
                <p class="typo-medium">
                    {{ item.name | currentLanguageV2: true }}
                </p>
                @if (item.isNew) {
                    <p
                        class="typo-xs flex h-24 items-center rounded-full border px-8"
                    >
                        {{ 'DIGITAL_WORKPLACE.NEW_HOME_BADGE' | translate }}
                    </p>
                }
            </button>
        }
    </interacta-menu>
} @else {
    <h1 [ngClass]="isMd() ? 'typo-t5' : 'typo-t6'">
        {{ selectedHome }}
    </h1>
}
