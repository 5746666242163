import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    ApplyPipe,
    DeltaViewComponent,
    IconButtonComponent,
    SafeHtmlPipe,
} from '@interacta-shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ProTipData, TipActionCallback } from '../../models/tip.model';
import { asDelta, asString } from '../../models/tip.utils';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        IconButtonComponent,
        DeltaViewComponent,
        ApplyPipe,
        SafeHtmlPipe,
    ],
    selector: 'interacta-pro-tip[tip]',
    templateUrl: './pro-tip.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProTipComponent<A, P> implements OnChanges {
    @Input() tip!: ProTipData<A, P> | null;
    @Output() closeTip = new EventEmitter<void>();
    @Output() actionClicked = new EventEmitter<TipActionCallback<A>>();

    imageSrc: string | null = null;

    asString = asString;
    asDelta = asDelta;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['tip']) {
            this.imageSrc =
                this.tip && this.tip.image !== 'empty'
                    ? `assets/shared/images/pro-tip/${this.tip.image}.png`
                    : null;
        }
    }
}
