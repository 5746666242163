import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    DialogButtonComponent,
    DialogButtonsComponent,
    DialogComponent,
    DialogDescriptionComponent,
} from '@interacta-shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
    ConfirmDialogService,
    ConfirmDialogState,
} from './confirm-dialog.service';

@Component({
    standalone: true,
    selector: 'interacta-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    imports: [
        DialogButtonComponent,
        DialogButtonsComponent,
        DialogComponent,
        DialogDescriptionComponent,
        CommonModule,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent implements OnInit {
    state$!: Observable<ConfirmDialogState>;

    constructor(private confirmDialogService: ConfirmDialogService) {}

    ngOnInit(): void {
        this.state$ = this.confirmDialogService.getState();
    }

    close(confirm: boolean): void {
        this.confirmDialogService.close(confirm);
    }
}
