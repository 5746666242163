import { ActivatedRouteSnapshot } from '@angular/router';

export function routeToUrl(route: ActivatedRouteSnapshot): string | null {
    return (
        '/' +
        route.pathFromRoot
            .map((v) => v.url.map((segment) => segment.toString()).join('/'))
            .join('/')
            .trim()
            .replace(/^\/+|\/+$/, '') // Remove leading and trailing slashes
    );
}
