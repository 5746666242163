import { Injectable } from '@angular/core';
import {
    ErrorCloseBehavior,
    ErrorDisplayService,
} from './error-display.abstract-service';

@Injectable()
export class DefaultErrorDisplayService implements ErrorDisplayService {
    warn(
        message: string,
        closeBehavior?: ErrorCloseBehavior,
        translateParams?: Record<string, unknown>,
    ): void {
        this.defaultWarning();
        console.warn(message, closeBehavior, translateParams);
    }

    error(
        message: string,
        closeBehavior?: ErrorCloseBehavior,
        translateParams?: Record<string, unknown>,
    ): void {
        this.defaultWarning();
        console.error(message, closeBehavior, translateParams);
    }

    private defaultWarning() {
        console.warn(
            'Provide a specific implementation of ErrorDisplayService. DefaultErrorDisplayService currently used is deisgned for development purposes only. only for development purposes.',
        );
    }
}
