import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonComponent } from '@interacta-shared/ui';
import { CarouselContainerComponent } from './components/carousel-container/carousel-container.component';
import { CarouselSlideComponent } from './components/carousel-slide/carousel-slide.component';

@NgModule({
    declarations: [CarouselContainerComponent, CarouselSlideComponent],
    imports: [CommonModule, ButtonComponent],
    exports: [CarouselContainerComponent],
})
export class CarouselModule {}
