<interacta-user-menu-title
    [label]="'USER_MENU.SELECT_LANGUAGE' | translate"
    (back)="back.emit()"
/>

<interacta-user-menu-separator />

<div class="flex flex-col items-center">
    @for (language of availableLanguages; track language.code) {
        <button
            class="w-full"
            interacta-user-menu-button
            type="button"
            [attr.cdkFocusInitial]="$first ? '' : null"
            [width]="'full'"
            (click)="changeLanguage.emit(language)"
        >
            <div
                [ngClass]="{
                    'text-text-primary': language.code === currentLanguage.code,
                }"
            >
                {{ language.description }}
            </div>
        </button>
    }
</div>
