import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { HashtagInfo } from '../models/hashtag-info.model';
import * as apiActions from './hashtag-info-api.actions';
import * as actions from './hashtag-info.actions';

export interface HashtagInfoState {
    detail: {
        visible: boolean;
        hashtagId: number | undefined;
    };

    hashtags: Record<number, HashtagInfo>;
}

const initialState: HashtagInfoState = {
    detail: {
        visible: false,
        hashtagId: undefined,
    },
    hashtags: {},
};

export const hashtagInfoReducer = createReducer(
    initialState,

    on(actions.clear, () => ({ ...initialState })),

    on(actions.openDetail, (state, { hashtagId }) => ({
        ...state,
        detail: {
            hashtagId,
            visible: true,
        },
    })),

    on(actions.closeDetail, (state) => ({
        ...state,
        detail: {
            hashtagId: undefined,
            visible: false,
        },
    })),

    on(apiActions.fetchHashtagInfoSuccess, (state, { hashtag }) => ({
        ...state,
        hashtags: {
            ...state.hashtags,
            [hashtag.id]: hashtag,
        },
    })),

    on(actions.followHashtag, (state, props) =>
        produce(state, (draft) => {
            const hashtag: HashtagInfo | undefined =
                draft.hashtags[props.hashtagId];
            if (hashtag) {
                hashtag.followedByMe = props.follow;
            }
        }),
    ),

    on(apiActions.followHashtagError, (state, props) =>
        produce(state, (draft) => {
            const hashtag: HashtagInfo | undefined =
                draft.hashtags[props.hashtagId];
            if (hashtag) {
                hashtag.followedByMe = !props.follow;
            }
        }),
    ),
);
