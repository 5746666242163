import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'interacta-user-menu-title',
    templateUrl: './user-menu-title.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuTitleComponent {
    @Input() label!: string;

    @Output() back = new EventEmitter();
}
