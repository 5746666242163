<div
    *ngIf="currentTip()"
    class="shadow-tip z-overlay-tip fixed mx-auto flex min-h-64 flex-col justify-center rounded-2xl py-16"
    @tip
    [ngClass]="{
        'bg-surface-A inset-x-32 top-96 px-20': systemTip(),
        'w-352 xl:w-440 bg-surface-A-reverse inset-x-0 bottom-48 px-16':
            proTip() || simpleTip(),
    }"
>
    <interacta-simple-tip
        [tip]="simpleTip()"
        (closeTip)="close()"
        (forceReload)="forceReload()"
    />
    <interacta-pro-tip
        [tip]="proTip()"
        (actionClicked)="actionClicked($event)"
        (closeTip)="close(proTip()?.closeCallback)"
    />
    <interacta-system-tip
        [tip]="systemTip()"
        (actionClicked)="actionClicked($event)"
        (closeTip)="close(systemTip()?.closeCallback)"
    />
</div>
