import { Route } from '@angular/router';
import { OutOfServicePageComponent } from './out-of-service-page/out-of-service-page.component';
import { outOfServiceGuard } from './out-of-service.guard';

export const featureErrorRoutes: Route[] = [
    {
        path: 'page-not-found',
        loadComponent: () =>
            import('./error-page/error-page.component').then(
                (m) => m.ErrorPageComponent,
            ),
        data: { id: 'page-not-found', errorPage: 'pageNotFound' },
    },
    {
        path: 'forbidden-access',
        loadComponent: () =>
            import('./error-page/error-page.component').then(
                (m) => m.ErrorPageComponent,
            ),
        data: { id: 'forbidden-access', errorPage: 'forbiddenAccess' },
    },
    {
        path: 'unauthorized',
        loadComponent: () =>
            import('./error-page/error-page.component').then(
                (m) => m.ErrorPageComponent,
            ),
        data: { id: 'unauthorized', errorPage: 'unauthorized' },
    },
    {
        path: 'out-of-service',
        canActivate: [outOfServiceGuard],
        // keep this componenet eager loaded beacase out-of-service page should be displayed even if the server is down
        component: OutOfServicePageComponent,
    },
];
