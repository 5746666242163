<interacta-menu-dialog
    [open]="isOpen"
    [origin]="origin"
    [overridePositions]="[
        {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top',
        },
    ]"
    (closing)="closing.emit()"
>
    <div
        class="min-w-252 max-h-screen w-min overflow-y-auto p-16"
        interactaMenuDecorator
    >
        @switch (currentPage) {
            @case ('home') {
                <interacta-user-menu-home
                    cdkTrapFocus
                    cdkTrapFocusAutoCapture
                    class="block"
                    role="menu"
                    @slideLeft
                    [availableLanguages]="availableLanguages"
                    [currentLanguage]="currentLanguage"
                    [currentTheme]="currentTheme"
                    [currentUser]="currentUser"
                    (changePage)="currentPage = $event"
                    (logout)="logout.emit()"
                    (openNotificationsSettings)="
                        openNotificationsSettings.emit()
                    "
                    (openProfile)="openProfile.emit()"
                />
            }
            @case ('theme') {
                <interacta-user-menu-theme
                    cdkTrapFocus
                    cdkTrapFocusAutoCapture
                    class="block"
                    role="menu"
                    @slideRight
                    [currentTheme]="currentTheme"
                    (back)="currentPage = 'home'"
                    (changeTheme)="changeTheme.emit($event)"
                />
            }
            @case ('language') {
                <interacta-user-menu-language
                    cdkTrapFocus
                    cdkTrapFocusAutoCapture
                    class="block"
                    role="menu"
                    @slideRight
                    [availableLanguages]="availableLanguages"
                    [currentLanguage]="currentLanguage"
                    (back)="currentPage = 'home'"
                    (changeLanguage)="changeLanguage.emit($event)"
                />
            }
        }
    </div>
</interacta-menu-dialog>
